import PT from "prop-types";

import annotation from "../../../assets/img/icons/annotation.svg";

import {
  StyledAnnotationContainer,
  StyledAnnotationNegative,
  StyledAnnotationPositive
} from "./Annotation.styled";

export const Annotation = ({
  positive = 0,
  negative = 0,
  className = "",
  showIcon = false
}) => (
  <StyledAnnotationContainer className={className}>
    {showIcon && <img src={annotation} alt="" width={16} height={16} />}
    <StyledAnnotationPositive default={!positive}>
      +{positive}/
    </StyledAnnotationPositive>
    <StyledAnnotationNegative default={!negative}>
      -{negative}
    </StyledAnnotationNegative>
  </StyledAnnotationContainer>
);

Annotation.propTypes = {
  positive: PT.number,
  negative: PT.number,
  className: PT.string,
  showIcon: PT.bool
};
