import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";
import { Button } from "../../../../components/ui/Button/Button";
import { Title } from "../../../../components/ui/Title/Title";

export const RestyledContainer = styled(Container)`
  padding-bottom: 64px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-bottom: 20px;
  }
`;

export const Inner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${(p) => p.theme.colors.white};
  z-index: 7;
  padding: 16px;
  border-radius: 12px;
  margin-top: -35px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 36px;
    margin-top: -180px;
    flex-direction: row;
    min-height: 366px;
    box-shadow: ${(p) => p.theme.shadows.card};
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 16px;
  border-radius: 12px 12px 0 0;
  background: ${(p) => p.theme.colors["background-color"]};
  padding-bottom: 40px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 24px;
    border-radius: 12px;
    margin-bottom: 0;
    margin-right: 36px;
    min-width: 360px;
    max-width: 360px;
  }
`;

export const ImageWrapper = styled.div`
  min-height: 140px;
  & > img {
    width: 100%;
    max-width: 500px;
  }
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    min-height: 186px;
    & > img {
      max-width: 100%;
      margin-bottom: 24px;
    }
  }
`;

export const RestyledButton = styled(Button)`
  display: ${(p) => (p.isMobile ? "flex" : "none")};
  

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: ${(p) => (p.isMobile ? "none" : "flex")};\
    max-width: 500px;
  }
`;

export const Results = styled.div`
  width: 100%;
`;

export const TitleWrapper = styled.div`
  background: ${(p) => p.theme.colors["background-color"]};
  border-radius: 0 0 12px 12px;
  padding: 0 12px 16px 12px;
  margin-bottom: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    background: ${(p) => p.theme.colors.white};
    padding: 0;
    margin-bottom: 0;
  }
`;

export const RestyledTitle = styled(Title)`
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 120%
  width: auto;
  margin-bottom: 16px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin})
    font-size: ${(p) => p.theme.fontSizes.xll};
    line-height: 120%
    margin-bottom: 24px !important;
  }
`;

export const InfoMessage = styled.div`
  width: 100%;
  padding: 12px 16px;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
  border-radius: 12px;

  color: ${(p) => (p.webRtcStatus ? p.theme.colors["red-500"] : "#86c13b")};
  background: ${(p) => (p.webRtcStatus ? "#ffe9e696" : "#e8f8cc96")};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 24px;
  }
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;
