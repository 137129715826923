import { useCallback, useEffect, useRef } from "react";

import PT from "prop-types";

import {
  StyledChildrenWrapper,
  StyledContent,
  StyledOverlay
} from "./Overlay.styled";

export const Overlay = ({ isOpened, setIsOpened, children }) => {
  // **Ref
  const menuRef = useRef(null);

  const handleOverlayClick = useCallback(
    (event) => {
      if (event.target.classList.contains("overlay")) {
        setIsOpened(false);
      }
    },
    [setIsOpened]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (isOpened && event.key === "Escape") {
        setIsOpened(false);
        event.preventDefault();
      }
    },
    [isOpened, setIsOpened]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  // Lock scroll
  useEffect(() => {
    if (isOpened) {
      // eslint-disable-next-line no-undef
      document.body.classList.add("lock-new");
    } else {
      // eslint-disable-next-line no-undef
      document.body.classList.remove("lock-new");
    }

    return () => document.body.classList.remove("lock-new");
  }, [isOpened]);

  // useEffect(() => {
  //   menuRef.current?.scrollTo({ top: 0 });
  // }, [servicesMenuStep]);

  return (
    <StyledOverlay
      isOpened={isOpened}
      onClick={handleOverlayClick}
      className="overlay"
    >
      <StyledContent ref={menuRef} isOpened={isOpened}>
        <StyledChildrenWrapper>{children}</StyledChildrenWrapper>
      </StyledContent>
    </StyledOverlay>
  );
};

Overlay.propTypes = {
  children: PT.node.isRequired
};
