import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

import { getAllBlogInner } from "../../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";

import { StyledHeading } from "./Article.styled";
import { ArticleNavigation } from "./ArticleNavigation/ArticleNavigation";
import { Body } from "./Body/Body";
import { Info } from "./Info/Info";

export const Article = () => {
  // **Redux state
  const { content } = useSelector(getAllBlogInner);

  return (
    <>
      <StyledHeading>
        {content.data?.title ? content.data?.title : <Skeleton width="100%" />}
      </StyledHeading>
      <Info data={content?.data} />
      <Body />
      <ArticleNavigation />
    </>
  );
};
