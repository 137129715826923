import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

import {
  ItemLabel,
  ItemValue,
  ListMobileBlock,
  ListMobileItem,
  Status
} from "./ListMobile.styled";

export const ListMobileItemComponent = ({ ip = "", type, status }) => {
  const { t } = useTranslation();
  return (
    <ListMobileItem>
      <ListMobileBlock>
        <ItemLabel>{t("webRTCLeakTest.table.ip")}</ItemLabel>
        <ItemValue>{ip}</ItemValue>
      </ListMobileBlock>
      <ListMobileBlock>
        <ItemLabel>{t("webRTCLeakTest.table.type")}</ItemLabel>
        <ItemValue>{type || "Public IPv4"}</ItemValue>
      </ListMobileBlock>
      <ListMobileBlock>
        <ItemLabel>{t("webRTCLeakTest.table.status")}</ItemLabel>
        <ItemValue>
          <Status webRtcStatus={status}>
            {status
              ? t("webRTCLeakTest.table.leak")
              : t("webRTCLeakTest.table.noLeak")}
          </Status>

          <Tooltip
            body={
              status
                ? t("webRTCLeakTest.table.leakTooltip")
                : t("webRTCLeakTest.table.noLeakTooltip")
            }
            isOpen={true}
            place="top"
          >
            <img src="img/icons/information.svg" alt="" />
          </Tooltip>
        </ItemValue>
      </ListMobileBlock>
    </ListMobileItem>
  );
};
