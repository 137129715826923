import styled from "@emotion/styled";

export const BlockLogoStyledBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BlockLogoStyledBodyRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    flex-direction: row;
    gap: 12px;
    & > * {
      margin: 0 !important;
    }
  }
`;

export const StyledInstructionText = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 140%;

  margin-bottom: 24px;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  & pre {
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 8px;
    padding: 16px;
    white-space: pre-wrap;
    word-break: break-all;

    & > code {
      tab-size: 2;
    }
  }
`;

export const StyledInstructionButtons = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  & > .button_download_file {
    background-image: none !important;

    background-color: ${(p) => p.theme.colors.white};
    font-weight: 400;
  }
`;
