import styled from "@emotion/styled";

import { Container } from "../Container/Container";

export const StyledSection = styled.div`
  overflow: hidden;
  width: 100%;
  background: ${(p) =>
    p.background === "dark"
      ? p.theme.colors["background-color"]
      : p.theme.colors["background-color-seo"]};
`;

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px !important;
  padding-bottom: 64px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
`;

export const StyledGuideAnimation = styled.div`
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 50%;
    max-width: 553px;
    height: 568px;
    margin-top: 30px;
    margin-right: 12px;
  }
`;

export const StyledServiceAnimation = styled.div`
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    min-width: 536px;
    height: 457px;
    margin-top: 9px;
    margin-left: 36px;
  }
`;

export const StyledContent = styled.div`
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 50%;
  }
`;

export const StyledHeading = styled.h2`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.18;
  margin-bottom: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    font-size: 48px;
    line-height: 1.08;
    margin-bottom: ${(p) => (p.marginBottom === "small" ? "16px" : "24px")};
  }
`;

export const StyledSubheading = styled.p`
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 32px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 24px;
  }
`;

export const StyledListTitle = styled.p`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 24px;
`;

export const StyledDottedListItem = styled.li`
  display: flex;
  align-items: flex-start;
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 18px;
  font-weight: 400;
  line-height: 1.66;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  div {
    margin-right: 12px;

    img {
      max-width: 14px;
    }
  }
`;

export const StyledListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const StyledItemTitle = styled.p`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 18px;
  font-weight: 600;
  line-height: 1.66;
  margin-bottom: 12px;
`;

export const StyledItemDescription = styled.span`
  display: block;
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  padding-left: 24px;
`;

export const StyledImage = styled.picture`
  width: ${(p) => (p.variant === "dark" ? "322px" : "310px")};
  margin-top: ${(p) => (p.variant === "dark" ? "32px" : "40px")};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none;
  }
`;
