export const EMAIL_REGEX =
  /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,4}$/i;
export const EMAIL_STAGE_REGEX =
  /^([a-z0-9_-]+\.)*[a-z0-9_-]+(?:\+[0-9]+)?@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,4}$/i;

export const URL_WITHOUT_HTTPS =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const REGEX_IP =
  /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const URL_START_WITH_HTTPS = /^https:\/\/\S+$/;
