import { RecentReviews } from "../../../../components/common/RecentReviews/RecentReviews";

import { UserChoiceTable } from "./UserChoiceTable/UserChoiceTable";
import { StyledUserChoiceContainer } from "./UserChoise.styled";

export const UserChoice = () => (
  <section>
    <StyledUserChoiceContainer>
      <UserChoiceTable />
      <RecentReviews itemHovered isMainPage />
    </StyledUserChoiceContainer>
  </section>
);
