import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import Skeleton from "react-loading-skeleton";

const reactLoadingSkeleton = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

export const StyledSceletonTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 12px;
  border: ${(p) => (p.noBorder ? "none" : p.theme.borders.border)};
`;

export const StyledCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || "flex-start"};
  gap: 8px;
  padding: 0 8px;
  ${(p) => (p.cellMinWidth ? `min-width: ${p.cellMinWidth}px` : "")};
  ${(p) => (p.cellWidth ? `min-width: ${p.cellWidth}px` : "")};
  width: ${(p) => (p.cellWidth ? `${p.cellWidth}px` : "100%")};
  height: 100%;

  font-size: 0;
  line-height: 0;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  height: ${(p) => `${p.rowHeight}px`};
  &:not(:last-child) {
    border-bottom: ${({ theme }) => theme.borders.border};
  }
`;

export const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  width: 100%;
`;

export const StyledPagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  gap: 8px;
  & > div {
    @media (max-width: ${({ theme }) => theme.screenSizes.mdMax}) {
      display: none;
    }
  }
`;

export const StyledSkeleton = styled(Skeleton)`
  background-color: #ebebeb;
  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  overflow: hidden;
  z-index: 0;
  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, #ebebeb, #f5f5f5, #ebebeb);
    transform: translateX(-100%);

    animation-name: ${reactLoadingSkeleton};
    animation-direction: 1.5s;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
`;
