import styled from "@emotion/styled";

export const StyledTable = styled.div`
  display: ${(props) => (props.display ? props.display : "block")};
  position: relative;
  padding: ${(props) => (props.padding ? props.padding : "24px")};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  width: 100%;
  overflow-x: ${(props) => (props.overflow ? props.overflow : "auto")};
  border-radius: 12px;
  background: white;
  box-shadow: ${(props) =>
    props.boxShadow
      ? props.boxShadow
      : "0px 5px 28px 0px rgba(135, 140, 189, 0.08)"};

  &::-webkit-scrollbar-button:end:increment {
    width: 2.5%;
    display: block;
    background: transparent;
  }

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: block;
  }
`;

export const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  display: table;
`;

export const TableBody = styled.div`
  overflow-y: auto;
  max-height: ${(props) => props.maxHeight};
  width: 100%;
`;

export const TableHeadRow = styled.div`
  border-radius: 12px;
  background-color: #f6f7f9;
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: ${(props) => (props.height ? props.height : "60px")};
`;

export const TableHeadCol = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : "auto")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : props.width)};
  height: 100%;
  padding: ${(props) => (props.padding ? props.padding : "20px 0")};
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  color: ${(props) => props.theme.colors["neutral-400"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42;
  text-transform: uppercase;

  @media (min-width: 1530px) {
    width: ${(props) =>
      props.desktopWidth ? props.desktopWidth : props.width};
  }
`;

export const TableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: ${(props) => props.height};
  cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
  border-bottom: ${(props) =>
    props.borderBottom
      ? props.borderBottom
      : "1px solid rgba(216, 218, 224, 0.5)"};
  overflow: visible;
  background: none;
  transition: all 0.3s ease;

  &:hover {
    background: ${(props) =>
      props.hoverBackground
        ? props.hoverBackground
        : "rgba(246, 247, 249, 0.6)"};
    transition: all 0.3s ease;
  }
`;

export const TableCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  box-sizing: border-box;
  word-break: break-word;
  width: ${(props) => (props.width ? props.width : "auto")};
  max-width: 100%;
  min-width: ${(props) => (props.minWidth ? props.minWidth : props.width)};
  height: 100%;
  padding: ${(props) => (props.padding ? props.padding : "12px 0")};
  align-items: center;

  @media (min-width: 1530px) {
    width: ${(props) =>
      props.desktopWidth ? props.desktopWidth : props.width};
  }
`;

export const TableText = styled.span`
  display: ${(props) => props.display ? props.display : "flex"};
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  width: ${(props) => (props.width ? props.width : "100%")};
  gap: 8px;
  flex-shrink: 0;
  color: ${(props) => props.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
`;

export const StyledNoData = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
`;
