import { useCallback, useMemo, useState } from "react";

import PT from "prop-types";
import { useTranslation } from "react-i18next";

import { Icon } from "../../../../components/ui/Icon/Icon";
import { getIpDetailsData } from "../../../../utils/helpers/getIpDetailsData.helpers";

import {
  Details,
  RestyledContainer,
  RestyledTitle,
  StyledShowMore
} from "./IpDetails.styled";
import { IpDetailsItem } from "./IpDetailsItem";

export const IpDetails = ({ anonymity, isLoading = false }) => {
  const [openedItems, setOpenedItems] = useState([]);
  const [showMore, setShowMore] = useState(false);

  const { t } = useTranslation();

  const handleOpen = useCallback(
    (id) => {
      if (openedItems.includes(id)) {
        setOpenedItems(openedItems?.filter((item) => item !== id));
      } else {
        setOpenedItems([...openedItems, id]);
      }
    },
    [openedItems]
  );

  const resultsList = useMemo(
    () =>
      (showMore
        ? getIpDetailsData(anonymity, t)
        : getIpDetailsData(anonymity, t)?.slice(0, 4)
      )?.map((item) => (
        <IpDetailsItem
          result={!!anonymity}
          title={item.title}
          image={item.image}
          isLoading={isLoading}
          key={item.id}
          id={item.id}
          isOpened={openedItems.includes(item.id)}
          handleOpen={handleOpen}
          listData={item.listData}
        />
      )),
    [showMore, handleOpen, openedItems, isLoading, anonymity, t]
  );

  return (
    <RestyledContainer>
      <RestyledTitle tag="h2">{t("myAnonymity.details.title")}</RestyledTitle>
      <Details>
        {resultsList}
        {anonymity && (
          <StyledShowMore onClick={() => setShowMore(!showMore)}>
            <span>{showMore ? "Hide" : "Show more"}</span>
            <Icon name={showMore ? "expandUp" : "refresh"} />
          </StyledShowMore>
        )}
      </Details>
    </RestyledContainer>
  );
};

IpDetails.propTypes = {
  userIp: PT.shape({}),
  isLoading: PT.bool
};
