import styled from "@emotion/styled";

import { Button } from "../../ui/Button/Button";

export const FormListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export const FormListStyled = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  list-style: none;
  padding: 0;
  margin: 0;
`;

export const FormListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

export const AddNewButtonStyled = styled(Button)`
  background-image: none !important;
  background-color: ${(p) => p.theme.colors["neutral-50"]};
`;

export const RemoveButtonStyled = styled(Button)`
  background-image: none !important;
  background-color: ${(p) => p.theme.colors["neutral-50"]};
  padding: 12px;
  &:hover,
  &:focus {
    color: ${(p) => p.theme.colors["red-500"]};
  }

  & svg {
    width: 24px;
    height: 24px;
  }
`;
