import styled from "@emotion/styled";

import { FormInputCode } from "../../../../components/forms/FormInput/FormInputCode";
import { Button } from "../../../../components/ui/Button/Button";
import { Modal } from "../../../../components/ui/Modal/Modal";
import { TextButton } from "../../../../components/ui/TextButton/TextButton";

export const RestyledModal = styled(Modal)`
  padding: 36px !important;
  width: 480px !important;
  @media (max-width: 480px) {
    top: unset !important;
    bottom: 0 !important;
    left: 50% !important;
    min-width: 100% !important;
    width: 100% !important;
    transform: translateX(-50%) !important;
    padding: 36px 16px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .md_title {
    margin-bottom: 8px !important;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const StyledSubtitle = styled.p`
  color: #2e394f;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
  margin-bottom: 24px;

  @media (min-width: 480px) {
    line-height: 112%;
    margin-bottom: 32px;
  }
`;

export const RestyledFormInputCode = styled(FormInputCode)`
  input {
    flex: 0 1 56px;
    @media (min-width: 480px) {
      min-height: 59px;
      flex: 0 1 67px;
      padding: 15px 0;
    }
  }
`;

export const StyledTimer = styled.div`
  color: ${(p) =>
    p.timerEnd ? p.theme.colors["red-500"] : p.theme.colors["neutral-500"]};

  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 125%;
  margin-top: 16px;
`;

export const TimeContainer = styled.span`
  color: ${(p) =>
    p.timerEnd ? p.theme.colors["red-500"] : p.theme.colors["neutral-800"]};
  line-height: 130%;
  margin-left: 8px;
  font-size: 16px;
`;

export const ResentButton = styled(TextButton)`
  margin-top: 16px;
  margin-right: auto;
  color: ${(p) => p.theme.colors["neutral-800"]} !important;
  &:hover,
  &:focus {
    font-weight: 600;
  }
  & > span {
    &::after {
      width: 100% !important;
      height: 1px;
    }
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 24px;
`;
