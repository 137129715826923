import styled from "@emotion/styled";

import { Title } from "../../../../ui/Title/Title";

export const SuccessStyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0;
`;

export const RestyledTitle = styled(Title)`
  text-align: center;

  font-size: ${(p) => p.theme.fontSizes.xll};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  font-style: ${(p) => p.theme.fontStyle.normal};
  line-height: 120%;

  margin-bottom: 8px;
`;

export const SuccessStyledText = styled.p`
  color: ${(p) => p.theme.colors["neutral-600"]};
  text-align: center;
  max-width: 325px;

  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
  margin-bottom: 35px;
`;

export const SuccessStyledImage = styled.img`
  object-position: 0 -8px;
  width: 260px;
  height: 126px;
`;
