import PT from "prop-types";

import {
  StyledFormBlock,
  StyledFormBlockBody,
  StyledFormBlockLabel
} from "./FormBlock.styled";

export const FormBlock = ({
  title,
  subtitle,
  children,
  bodyType = "default"
}) => (
  <StyledFormBlock bodyType={bodyType}>
    {title && (
      <StyledFormBlockLabel>
        <h3>{title}</h3>
        {subtitle && <p>{subtitle}</p>}
      </StyledFormBlockLabel>
    )}
    <StyledFormBlockBody bodyType={bodyType} withoutTitle={!title}>
      {children}
    </StyledFormBlockBody>
  </StyledFormBlock>
);

FormBlock.propTypes = {
  title: PT.string,
  subtitle: PT.string,
  children: PT.node,
  bodyType: PT.oneOf(["default", "buttons"])
};
