import { locationsList } from "../../../../utils/constants";

import { LocationItem } from "./LocationsItem/LocationItem";

export const Locations = ({
  selectedLocation,
  onClick,
  className,
  setCurrentStep
}) => {
  const handleClick = (location) => {
    onClick(location);
    if (setCurrentStep) {
      setCurrentStep("countries");
    }
  };
  return (
    <ul className={className}>
      {locationsList.map((item) => (
        <LocationItem
          key={item.translation}
          title={item.translation}
          isSelected={item.translation === selectedLocation}
          onClick={() => handleClick(item.translation)}
        />
      ))}
    </ul>
  );
};
