export const siteInitialState = {
  isMobileMenuOpened: false,
  isBurgerMenuOpened: false,
  isServiceMenuOpened: false,
  previousLang: "en",
  isServicesOpened: false,
  isLocationOpened: false,
  selectedLocation: "",
  servicesMenuStep: "locations",
  searchCountry: ""
};
