import styled from "@emotion/styled";

import { Icon } from "../../../ui/Icon/Icon";
import { Search } from "../../../ui/Search/Search";
import { NavigationItem } from "../Navigation/NavigationItem";

export const StyledButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  margin-top: 16px;

  span {
    color: rgba(255, 255, 255, 0.8);
    font-family: var(--font-family-text);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: 12px;
  transform: rotate(180deg);
`;

export const LocationsNavigationItem = styled(NavigationItem)`
  margin-top: 24px;
  margin-bottom: 0;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-top: 0;
  }

  > div:first-of-type {
    margin-bottom: 0;
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      margin-bottom: 16px;
    }
  }
`;

export const StyledSearch = styled(Search)`
  display: ${(p) => (p.isOpened ? "flex" : "none")};
`;
