import styled from "@emotion/styled";

import { TextButton } from "../../../../../components/ui/TextButton/TextButton";

export const StyledPromocodesBlock = styled.div`
  display: ${(p) => (p.active ? "flex" : "none")};
  flex-direction: column;
  gap: 16px;

  margin-top: 24px;
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    gap: 24px;
  }
`;

export const StyledPromocodesBlockHead = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const StyledPromocodesList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    gap: 12px;
  }
  & > li {
    width: 100%;
  }
`;

export const StyledPaginationBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 12px;
  margin-top: 8px;
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    margin-top: 0px;
    gap: 20px;
  }
`;

export const StyledShowMore = styled(TextButton)`
  & > svg {
    width: 20px;
    height: 20px;
  }
`;
