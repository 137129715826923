import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";
import { FormSelect } from "../../../../components/forms/FormSelect/FormSelect";
import { Button } from "../../../../components/ui/Button/Button";
import { Title } from "../../../../components/ui/Title/Title";

export const RestyledContainer = styled(Container)`
  padding-top: 120px;
  padding-bottom: 64px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-bottom: 120px;
  }
`;

export const TracingIpWrapper = styled.div`
  position: relative;
  z-index: 2;
  margin-top: -156px;
  padding: 36px;
  border-radius: 12px;
  background: white;
  box-shadow: ${(p) => p.theme.shadows.card};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-top: -255px;
  }
`;

export const RestyledTitle = styled(Title)`
  margin-bottom: 16px !important;
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 120%;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 24px !important;
    font-size: ${(p) => p.theme.fontSizes.xll};
    line-height: 120%;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
  }
`;

export const InputBox = styled.div`
  width: 100%;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    min-width: 380px;
  }
`;

export const StyledAdditionalText = styled.button`
  padding-top: 5px;
  color: ${({ theme }) => theme.colors["main-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 115%;
  cursor: pointer;

  &::after {
    content: "";
    position: relative;
    top: -1px;
    width: 100%;
    height: 1px;
    display: block;
    background: ${({ theme }) => theme.colors["main-600"]};
  }
`;

export const RestyledFormSelect = styled(FormSelect)`
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    min-width: 236px;
  }
`;

export const RestyledButton = styled(Button)`
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-top: 26px;
  }
`;

export const InfoMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
  margin-bottom: 36px;
  color: ${(p) => p.theme.colors["neutral-500"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 140%;
`;

export const ResultWrapper = styled.div`
  width: 100%;
  height: 230px;
  border-radius: 12px;
  border: 1px solid ${(p) => p.theme.colors["outline-color"]};
  padding: 24px 24px 14px 24px;

  ${({ isLoading }) =>
    isLoading && "display: flex; justify-content: center; align-items: center;"}
`;

export const ResultContent = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const ResultItem = styled.div`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
  margin-bottom: 4px;
`;
