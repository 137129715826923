import { $api } from "../../services/AxiosInstance";
import { AllActions } from "../../store/reducers/AllActions";

import { Blog } from "./Blog/Blog";

const BlogPage = () => <Blog />;

export default BlogPage;

BlogPage.getServerSideState = async (store, params) => {
  const { data } = await $api.get("/api/front/site/blog/search", {
    params: {
      page: 0,
      size: 5,
      category:
        params.params.tab === "all" ? "null" : params.params.tab !== "news",
      languageCode: params.locale,
      ...(params.params.s && params.params.s?.length > 1 && { search: params.params.s })
    }
  });

  if (data) {
    store.dispatch(AllActions.setBlogContent(data));
  }
};
