import styled from "@emotion/styled";

const BTN_SIZE = {
  mobile: {
    dark: "44px",
    gray: "40px",
    light: "40px",
    table: "44px"
  },
  desktop: {
    dark: "48px",
    gray: "40px",
    light: "48px",
    table: "44px"
  }
};

const FONT_SIZE = {
  mobile: {
    dark: "14px",
    gray: "14px",
    light: "16px",
    table: "14px"
  },
  desktop: {
    dark: "16px",
    gray: "14px",
    light: "16px",
    table: "14px"
  }
};

const BG_COLORS = {
  default: {
    dark: "white",
    gray: "white",
    light: "neutral-50",
    table: "neutral-50"
  },
  hover: {
    dark: "main-500",
    gray: "white",
    light: "neutral-50",
    table: "neutral-50"
  }
};

const MAX_BOX_SIZE = {
  mobile: {
    dark: "100%",
    gray: "100%",
    light: "100%",
    table: "235px"
  },
  desktop: {
    dark: "100%",
    gray: "100%",
    light: "100%",
    table: "235px"
  }
};

export const StyledPromoButtonsBox = styled.div`
  font-family: ${(p) => p.theme.fontFamily.lato};
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-width: ${(p) => MAX_BOX_SIZE.mobile[p.variant]};
  width: 100%;
  max-width: ${(p) => MAX_BOX_SIZE.mobile[p.variant]};
  gap: 8px;
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    min-width: ${(p) => MAX_BOX_SIZE.desktop[p.variant]};
    max-width: ${(p) => MAX_BOX_SIZE.desktop[p.variant]};
  }
  ${(p) =>
    p.variant === "light"
      ? `
        @media (min-width: 1440px) {
        min-width: 220px;
        max-width: 220px;
        `
      : ""}

  & > span[aria-live="polite"] {
    width: 100%;
  }
`;

export const StyledPromoButton = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  justify-content: ${(p) => (p?.icon ? "center" : "space-between")};
  gap: 8px;
  min-width: ${(p) => (p?.variant ? BTN_SIZE.mobile[p.variant] : "44px")};
  width: ${(p) => {
    if (p?.icon) {
      return p?.variant ? BTN_SIZE.mobile[p.variant] : "44px";
    } else {
      return "100%";
    }
  }};
  height: ${(p) => (p?.variant ? BTN_SIZE.mobile[p.variant] : "44px")};
  padding: 10px;
  background-color: ${(p) =>
    p.variant
      ? p.theme.colors[BG_COLORS.default[p.variant]]
      : p.theme.colors.white};
  color: ${(p) => p.theme.colors["neutral-800"]};
  border-radius: 8px;
  border: none;
  font-family: ${(p) => p.theme.fontFamily.lato};
  line-height: 150%;
  font-weight: 400;
  font-size: ${(p) => FONT_SIZE.mobile[p.variant]};

  transition: background-color ${(p) => p.theme.transitions.default},
    color ${(p) => p.theme.transitions.default};

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    min-width: ${(p) => (p?.variant ? BTN_SIZE.desktop[p.variant] : "44px")};
    width: ${(p) => {
      if (p?.icon) {
        return p?.variant ? BTN_SIZE.desktop[p.variant] : "44px";
      } else {
        return "100%";
      }
    }};
    height: ${(p) => (p?.variant ? BTN_SIZE.desktop[p.variant] : "44px")};
    font-size: ${(p) => FONT_SIZE.desktop[p.variant]};
  }

  &:hover {
    background-color: ${(p) =>
      p.variant
        ? p.theme.colors[BG_COLORS.hover[p.variant]]
        : p.theme.colors["main-500"]};
    color: ${(p) =>
      p.variant === "dark"
        ? p.theme.colors["neutral-800"]
        : p.theme.colors["main-700"]};
    font-weight: 600;
    transition: background-color ${(p) => p.theme.transitions.default},
      color ${(p) => p.theme.transitions.default};
  }

  & svg {
    flex: none;
    width: 20px;
    height: 20px;
  }
`;

export const StyledPromoCodeBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  min-width: ${(p) => (p?.variant ? BTN_SIZE.mobile[p.variant] : "44px")};
  width: 100%;
  height: ${(p) => (p?.variant ? BTN_SIZE.mobile[p.variant] : "44px")};
  padding: 10px 16px;
  background-color: ${(p) =>
    p.variant === "table"
      ? p.theme.colors["neutral-50"]
      : p.theme.colors.white};
  outline: ${(p) =>
    p.variant === "light" ? `1px solid ${p.theme.colors["main-500"]}` : "none"};
  color: ${(p) => p.theme.colors["neutral-800"]};
  border-radius: 8px;

  font-family: ${(p) => p.theme.fontFamily.lato};
  line-height: 150%;
  font-weight: 600;
  font-size: ${(p) => FONT_SIZE.mobile[p.variant]};

  word-break: break-all;
  text-overflow: clip;

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    min-width: ${(p) => (p?.variant ? BTN_SIZE.desktop[p.variant] : "44px")};
    width: 100%;
    height: ${(p) => (p?.variant ? BTN_SIZE.desktop[p.variant] : "44px")};
    font-size: ${(p) => FONT_SIZE.desktop[p.variant]};
  }

  & svg {
    flex: none;
    width: ${(p) => (p?.variant === "dark" ? "24px" : "20px")};
    height: ${(p) => (p?.variant === "dark" ? "24px" : "20px")};

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      width: ${(p) =>
        ["dark", "light"].includes(p?.variant) ? "24px" : "20px"};
      height: ${(p) =>
        ["dark", "light"].includes(p?.variant) ? "24px" : "20px"};
    }
  }
`;

export const StyledPromoCopy = styled.button`
  width: fit-content;
  height: fit-content;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 0;
  outline: none;

  color: ${(p) => p.theme.colors["neutral-300"]};
  transition: color ${(p) => p.theme.transitions.default};

  &:hover {
    color: ${(p) => p.theme.colors["main-700"]};
    transition: color ${(p) => p.theme.transitions.default};
  }
`;

export const StyledPromoCode = styled.p`
  font-family: ${(p) => p.theme.fontFamily.lato};
  line-height: 150%;
  font-weight: 600;
  font-size: ${(p) => FONT_SIZE.mobile[p.variant]};

  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  max-width: 100%;
`;
