import { Navigate, Route } from "react-router-dom";

import { AuthLayout } from "../layout/AuthLayout/AuthLayout";
import { DashboardLayout } from "../layout/DashbordLayout/DashboardLayout";
import { NewAppLayout } from "../layout/app.layout";
import { ExportLayout } from "../layout/export.layout";
import { IndexLayout } from "../layout/index.layout";
import { renderRoutes } from "../utils/helpers/renderRoutes.helper";

import { AppRoutes } from "./app.routes";
import { AuthRoutes } from "./auth.routes";
import { DashboardRoutes } from "./dashboard.routes";
import { ExportRoutes } from "./export.routes";
import { ToolsRoutes } from "./tools.routes";

// eslint-disable-next-line no-unused-vars
export const getRoutes = ({ lang = "en", store = null }) => (
  <>
    <Route element={<IndexLayout />}>
      <Route element={<NewAppLayout />}>
        {renderRoutes(AppRoutes(), store)}
        {renderRoutes(ToolsRoutes(), store)}
      </Route>
      <Route
        element={<DashboardLayout />}
        loader={() => ({ isAuthRequired: true })}
      >
        {renderRoutes(DashboardRoutes(), store)}
      </Route>
      <Route element={<AuthLayout />}>
        {renderRoutes(AuthRoutes(), store)}
      </Route>
    </Route>
    <Route element={<ExportLayout />}>
      {renderRoutes(ExportRoutes(), store)}
    </Route>
    <Route path="*" element={<Navigate to="/" replace={true} />} />
  </>
);
