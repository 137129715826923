import { useEffect, useState } from "react";

import PT from "prop-types";

import {
  StyledAnswer,
  StyledCard,
  StyledCardContainer,
  StyledIcon,
  StyledQuestion
} from "./FAQ.styled";

export const FAQCard = ({
  title,
  children,
  className,
  isOpened = false,
  onClick,
  handleOpen
}) => {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(isOpened);
  }, [isOpened]);

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
    if (handleOpen) {
      handleOpen(e);
    } else {
      setOpened(!opened);
    }
  };

  return (
    <StyledCard
      className={className}
      onClick={handleClick}
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      <StyledCardContainer opened={opened}>
        <StyledQuestion opened={opened} itemProp="name">
          {title}
        </StyledQuestion>
        <StyledIcon opened={opened} />
      </StyledCardContainer>
      <StyledAnswer
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
        opened={opened}
      >
        {children}
      </StyledAnswer>
    </StyledCard>
  );
};

FAQCard.propTypes = {
  className: PT.string,
  children: PT.node,
  handleOpen: PT.func,
  isOpened: PT.bool,
  onClick: PT.func,
  title: PT.node.isRequired
};
