import styled from "@emotion/styled";

export const StyledOfferItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  gap: 16px;
  border-radius: 20px;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: ${(p) => p.theme.shadows["special-offer-card"]};
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 24px !important;
    gap: 24px;
  }
`;

export const StyledOfferItemTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.l};
  font-style: normal;
  font-weight: 700;
  line-height: 125%;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    font-size: 20px;
  }
`;

export const StyledOfferItemSiteImage = styled.img`
  width: 24px;
  height: 24px;
  flex: none;
  object-fit: cover;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 32px;
    height: 32px;
  }
`;

export const StyledOfferItemBox = styled.div`
  display: flex;
  flex: 1;
`;
