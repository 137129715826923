import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";
import { Title } from "../../../../components/ui/Title/Title";

export const Preview = styled.div`
  margin-bottom: 32px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 24px;
  width: 100%;
  padding-bottom: 307px;
  background: rgba(216, 218, 224, 0.5);

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 580px;
    min-width: 580px;
    margin-bottom: 32px;
  }

  a {
    font-size: 0;
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    padding-bottom: 74px !important;
    column-gap: 56px !important;
    min-height: 500px !important;
  }
`;

export const StyledLatestTitle = styled(Title)`
  margin-bottom: 24px !important;
`;
