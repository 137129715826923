import styled from "@emotion/styled";

import { Stars } from "../../../../../ui/Stars/Stars";

export const StyledUserPart = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  max-width: 100%;

  color: ${(p) => p.theme.colors["neutral-400"]};

  font-family: ${(p) => p.theme.fontFamily.lato};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-size: ${(p) => p.theme.fontSizes.xxs};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 100%;
  margin-bottom: ${(p) => (p.isRootReview ? "24px" : "12px")};

  @media (min-width: ${(p) =>
      p.isDashboard ? p.theme.screenSizes.lgMin : p.theme.screenSizes.xlMin}) {
    margin-bottom: ${(p) => (p.isRootReview ? "24px" : "16px")};
  }
`;

export const StyledUserInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: calc(100% - 125px);
`;

export const StyledUserName = styled.p`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 120%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  @media (min-width: ${(p) =>
      p.isDashboard ? p.theme.screenSizes.lgMin : p.theme.screenSizes.xlMin}) {
    font-size: ${(p) => p.theme.fontSizes.l};
  }
`;

export const StyledUserEmail = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const StyledUserExpert = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  padding: 4px 8px;
  color: ${(p) => p.theme.colors["neutral-800"]};
  border-radius: 6px;
  background-color: ${(p) => p.theme.colors["main-500"]};
  & > svg {
    width: 16px;
    height: 16px;
  }
`;

export const StyledReviewDataBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 12px;
`;

export const StyledStars = styled(Stars)`
  ${(p) =>
    p.mobileNone
      ? `
    @media (max-width: ${p.theme.screenSizes.mdMax}) {
    display: none;
    }
    `
      : ""}
  ${(p) =>
    p.desctopNone
      ? `
    @media (min-width: ${p.theme.screenSizes.lgMin}) {
    display: none;
    }
    `
      : ""}
`;

export const StyledFlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledStatus = styled.div`
display: flex;
align-items: center;
gap: 8px;
font-size: ${(p) => p.theme.fontSizes.s};
line-height: 140%;


color: ${(p) => {
  if (p.status === "approved") return p.theme.colors["main-600"];
  if (p.status === "moderation") return p.theme.colors.orange;
  if (p.status === "rejected") return p.theme.colors["red-500"];
  return p.theme.colors["neutral-400"];
}};



&  svg {
  width: 20px;
  height: 20px;
}

& > span {
  display: none;
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
        display: inline-block;
    }
`;

export const ModalButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;

  margin-top: 24px;

  & > button {
    flex: 1 0 150px;
  }
`;
