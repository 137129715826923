import { useState } from "react";

import { useTranslation } from "react-i18next";

import { ua, us } from "../../../../assets/img/flags";
import { Progress } from "../../../../components/common/Progress/Progress";
import { Button } from "../../../../components/ui/Button/Button";
import { Icon } from "../../../../components/ui/Icon/Icon";
import { Status } from "../../../../components/ui/Status/Status";

import {
  AdditionalText,
  AnalyzingWrapper,
  DnsTestCard,
  DnsTestContainer,
  FlagWrapper,
  IPAdress,
  IPInfoBox,
  IPWrapper,
  InfoCard,
  InfoContainer,
  InfoImage,
  InfoImageWrapper,
  InfoMessage,
  LoaderIcon,
  ProgressWrapper,
  RestyledTitle,
  ResultsWrapper,
  SecurityReport,
  TitleWrapper
} from "./DnsLeakTest.styled";
import { ResultsTable } from "./ResultTable/ResultTable";

export const DnsLeakTest = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);
  const mockResult = {
    status: "VULNERABLE",
    ipAddress: "155.15.46.225",
    flag: ua,
    securityReport: "Security report 2024-01-06 12:45:59",
    tableData: [
      {
        ip: "157.35.306.44",
        provider: "Google LLC",
        flag: us,
        country: "United States"
      },
      {
        ip: "157.35.306.44",
        provider: "Google LLC",
        flag: ua,
        country: "Ukraine"
      },
      {
        ip: "157.35.306.44",
        provider: "Google LLC",
        flag: us,
        country: "United States"
      },
      {
        ip: "157.35.306.44",
        provider: "Google LLC",
        flag: us,
        country: "United States"
      },
      {
        ip: "157.35.306.44",
        provider: "Content Delivery Network Ltd",
        flag: ua,
        country: "Ukraine"
      },
      {
        ip: "157.35.306.44",
        provider: "Content Delivery Network Ltd",
        flag: ua,
        country: "Ukraine"
      },
      {
        ip: "157.35.306.44",
        provider: "Content Delivery Network Ltd",
        flag: ua,
        country: "Ukraine"
      },
      {
        ip: "157.35.306.44",
        provider: "Content Delivery Network Ltd",
        flag: ua,
        country: "Ukraine"
      },
      {
        ip: "157.35.306.44",
        provider: "Content Delivery Network Ltd",
        flag: ua,
        country: "Ukraine"
      }
    ]
  };

  const handleClick = () => {
    setIsLoading(true);

    setTimeout(() => {
      setResult(mockResult);
      setIsLoading(false);
    }, 1000);
  };

  const analyzingWrapper = (mobileDirection, mbTitle) => (
    <AnalyzingWrapper mobileDirection={mobileDirection}>
      <RestyledTitle tag="h2" mb={mbTitle}>
        {t("dnsLeak.analyzing")}
      </RestyledTitle>
      <AdditionalText>
        <LoaderIcon name="loading" />
        {t("dnsLeak.pleaseWait")}
      </AdditionalText>
      <ProgressWrapper>
        <Progress isLoading />
      </ProgressWrapper>
    </AnalyzingWrapper>
  );

  return (
    <DnsTestContainer>
      <DnsTestCard>
        <InfoContainer>
          <InfoCard>
            <InfoImageWrapper>
              <InfoImage
                src="img/dns-leak-test/dns-leak-test-image.svg"
                alt={t("dnsLeak.main.title")}
              />
            </InfoImageWrapper>
            <TitleWrapper>
              <RestyledTitle tag="h2">
                {result ? t("dnsLeak.result") : t("dnsLeak.main.title")}
              </RestyledTitle>
              {result ? <Status label={result.status} color="warning" /> : null}
            </TitleWrapper>
            <InfoMessage>
              <img src="img/icons/information.svg" alt="Info icon" />
              <span>
                {result ? t("dnsLeak.notDetected") : t("dnsLeak.verify")}
              </span>
            </InfoMessage>
            <Button type="button" onClick={handleClick} fullWidth>
              {result ? t("dnsLeak.stopDns") : t("dnsLeak.runDns")}
            </Button>
          </InfoCard>
          {isLoading && analyzingWrapper(true, 16)}
        </InfoContainer>
        <ResultsWrapper>
          {isLoading && analyzingWrapper(false, 12)}
          {!isLoading && !!result && (
            <>
              <IPWrapper>
                <IPInfoBox>
                  <RestyledTitle tag={"h2"}>
                    {t("dnsLeak.yourIP")}
                  </RestyledTitle>
                  <FlagWrapper>
                    <img src={result.flag} />
                    <IPAdress>{result.ipAddress}</IPAdress>
                  </FlagWrapper>
                </IPInfoBox>
                <SecurityReport>
                  <Icon name="file" />
                  {result.securityReport}
                </SecurityReport>
              </IPWrapper>
              <ResultsTable data={result.tableData} isLoading={isLoading} />
            </>
          )}
        </ResultsWrapper>
      </DnsTestCard>
    </DnsTestContainer>
  );
};
