import styled from "@emotion/styled";

import { Alert } from "../../components/ui/Alert/Alert";
import { Button } from "../../components/ui/Button/Button";
import { Subtitle } from "../../components/ui/Subtitle/Subtitle";
import { TextButton } from "../../components/ui/TextButton/TextButton";
import { Title } from "../../components/ui/Title/Title";

const MT_TITLE = {
  mobile: {
    main: "172px",
    success: "132px",
    recovery: "112px"
  },
  desktop: {
    main: "160px",
    success: "212px",
    recovery: "112px"
  }
};

const MB_SUBTITLE = {
  mobile: {
    main: "32px",
    success: "24px"
  },
  desktop: {
    main: "36px",
    success: "24px"
  }
};

export const StyledAuthTitle = styled(Title)`
  margin-top: ${(p) =>
    p.variant ? MT_TITLE.mobile[p.variant] : MT_TITLE.mobile.main};
  text-align: center;
  margin-bottom: ${(p) => {
    if (p.noSub) {
      return p.variant
        ? MB_SUBTITLE.mobile[p.variant]
        : MB_SUBTITLE.mobile.main;
    } else {
      return "0";
    }
  }};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-top: ${(p) =>
      p.variant ? MT_TITLE.desktop[p.variant] : MT_TITLE.desktop.main};
    margin-bottom: ${(p) => {
      if (p.noSub) {
        return p.variant
          ? MB_SUBTITLE.desktop[p.variant]
          : MB_SUBTITLE.desktop.main;
      } else {
        return "0";
      }
    }};
  }
`;

export const StyledSubTitle = styled(Subtitle)`
  margin-top: 8px;
  max-width: 550px;
  text-align: center;
  margin-bottom: ${(p) =>
    p.variant ? MB_SUBTITLE.mobile[p.variant] : MB_SUBTITLE.mobile.main};
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: ${(p) =>
      p.variant ? MB_SUBTITLE.desktop[p.variant] : MB_SUBTITLE.desktop.main};
    ${(p) =>
      p.variant === "success" &&
      `font-size: ${p.theme.fontSizes.l}; line-height: 166%;`}
  }
`;

export const StyledAuthForm = styled.form`
  display: flex;
  max-width: 550px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 24px;

  & textarea {
    min-height: 132px;
  }
`;

export const StyledButtonSubmit = styled(Button)`
  margin-top: 24px;
  width: 100%;
`;

export const StyledAgreeButton = styled(TextButton)`
  color: ${(p) => p.theme.colors["neutral-800"]};
  &:hover,
  &:focus {
    font-weight: 400;
  }
`;

export const StyledAgreeLabel = styled.p`
  color: ${(p) => p.theme.colors["neutral-500"]};
  word-break: break-all;
`;

export const StyledAuthFormComponentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
`;

export const StyledRecoveryBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
`;

export const StyledRecoveryButton = styled(TextButton)`
  font-size: ${(p) => p.theme.fontSizes.s};
  line-height: 140%;
`;

export const StyledSuccessImage = styled.img`
  margin-top: ${(p) => p.mt || 0}px;
  margin-bottom: ${(p) => p.mb || 0}px;
  max-width: 260px;
  font-size: 0;
`;
export const StyledSuccessPicture = styled.picture`
  font-size: 0;
`;

export const StyledSpam = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-top: 24px;
  margin-bottom: 48px;
  padding: 0 12px;
  color: ${(p) => p.theme.colors["neutral-500"]};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  font-style: ${(p) => p.theme.fontStyle.normal};
  line-height: 140%;

  & svg {
    flex: none;
    width: 20px;
    height: 20px;
  }
`;

export const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
  max-width: 521px;
`;
