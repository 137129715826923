import styled from "@emotion/styled";

export const ResultTableWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.card};
  padding: 16px 16px 0 16px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    width: 100%;
    padding: 0;
    box-shadow: none;
    margin-left: 5px;
    margin-right: 0;
  }

  &::after {
    content: "";
    position: absolute;
    pointer-events: none;
    right: 23px;
    bottom: 16px;

    height: 69px;
    width: calc(100% - 23px);

    background: transparent;

    @media screen and (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
      background: ${(p) => p.scrollBottom ? "transparent" : "linear-gradient(0deg, #fff 23.68%, #ffffff00 63.16%)"};
    }
  }
`;

export const StyledResultTable = styled.div`
  padding-bottom: 16px;
  width: 100%;
`;

export const TableBody = styled.div`
  max-height: 382px;
  overflow-y: auto;
`;

export const TableHeadRow = styled.div`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors["background-color"]};
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 48px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    gap: 64px;
  }
`;

export const TableHeadCol = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  padding: 8px 0px 8px 24px;
  align-items: center;
  color: ${({ theme }) => theme.colors["neutral-400"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42;
  text-transform: uppercase;
`;

export const TableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 48px;
  border-bottom: 1px solid rgba(216, 218, 224, 0.55);

  @media (max-width: ${(p) => p.theme.screenSizes.lgMin}) {
    border-bottom: none !important;

    &:nth-of-type(even) {
      background-color: rgba(246, 247, 249, 0.6);
    }
  }

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    gap: 64px;
  }
`;

export const TableCol = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  padding: 8px 0px 8px 24px;
  align-items: center;
  color: ${({ theme }) => theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
`;

export const TableText = styled.span`
  color: ${({ theme }) => theme.colors["neutral-800"]};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
`;
