import styled from "@emotion/styled";

import { TextButton } from "../../ui/TextButton/TextButton";
import { Title } from "../../ui/Title/Title";

export const StyledRecentReviewsContainer = styled.div`
  position: relative;
  padding-bottom: 24px;
  width: 100%;
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    padding-bottom: ${(p) => (p.isSitePage ? "32px" : "0")};
    ${(p) => (p?.isSitePage ? "" : "width: 50%;")}
  }

  &.is_hidden {
    display: none;
    overflow: hidden;
    margin: 0 !important;
  }
`;

export const StyledRecentReviewsTitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  ${(p) =>
    !p?.isSitePage
      ? `margin-left: 0;
         margin-right: 0;`
      : ""}
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    margin-bottom: ${(p) => (p.isSitePage ? "24px" : "48px")};
    ${(p) => (p?.isSitePage ? "" : "align-items: baseline;")}
    margin-left: ${(p) => (p.isSitePage ? "32px" : "0")};
    margin-right: ${(p) => (p.isSitePage ? "32px" : "0")};
  }
`;

export const StyledRecentReviewsTitle = styled(Title)`
  margin-top: 32px;
  ${(p) =>
    p.isSitePage
      ? `font-size: ${p.theme.fontSizes.xl} !important;`
      : "margin-left: 16px;"}

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    margin-left: 0;
    margin-top: 0px;
    margin-right: 32px;
    width: auto;
    ${(p) =>
      p?.isSitePage ? `font-size: ${p.theme.fontSizes.xll} !important` : ""};
  }
`;

export const StyledRecentReviewsList = styled.ul`
  list-style: none;
  padding: 0;
  display: none;
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: auto;
    overflow-y: visible;
    margin: 0 !important;
    margin-bottom: 32px;
  }
`;

export const StyledRecentReviewsListItem = styled.li`
  ${(p) =>
    p.isSitePage
      ? `
  margin-left: 32px;
  margin-right: 32px;
  width: calc(100% - 64px);`
      : ""}
`;

export const StyledRecentReviewsListMobileBox = styled.div`
  margin: 0 ${(p) => (p.isSitePage ? "-15px" : "0")};
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    margin: 0 !important;
    display: none;
  }
`;

export const StyledRecentReviewsAllButton = styled(TextButton)`
  position: absolute;
  @media (max-width: ${(p) => p.theme.screenSizes.lgMax}) {
    bottom: 0;
    left: ${(p) => (p?.isSitePage ? "0" : "24px")};
  }
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    position: relative;
  }
`;
