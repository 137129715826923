/* eslint-disable no-nested-ternary */
import PT from "prop-types";
import { useTranslation } from "react-i18next";

import {
  Description,
  StatusImage,
  StatusValue,
  StatusWrapper
} from "./AnonymityStatus.styled";

export const AnonymityStatus = ({ value }) => {
  const { t } = useTranslation();
  return (
    <StatusWrapper>
      <StatusImage
        src={
          value > 50
            ? "img/ui/disguise-green.svg"
            : value > 20
            ? "img/ui/disguise-yellow.svg"
            : "img/ui/disguise-red.svg"
        }
      />
      <StatusValue>
        {t("myAnonymity.yourDisguise")} <span>{value}%</span>
      </StatusValue>
      <Description>{t("myAnonymity.info")}</Description>
    </StatusWrapper>
  );
};

AnonymityStatus.propTypes = {
  value: PT.number.isRequired
};
