import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";

export const StyledUserChoiceContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 64px;
  padding: 0;

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    flex-direction: row;
    gap: 12px;
    margin-bottom: 120px;
    padding: 0 15px;
  }
  // @media (min-width: 1530px) {
  //   padding: 0 11.5vw;
  // }
`;
