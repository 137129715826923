import styled from "@emotion/styled";

import { Flag as BaseFlag } from "../../../../../components/ui/Flag/Flag";

export const DropdownLabel = styled.div`
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;

  & svg {
    margin-left: 8px;
    cursor: pointer;
    flex-shrink: 0;
  }
`;

export const MenuBodyContainer = styled.div`
  max-height: 300px;
  overflow: auto;
  margin-top: 10px;
`;

export const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const MenuListItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active,
  &[aria-selected="true"] {
    border-radius: 8px;
    background: #f6f7f9cc;
  }
`;

export const Flag = styled(BaseFlag)`
  margin-right: 8px;
`;

export const CountryName = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-size: ${({ theme }) => theme.fontSizes.m};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: 150%;
  text-transform: capitalize;
`;
