import styled from "@emotion/styled";

import { Icon } from "../../../../../../components/ui/Icon/Icon";

export const StyledNavigation = styled.ul`
  display: flex;
  padding-bottom: 64px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 120px;
  }
`;

export const StyledNavigationItem = styled.li`
  width: 100%;
  cursor: pointer;
  display: ${(p) => (p.next ? "flex" : "block")};
  justify-content: flex-end;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 450px;
  }

  & .wrapper-next {
    justify-content: flex-end;
  }
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors["neutral-400"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42;
  text-transform: uppercase;
  transition: color 0.3s ease;

  &:hover,
  &:focus,
  &:active {
    color: ${(p) => p.theme.colors["main-600"]};
    transition: color 0.3s ease;
    & .icon-wrapper {
      transition: background-color 0.3s ease;
      border-radius: 10px;
      background-color: ${(p) => p.theme.colors["background-color"]};
    }
  }
`;

export const StyledText = styled.span`
  margin-left: ${(p) => (p.variant === "next" ? "0" : "8px")};
  margin-right: ${(p) => (p.variant === "next" ? "8px" : "0")};
`;

export const StyledTitle = styled.span`
  display: none;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: inline-block;
    color: ${(p) => p.theme.colors["neutral-800"]};
    font-family: ${(p) => p.theme.fontFamily.lato};
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    margin-left: ${(p) => (p.variant === "next" ? "0" : "48px")};
    margin-right: ${(p) => (p.variant === "next" ? "48px" : "0")};
  }
`;

export const StyledIcon = styled(Icon)`
  transform: ${(p) =>
    p.variant === "next" ? "rotate(-90deg)" : "rotate(90deg)"};
`;
