/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { ExpandableComponent } from "../../../../../components/common/ExpandableCompononent/ExpandableComponent";
import { StyledDataTable } from "../../../../../components/common/Table/Table.styled";
import {
  BoxCell,
  CustomExpandCell,
  HeaderNameCell,
  IndexCell,
  NoDataComponent,
  SiteNameCell,
  TextCell
} from "../../../../../components/common/TableColumns";
import { YesNo } from "../../../../../components/common/YesNo/YesNo";
import { SkeletonProxyPageTable } from "../../../../../components/ui/Skeleton/SkeletonProxyPageTable/SkeletonProxyPageTable";
import { TextButton } from "../../../../../components/ui/TextButton/TextButton";
import { useLangUrlDefault } from "../../../../../hooks";
import { time } from "../../../../../utils/helpers";

export const ProxyTable = ({ isLoading, tableData, skip }) => {
  const [queryLang, hrefLang] = useLangUrlDefault();

  const { t } = useTranslation();

  const [data, setData] = useState(tableData);

  const handleExpand = (event) => {
    const id = event.currentTarget.id.replace("expand-", "");
    const newData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          expanded: !item.expanded
        };
      }
      return { ...item, expanded: false };
    });
    setData(newData);
  };

  const columns = [
    {
      name: (
        <HeaderNameCell width={50} center>
          №
        </HeaderNameCell>
      ),
      selector: (row, index) => {
        const currentNumber = index + 1 + skip;
        return (
          <BoxCell width={50}>
            <IndexCell top={row?.topSite}>
              {`${currentNumber < 10 ? "0" : ""}${currentNumber}.`}
            </IndexCell>
          </BoxCell>
        );
      },
      width: "50px"
      // center: true
    },
    {
      name: (
        <HeaderNameCell minWidth={290}>
          {t("proxyPage.content.table.name.name")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell minWidth={290}>
          <SiteNameCell
            imgUrl={row?.imageFile}
            name={row?.name}
            rating={row?.rating}
            showRating
            showAnnotation
            positive={row?.ratingPositive}
            negative={row?.ratingNegative}
            showReviewButton={row?.showReviewButton}
            reviewButtonUrl={row?.reviewButtonUrl}
            // linkToReview={`${hrefLang}/proxy/${row.alias}/#reviews`}
          />
        </BoxCell>
      ),
      width: "290px"
    },
    {
      name: (
        <HeaderNameCell width={110} sortable>
          {t("proxyPage.content.table.minPrice.title")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell width={110}>
          <TextCell>{`$${row?.price}`}</TextCell>
        </BoxCell>
      ),
      sortable: true,
      selector: (row) => row.price
      // width: "110px"
    },
    {
      name: (
        <HeaderNameCell width={110} sortable>
          {t("proxyPage.content.table.minRent.title")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell width={110}>
          <TextCell>
            {time(
              row?.minRentPeriod?.periodName,
              row?.minRentPeriod?.periodValue,
              queryLang,
              t("times", { returnObjects: true })
            )}
          </TextCell>
        </BoxCell>
      ),
      sortable: true,
      selector: (row) => row?.minRentPeriod?.periodMilliseconds
      // width: "110px"
    },
    {
      name: (
        <HeaderNameCell width={90}>
          {t("proxyPage.content.table.freeTest.title")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell width={90}>
          <YesNo value={row?.freeTest} />
        </BoxCell>
      )
      // width: "90px"
    },
    {
      name: (
        <HeaderNameCell width={105}>
          {t("proxyPage.content.table.site.title")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell width={105}>
          <TextButton
            size={"sm"}
            color={"table"}
            iconRight={"arrowAltRight"}
            linkTo={`${hrefLang}/proxy/${row.alias}/`}
          >
            {t("proxyPage.content.table.site.btn")}
          </TextButton>
        </BoxCell>
      )
      // width: "105px"
    },
    {
      cell: (row) => (
        <BoxCell width={44}>
          <CustomExpandCell
            id={`expand-${row.id}`}
            open={row?.expanded}
            onClick={handleExpand}
          />
        </BoxCell>
      ),
      width: "44px"
    }
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row?.expanded,
      style: {
        backgroundColor: "#f6f7f9cc !important"
      }
    }
  ];

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  return (
    <StyledDataTable
      styleRules={{
        row: {
          noBorder: true
          // noPadding: true
        }
      }}
      columns={columns}
      data={data}
      progressPending={isLoading}
      progressComponent={<SkeletonProxyPageTable cells={10} />}
      persistTableHead={!isLoading}
      noDataComponent={<NoDataComponent />}
      conditionalRowStyles={conditionalRowStyles}
      expandableRowExpanded={(row) => row?.expanded}
      expandableRowsComponent={ExpandableComponent}
      expandableRows
      expandableRowsHideExpander
    />
  );
};
