import styled from "@emotion/styled";

import { TextButton } from "../../ui/TextButton/TextButton";

export const UploaderWrapperStyled = styled.label`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 140px;
  height: 100%;

  border-radius: 8px;
  border: 1px dashed ${(p) => p.theme.colors["outline-color"]};
  padding: 20px;

  transition: border-color 0.3s ease;

  &:hover {
    border-color: ${(p) => p.theme.colors["neutral-800"]};
    transition: border-color 0.3s ease;
  }

  //   &:focus-within {
  //     border-color: ${(p) => p.theme.colors["main-700"]};
  //     transition: border-color 0.3s ease;
  //   }

  &::after {
    content: "";
    position: absolute;
    z-index: ${(p) => (p.isDragging ? 5 : 0)};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    background-color: ${(p) => (p.isDragging ? "#00000052" : "transparent")};
    transition: background-color 0.5s ease;
  }
`;

export const UploaderInputStyled = styled.input`
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -1;
  clip: rect(0 0 0 0);
`;

export const UploaderIconStyled = styled.div`
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors["background-color"]};
  color: ${(p) => p.theme.colors["neutral-800"]};
  padding: 12px;
  margin-bottom: 8px;
  font-size: 0px;

  & > svg,
  & > img {
    width: 28px;
    height: 28px;
  }

  & > img {
    object-fit: contain;
  }

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 16px;

    & > svg,
    & > img {
      width: 32px;
      height: 32px;
    }
  }
`;

export const UploaderSubActionBoxStyled = styled.div`
  z-index: 100;

  display: flex;
  flex-direction: column;
  align-items: center;

  color: ${(p) => p.theme.colors["neutral-500"]};
  text-align: center;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.xxs};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 165%;
`;

export const TextActionStyled = styled.p`
  width: 100%;
  font-size: ${(p) => p.theme.fontSizes.s};
  line-height: 170%;

  &::after {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      content: "${(p) => p["data-text"]}";
    }
  }
`;

export const RestyledTextButton = styled(TextButton)`
  color: ${(p) => p.theme.colors["neutral-800"]};
  cursor: pointer !important;
  & > svg {
    width: 16px;
    height: 16px;
  }

  &#delete-image {
    color: ${(p) => p.theme.colors["red-500"]};
  }

  & > span::after {
    width: 100%;
  }
`;
