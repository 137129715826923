import { useId, useRef } from "react";

import {
  StyledTab,
  StyledTabButton,
  StyledTabsList
} from "./GroupButtonTab.styled";

export const GroupButtonTab = ({ data, active, onSelect, variant }) => {
  const id = useId();
  const ref = useRef();
  const handleClick = (event) => {
    const value = event.currentTarget.id.replace(`${id}-`, "");
    onSelect?.(value);
  };

  // useEffect(() => {
  //   if (ref.current) {
  //     const activeTab = ref.current.querySelector("button[aria-pressed=true]");
  //     if (activeTab) {
  //       activeTab.scrollIntoView({
  //         behavior: "smooth",
  //         block: "nearest",
  //         inline: "center"
  //       });
  //     }
  //   }
  // }, [active]);

  return data?.length ? (
    <StyledTabsList variant={variant} ref={ref}>
      {data.map((it) => (
        <StyledTab key={`tab-${it?.value}`}>
          <StyledTabButton
            active={active === it.value}
            id={`${id}-${it.value}`}
            onClick={handleClick}
            variant={variant}
            aria-pressed={active === it.value}
            type="button"
          >
            {it?.label}
          </StyledTabButton>
        </StyledTab>
      ))}
    </StyledTabsList>
  ) : null;
};
