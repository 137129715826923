import styled from "@emotion/styled";

import { Dropdown } from "../../ui/Dropdown/Dropdown";

export const ReStyledDropdown = styled(Dropdown)`
  display: none;
  @media screen and (min-width: ${(props) => props.theme.screenSizes.lgMin}) {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    padding: 8px;

    outline: none;
    border: none;
    background-color: transparent;
  }
`;

export const ReStyledDropdownItem = styled(Dropdown.Item)`
  min-width: 140px;
`;

export const StyledSelectBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;

  @media (min-width: ${(props) => props.theme.screenSizes.lgMin}) {
    display: none;
    width: 0px;
  }

  .base-Select-root {
    & > svg {
      width: 24px;
      height: 24px;
    }
  }
`;
