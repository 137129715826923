import { ListMobile } from "./ListMobile.styled";
import { ListMobileItemComponent } from "./ListMobileItem";

export const ListMobileComponent = ({ data, status, userIp }) => (
  <ListMobile>
    {status ? (
      data.map((item, index) => (
        <ListMobileItemComponent
          key={index}
          ip={item.ip}
          type={item.type}
          status={status}
        />
      ))
    ) : (
      <ListMobileItemComponent ip={userIp} status={status} />
    )}
  </ListMobile>
);
