import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FormSelect } from "../../../../components/forms/FormSelect/FormSelect";
import { getAllCabinet } from "../../../../store/reducers/CabinetReducer/Cabinet.selectors";
import { getCabinetSitesForSelect } from "../../../../utils/helpers";
import { FormBlock } from "../../components/FormBlock/FormBlock";

export const BlockSites = () => {
  const { t } = useTranslation();

  const { allProxies } = useSelector(getAllCabinet);
  return (
    <FormBlock
      title={t("dashboard.promocodes.addEdit.blocks.site.title")}
      subtitle={t("dashboard.promocodes.addEdit.blocks.site.subtitle")}
    >
      <FormSelect
        options={getCabinetSitesForSelect(allProxies.data)}
        label={t("promocodes.site.label")}
        name="siteId"
        placeholder={t("promocodes.site.placeholder")}
        noData={t("promocodes.site.noData")}
        showError
      />
    </FormBlock>
  );
};
