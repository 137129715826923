import PT from "prop-types";

import { Icon } from "../Icon/Icon";

import { StyledTextButton, StyledTextLink } from "./TextButton.styled";

const BUTTON_TYPES = ["button", "submit", "reset", "link"];

export const TextButton = ({
  id,
  className = "",
  color = "default",
  type = "button",
  size = "md",
  disabled = false,
  style,
  onClick,
  children,
  ariaLabel,
  iconRight,
  iconLeft,
  linkTo,
  target,
  dataTestId
}) => {
  if (linkTo || type === "link") {
    return (
      <StyledTextLink
        id={id}
        {...(dataTestId && { "data-testid": dataTestId })}
        to={linkTo}
        className={className}
        color={color}
        size={size}
        style={style}
        onClick={(event) => {
          if (disabled) event.preventDefault();
          if (!disabled && onClick) onClick(event);
        }}
        aria-label={ariaLabel}
        disabled={disabled}
        type={type}
        {...(target && { target })}
      >
        {!!iconLeft && <Icon name={iconLeft} />}
        {!!children && <span>{children}</span>}
        {!!iconRight && <Icon name={iconRight} />}
      </StyledTextLink>
    );
  }

  return (
    <StyledTextButton
      id={id}
      data-testid={dataTestId}
      className={className}
      onClick={onClick}
      aria-label={ariaLabel}
      style={style}
      disabled={disabled}
      color={color}
      size={size}
      type={type}
    >
      {!!iconLeft && <Icon name={iconLeft} />}
      {!!children && <span>{children}</span>}
      {!!iconRight && <Icon name={iconRight} />}
    </StyledTextButton>
  );
};

TextButton.propTypes = {
  className: PT.string,
  color: PT.oneOf(["primary", "secondary", "error", "default", "table"]),
  size: PT.oneOf(["md", "sm"]),
  type: PT.oneOf(BUTTON_TYPES),
  disabled: PT.bool,
  style: PT.shape({}),
  onClick: PT.func,
  children: PT.node,
  ariaLabel: PT.string,
  iconRight: PT.string,
  iconLeft: PT.string,
  linkTo: PT.string,
  target: PT.string,
  id: PT.string
};
