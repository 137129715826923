export const FLAGS_POSITION = {
  af: { x: -20, y: -20 },
  al: { x: -82, y: -20 },
  dz: { x: -20, y: -76 },
  ad: { x: -82, y: -76 },
  ao: { x: -144, y: -20 },
  ag: { x: -144, y: -76 },
  ar: { x: -20, y: -132 },
  am: { x: -82, y: -132 },
  aw: { x: -144, y: -132 },
  au: { x: -206, y: -20 },
  at: { x: -206, y: -76 },
  az: { x: -206, y: -132 },
  bs: { x: -20, y: -188 },
  bh: { x: -82, y: -188 },
  bb: { x: -144, y: -188 },
  bd: { x: -206, y: -188 },
  by: { x: -268, y: -20 },
  be: { x: -268, y: -76 },
  bz: { x: -268, y: -132 },
  bj: { x: -268, y: -188 },
  bt: { x: -20, y: -244 },
  bo: { x: -82, y: -244 },
  ba: { x: -144, y: -244 },
  bw: { x: -206, y: -244 },
  bv: { x: -268, y: -244 },
  br: { x: -330, y: -20 },
  bn: { x: -330, y: -76 },
  bg: { x: -330, y: -132 },
  bf: { x: -330, y: -188 },
  bi: { x: -330, y: -244 },
  kh: { x: -20, y: -300 },
  cm: { x: -82, y: -300 },
  ca: { x: -144, y: -300 },
  cv: { x: -206, y: -300 },
  cf: { x: -268, y: -300 },
  td: { x: -330, y: -300 },
  cl: { x: -392, y: -20 },
  cn: { x: -392, y: -76 },
  co: { x: -392, y: -132 },
  km: { x: -392, y: -188 },
  cg: { x: -392, y: -244 },
  cr: { x: -392, y: -300 },
  hr: { x: -20, y: -356 },
  cu: { x: -82, y: -356 },
  cw: { x: -144, y: -356 },
  cy: { x: -206, y: -356 },
  cz: { x: -268, y: -356 },
  dk: { x: -330, y: -356 },
  dj: { x: -392, y: -356 },
  dm: { x: -454, y: -20 },
  do: { x: -454, y: -76 },
  cd: { x: -454, y: -132 },
  ec: { x: -454, y: -188 },
  eg: { x: -454, y: -244 },
  sv: { x: -454, y: -300 },
  gq: { x: -454, y: -356 },
  er: { x: -20, y: -412 },
  ee: { x: -82, y: -412 },
  sz: { x: -144, y: -412 },
  et: { x: -206, y: -412 },
  eu: { x: -268, y: -412 },
  fj: { x: -330, y: -412 },
  fi: { x: -392, y: -412 },
  fr: { x: -454, y: -412 },
  gf: { x: -454, y: -412 },
  gp: { x: -454, y: -412 },
  mq: { x: -454, y: -412 },
  yt: { x: -454, y: -412 },
  re: { x: -454, y: -412 },
  bl: { x: -454, y: -412 },
  mf: { x: -454, y: -412 },
  pm: { x: -454, y: -412 },
  wf: { x: -454, y: -412 },
  ga: { x: -516, y: -20 },
  gm: { x: -516, y: -76 },
  ge: { x: -516, y: -132 },
  de: { x: -516, y: -188 },
  gh: { x: -516, y: -244 },
  gr: { x: -516, y: -300 },
  gd: { x: -516, y: -356 },
  gt: { x: -516, y: -412 },
  gn: { x: -20, y: -468 },
  gw: { x: -82, y: -468 },
  gy: { x: -144, y: -468 },
  ht: { x: -206, y: -468 },
  va: { x: -268, y: -468 },
  hn: { x: -330, y: -468 },
  hk: { x: -392, y: -468 },
  hu: { x: -454, y: -468 },
  is: { x: -516, y: -468 },
  in: { x: -578, y: -20 },
  id: { x: -578, y: -76 },
  ir: { x: -578, y: -132 },
  iq: { x: -578, y: -188 },
  ie: { x: -578, y: -244 },
  il: { x: -578, y: -300 },
  it: { x: -578, y: -356 },
  ci: { x: -578, y: -412 },
  jm: { x: -578, y: -468 },
  jp: { x: -20, y: -524 },
  jo: { x: -82, y: -524 },
  kz: { x: -144, y: -524 },
  ke: { x: -206, y: -524 },
  ki: { x: -268, y: -524 },
  kw: { x: -330, y: -524 },
  kg: { x: -392, y: -524 },
  la: { x: -454, y: -524 },
  lv: { x: -516, y: -524 },
  lb: { x: -578, y: -524 },
  ls: { x: -20, y: -580 },
  lr: { x: -82, y: -580 },
  ly: { x: -144, y: -580 },
  li: { x: -206, y: -580 },
  lt: { x: -268, y: -580 },
  lu: { x: -330, y: -580 },
  mg: { x: -392, y: -580 },
  mw: { x: -454, y: -580 },
  my: { x: -516, y: -580 },
  mv: { x: -578, y: -580 },
  ml: { x: -640, y: -20 },
  mt: { x: -640, y: -76 },
  mh: { x: -640, y: -132 },
  mr: { x: -640, y: -188 },
  mu: { x: -640, y: -244 },
  mx: { x: -640, y: -300 },
  fm: { x: -640, y: -356 },
  md: { x: -640, y: -412 },
  mc: { x: -640, y: -468 },
  mn: { x: -640, y: -524 },
  me: { x: -640, y: -580 },
  ma: { x: -20, y: -636 },
  mz: { x: -82, y: -636 },
  mm: { x: -144, y: -636 },
  na: { x: -206, y: -636 },
  nr: { x: -268, y: -636 },
  np: { x: -330, y: -636 },
  nl: { x: -392, y: -636 },
  nz: { x: -454, y: -636 },
  ni: { x: -516, y: -636 },
  ne: { x: -578, y: -636 },
  ng: { x: -640, y: -636 },
  kp: { x: -702, y: -20 },
  mk: { x: -702, y: -76 },
  no: { x: -702, y: -132 },
  om: { x: -702, y: -188 },
  pk: { x: -702, y: -244 },
  pw: { x: -702, y: -300 },
  ps: { x: -702, y: -356 },
  pa: { x: -702, y: -412 },
  pg: { x: -702, y: -468 },
  py: { x: -702, y: -524 },
  pe: { x: -702, y: -580 },
  ph: { x: -702, y: -636 },
  pl: { x: -20, y: -692 },
  pt: { x: -82, y: -692 },
  qa: { x: -144, y: -692 },
  ro: { x: -206, y: -692 },
  ru: { x: -268, y: -692 },
  rw: { x: -330, y: -692 },
  lc: { x: -392, y: -692 },
  vc: { x: -454, y: -692 },
  ws: { x: -516, y: -692 },
  sm: { x: -578, y: -692 },
  st: { x: -640, y: -692 },
  sa: { x: -702, y: -692 },
  sn: { x: -764, y: -20 },
  rs: { x: -764, y: -76 },
  sc: { x: -764, y: -132 },
  sl: { x: -764, y: -188 },
  sg: { x: -764, y: -244 },
  sx: { x: -764, y: -300 },
  sk: { x: -764, y: -356 },
  si: { x: -764, y: -412 },
  sb: { x: -764, y: -468 },
  so: { x: -764, y: -524 },
  za: { x: -764, y: -580 },
  ss: { x: -764, y: -636 },
  es: { x: -764, y: -692 },
  lk: { x: -20, y: -748 },
  sd: { x: -82, y: -748 },
  sr: { x: -144, y: -748 },
  se: { x: -206, y: -748 },
  ch: { x: -268, y: -748 },
  sy: { x: -330, y: -748 },
  tj: { x: -392, y: -748 },
  tz: { x: -454, y: -748 },
  th: { x: -516, y: -748 },
  tl: { x: -578, y: -748 },
  tg: { x: -640, y: -748 },
  to: { x: -702, y: -748 },
  tt: { x: -764, y: -748 },
  tn: { x: -826, y: -20 },
  tr: { x: -826, y: -76 },
  tm: { x: -826, y: -132 },
  tv: { x: -826, y: -188 },
  ug: { x: -826, y: -244 },
  ua: { x: -826, y: -300 },
  ae: { x: -826, y: -356 },
  gb: { x: -826, y: -412 },
  uy: { x: -826, y: -468 },
  us: { x: -826, y: -524 },
  um: { x: -826, y: -524 },
  uz: { x: -826, y: -580 },
  vu: { x: -826, y: -636 },
  ve: { x: -826, y: -692 },
  vn: { x: -826, y: -748 },
  vi: { x: -20, y: -804 },
  ye: { x: -82, y: -804 },
  zm: { x: -144, y: -804 },
  zw: { x: -206, y: -804 },
  as: { x: -268, y: -804 },
  kr: { x: -330, y: -804 },
  en: { x: -826, y: -412 },
  tc: { x: -826, y: -412 },
  gs: { x: -826, y: -412 },
  sh: { x: -826, y: -412 },
  pn: { x: -826, y: -412 },
  an: { x: -826, y: -412 },
  ms: { x: -826, y: -412 },
  hm: { x: -826, y: -412 },
  ky: { x: -826, y: -412 },
  io: { x: -826, y: -412 },
  vg: { x: -826, y: -412 },
  ai: { x: -826, y: -412 },
  ax: { x: -516, y: -468 },
  eh: { x: -82, y: -524 },
  tw: { x: -516, y: -692 },
  sj: { x: -268, y: -244 }
};
