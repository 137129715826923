export const parseSeoData = (field, expectedType, replased) => {
  if (
    !field ||
    !field?.content ||
    (expectedType && expectedType !== field.type)
  ) {
    return expectedType !== "JSON" ? "" : null;
  }
  let { content } = field;
  if (replased) {
    replased.forEach((item) => {
      content = content.replace(new RegExp(item.from, "g"), item.to);
    });
  }
  if (field.type === "JSON") {
    try {
      return JSON.parse(content);
    } catch (e) {
      return null;
    }
  }

  if (field.type === "TEXT_FIELD") {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: content }}
        style={{ width: "100%" }}
      />
    );
  } else {
    return field.content;
  }
};
