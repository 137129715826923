// API
import { $api } from "./AxiosInstance";

export class ApiService {
  static getPageContent(params, signal) {
    return $api.post("/api/page/front/content", params, { signal });
  }

  static fetchSeoBlocks(keys, languageCode) {
    return $api.post("/api/front/seoBlock", { languageCode, keys });
  }

  static createDefaultUser(email, password) {
    return $api.post("/api/users/createDefaultUser", { email, password });
  }

  static getBlogContent(params, signal) {
    return $api.get("/api/front/site/blog/search", { params, signal });
  }

  static cacheSSR(id) {
    return $api.get(`/ssr/cache/${id}`);
  }

  static getBlogSidebarContent(languageCode, signal) {
    return $api.get("/api/front/site/blog/sidebar", {
      params: { languageCode },
      signal
    });
  }

  static getBlogContentByAlias(alias, languageCode) {
    return $api.get("/api/front/site/blog/view-article", {
      params: { alias, languageCode }
    });
  }

  static getStatistic() {
    return $api.get("/api/front/site/statistic");
  }

  static getAllProxies(sort = "sort") {
    return $api.get("/api/front/proxy/type/all", {
      params: { sort }
    });
  }

  static getAllCountries() {
    return $api.get("/api/front/all/country");
  }

  static getAllGoalGroups(languageCode = "en") {
    return $api.get("/api/front/goal/group/all", { params: { languageCode } });
  }

  static getAllGoals(languageCode = "en") {
    return $api.get("/api/goal/front/all", { params: { languageCode } });
  }

  static getGoalById(id) {
    return $api.get("/api/goal/group", { params: { id } });
  }

  static getAllTopSellers() {
    return $api.get("/api/front/site/top");
  }

  static getLastSiteReviews(count) {
    return $api.get("/api/front/site/reviews/last", {
      params: { count }
    });
  }

  static getTopSites() {
    return $api.get("/api/front/site/top");
  }

  static getProxySites(params, signal) {
    return $api.post("/api/front/site/proxy", params, { signal });
  }

  static saveErrorReport(object) {
    return $api.post("/api/front/error/report", object);
  }

  static getTypeProxySites(size) {
    return $api.get("/api/front/site/proxy/all", {
      params: { size }
    });
  }

  static getAdminUser() {
    return $api.get("/api/users/adminUser");
  }

  static getProxySiteInfo(id) {
    return $api.get("/api/front/site/proxy/description", { params: { id } });
  }

  static setSiteViewStatistic(siteId) {
    return $api.put("/api/front/site/view/statistic", { siteId });
  }

  static getProxySiteReviewsAmount(siteId) {
    return $api.get("/api/front/site/reviews/amount", {
      params: { siteId }
    });
  }

  static getReviewPageByReviewId(reviewId) {
    return $api.get("/api/front/site/review/pageNumber", {
      params: { reviewId }
    });
  }

  static getProxySiteRating(siteId, step) {
    return $api.get("/api/front/site/rating", {
      params: { siteId, step }
    });
  }

  static getProxySiteReviews(params) {
    return $api.get("/api/front/site/review/sort", {
      params
    });
  }

  static getProxyReviewsCabinet(params) {
    return $api.get("/api/cabinet/reviews", {
      params
    });
  }

  static getProxySiteCompaints(params) {
    return $api.post("/api/front/site/complaints-page", params);
  }

  static changeCommentCarma(commentId, step, headers) {
    return $api.get("/api/front/site/comments/karma", {
      params: { commentId, step },
      headers
    });
  }

  static changeReviewUsefulness(reviewId, useful, headers) {
    return $api.get("/api/front/site/reviews/useful", {
      params: { reviewId, useful },
      headers
    });
  }

  static getAllComments(params) {
    return $api.get("/api/front/site/comments/page", {
      params
    });
  }

  static createComment(
    targetId,
    ownerId,
    commentName,
    commentEmail,
    commentBody,
    commentLocale,
    headers
  ) {
    return $api.post(
      "/api/front/site/comments",
      {
        targetId,
        ownerId,
        commentName,
        commentEmail,
        commentBody,
        commentLocale
      },
      { headers }
    );
  }

  static login(username, password, remember) {
    return $api.post("/api/login", null, {
      params: {
        username,
        password,
        // eslint-disable-next-line no-unneeded-ternary
        "remember-me": remember === false ? false : true
      }
    });
  }

  static logout() {
    return $api.post("/api/logout");
  }

  static registerNewUser(username, password, locale, headers) {
    return $api.post(
      "/api/users/register",
      {
        email: username,
        password,
        locale
      },
      { headers }
    );
  }

  static getUserProfile() {
    return $api.get("/api/users/auth/profile");
  }

  static getUserAuth() {
    return $api.get("/api/users/authUser");
  }

  static isUserAuthenticated() {
    return $api.get("/api/users/user/authenticated");
  }

  static sendPasswordLink(email, headers) {
    return $api.get("/api/front/users/sendPasswordLink", {
      params: {
        email
      },
      headers
    });
  }

  static getEmailByToken(token) {
    return $api.get("/api/front/users/restoreToken", {
      params: {
        token
      }
    });
  }

  static restorePassword(params, headers) {
    return $api.post("/api/front/users/restore-password", params, { headers });
  }

  static changeEmail(params) {
    return $api.post("/api/users/changeEmail", params);
  }

  static resendEmailConfirmCode(locale) {
    return $api.post(`/api/users/resendEmail?locale=${locale}`);
  }

  static confirmEmailChange(token) {
    return $api.post(`/api/users/confirmChangeEmail/${token}`);
  }

  static changePassword(params) {
    return $api.post("/api/users/changePassword", params);
  }

  static resendPasswordConfirmCode(locale) {
    return $api.post(`/api/users/resendPassword?locale=${locale}`);
  }

  static confirmPasswordChange(token) {
    return $api.post(`/api/users/confirmPassword/${token}`);
  }

  static getCabinetSites(params) {
    return $api.post("/api/cabinet/sites", params);
  }

  static getUserPromoSitesList() {
    return $api.get("/api/cabinet/promocodes/sites");
  }

  static getAllCabinetSites() {
    return $api.get("/api/front/site/user/all");
  }

  static getAllCabinetSiteStatuses() {
    return $api.get("/api/front/site/status");
  }

  static getAllCurrencies() {
    return $api.get("/api/admin/currency/get/all");
  }

  static createSiteReview(review, headers) {
    return $api.post("/api/front/site/reviews", review, { headers });
  }

  // static createSiteReviewNew(review, headers) {
  //   return $api.post("/api/front/site/reviews/new", review, { headers });
  // }

  static updateSiteReview(params) {
    return $api.put("/api/front/site/reviews", params);
  }

  static deleteSiteReview(id) {
    return $api.delete("/api/front/site/reviews", {
      params: { id }
    });
  }

  static updateSiteReviewNew(params) {
    return $api.put("api/front/site/reviewsNew", params);
  }

  static deleteSiteReviewNew(id) {
    return $api.delete("/api/front/site/reviewsNew", {
      params: { id }
    });
  }

  static createComplaint(complaint) {
    return $api.post("/api/front/site/complaints", complaint);
  }

  static getComparisonSites(uuid) {
    return $api.get("/api/front/site/proxy/comparison", { params: { uuid } });
  }

  static updateComparsionSites(params) {
    return $api.post("/api/front/site/proxy/comparison", params);
  }

  static deleteComparisonSites(id) {
    return $api.delete("/api/front/site/proxy/comparison", {
      params: { id }
    });
  }

  static getComparisonWidgetInfo() {
    return $api.get("/api/front/site/proxy/comparison_widget");
  }

  static createProxySite(params) {
    return $api.post("/api/site/proxy/create", params);
  }

  static updateProxySite(params) {
    return $api.post("/api/site/proxy", params);
  }

  static updateProxySiteNew(params) {
    return $api.post("/api/site/proxyNew", params);
  }

  static uploadImage(formData, siteId, toast, trns) {
    return $api.post("/api/site/proxy/image/upload", formData, {
      params: { siteId },
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent?.lengthComputable
          ? progressEvent.total
          : progressEvent.target?.getResponseHeader("content-length") ||
            progressEvent.target?.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength) {
          const progress = Math.round(
            (progressEvent.loaded * 100) / totalLength
          );

          if (toast.isActive(siteId)) {
            toast.update(siteId, {
              render: (
                <div>
                  {trns}: <span>{progress}</span>%
                </div>
              ),
              type: "info"
            });
          } else {
            toast(
              <div>
                {trns}: <span>{progress}</span>%
              </div>,
              {
                toastId: siteId,
                type: "info"
              }
            );
          }
        }
      }
    });
  }

  static deleteImage(id) {
    return $api.delete("/api/site/proxy/image", { params: { id } });
  }

  static getProxySiteById(id) {
    return $api.get("/api/site/proxy", { params: { id } });
  }

  static createProxySiteDraft() {
    return $api.post("/api/site/proxy/createDraft");
  }

  static checkSite(siteId, link, signal) {
    return $api.get("/api/site/proxy/check", {
      params: {
        siteId,
        url: link
      },
      signal
    });
  }

  static getValidateFile(siteId) {
    return $api.get("/api/site/proxy/check/getFile", { params: { siteId } });
  }

  // static createProxySiteNew(params) {
  //   return $api.post("/api/site/proxy/createNew", params);
  // }
  static createProxySiteNew(params) {
    return $api.post("/api/site/proxy/createNewDraft", params);
  }

  static deleteProxySite(id) {
    return $api.delete("/api/site/proxy", { params: { id } });
  }

  static getRentPeriods() {
    return $api.get("/api/admin/rent/get/all");
  }

  static createFeedback(params) {
    return $api.post("/api/front/site/feedback", params);
  }

  static getAllPromocodes(params) {
    return $api.post("/api/front/site/promo/page", params);
  }

  static getAlternatePromocodes() {
    return $api.get("/api/front/site/promo/alternate");
  }

  static getSpecialPromocodes() {
    return $api.get("/api/front/promocode/special");
  }

  static getCabinetPromocodes(params) {
    return $api.get("/api/admin/promocode/user/page", { params });
  }

  static getCabinetPromocodesNew(params) {
    return $api.post("/api/cabinet/promocodes", params);
  }

  static getAllPromocodesBySite(siteId) {
    return $api.get("/api/admin/promocode/site", { params: { siteId } });
  }

  static getAllPromocodesByUser() {
    return $api.get("/api/admin/promocode/user");
  }

  static getPromocodeById(id) {
    return $api.get("/api/front/promocode", { params: { id } });
  }

  static getPromocodeByIdForPersonalAcc(id) {
    return $api.get("/api/front/promocodeedit", { params: { id } });
  }

  static createPromocode(params) {
    return $api.post("/api/admin/promocode", params);
  }

  static updatePromocode(params) {
    return $api.put("/api/front/promocode", params);
  }

  static deletePromocode(id) {
    return $api.delete("/api/admin/promocode", { params: { id } });
  }

  static sendProxyQuery(params) {
    return $api.post("/api/front/site/email", params);
  }

  static confirmEmail(token) {
    return $api.get("/api/front/site/email/confirm", {
      params: {
        token
      }
    });
  }

  static getAllTranslations() {
    return $api.get("/api/translation/all");
  }

  static getCaptchaConfig() {
    return $api.get("/api/front/captcha");
  }

  static getSeoDataDefault() {
    return $api.get("/api/system/properties");
  }

  static getSeoDataHtml() {
    return $api.get("/api/front/property/html");
  }

  static getAllSocials() {
    return $api.get("/api/front/social");
  }

  static getMainBlockList() {
    return $api.get("/api/front/property/main/block/list");
  }

  static getUserIp(lang) {
    return $api.get("/api/front/utils/user/info", {
      params: { lang }
    });
  }

  static getSiteHeader() {
    return $api.get(process.env.REACT_APP_URL);
  }

  static createCountView(articleId, fingerprint) {
    return $api.post("/api/front/site/blog/count-view", {
      articleId,
      fingerprint
    });
  }

  static getFreeProxy(params) {
    return $api.post("/api/front/proxy/free", params);
  }

  static getAllFreeProxy(params) {
    return $api.post("/api/front/proxy/free/all", params);
  }

  static getFaqContent(params) {
    return $api.post("/api/front/faqs", params);
  }

  static getUserChoice(size) {
    return $api.get("/api/front/userChoices", {
      params: { size }
    });
  }

  static subscribeToPromo(params) {
    return $api.post("/api/subscriptions", params);
  }

  static getSities(countryId) {
    return $api.get("/api/front/cities", { params: { countryId } });
  }

  static getAnonimityInfo(params) {
    return $api.post("/api/client/anonymity", params);
  }

  static getAnonimityScore(params) {
    return $api.post("/api/client/anonymity/anonymity/scan", params);
  }

  static getIpTraceroute(target, isShowCountry) {
    return $api.get("/api/client/ip/traceroute", {
      params: { target, isShowCountry }
    });
  }

  static getIpBlacklist(ip) {
    return $api.get("/api/client/check/blacklist", { params: { ip } });
  }

  static portScan(params) {
    return $api.post("/api/client/port/scan", params);
  }
}
