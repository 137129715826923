import styled from "@emotion/styled";

import { Title } from "../../../../../components/ui/Title/Title";

export const StyledRelatedItem = styled.li`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 24px;

    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      margin-bottom: 0;
    }
  }
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  width: 100%;
  padding-bottom: 241px;
  background: rgba(216, 218, 224, 0.5);
  margin-bottom: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 31vw;
    min-width: 31vw;
  }

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    width: 374px;
    min-width: 374px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  a {
    font-size: 0;
  }
`;

export const StyledItemContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledCategory = styled.p`
  display: inline-flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: rgba(216, 218, 224, 0.5);
  color: ${(p) => p.theme.colors["neutral-400"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 12px;
`;

export const StyledItemTitle = styled(Title)`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2 !important;
`;

export const StyledItemDescription = styled.div`
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 12px;
`;

export const StyledItemDate = styled.span`
  display: block;
  color: ${(p) => p.theme.colors["neutral-400"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.67;
  text-transform: uppercase;
  padding-top: 12px;
`;
