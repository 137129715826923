import { combineReducers, configureStore } from "@reduxjs/toolkit";

import BlogInnerSlice from "./reducers/BlogInnerReducer/BlogInner.slice";
import BlogSlice from "./reducers/BlogReducer/Blog.slice";
import CabinetSlice from "./reducers/CabinetReducer/Cabinet.slice";
import ComparisonSlice from "./reducers/ComparisonReducer/Comparison.slice";
import ContentSlice from "./reducers/ContentReducer/Content.slice";
import FAQSlice from "./reducers/FAQReducer/FAQ.slice";
import FreeProxySlice from "./reducers/FreeProxyReducer/FreeProxy.slice";
import PromocodesSlice from "./reducers/PromocodesReducer/Promocodes.slice";
import ProxySiteSlice from "./reducers/ProxySiteReducer/ProxySite.slice";
import SeoBlockSlice from "./reducers/SeoBlockReducer/SeoBlock.slice";
import SiteSlice from "./reducers/SiteReducer/Site.slice";
import ToolsSlice from "./reducers/ToolsReducer/Tools.slice";
import TranslationSlice from "./reducers/TranslationReducer/Translation.slice";
import UserSlice from "./reducers/UserReducer/User.slice";

const rootReducer = combineReducers({
  content: ContentSlice,
  translation: TranslationSlice,
  site: SiteSlice,
  blog: BlogSlice,
  bloginner: BlogInnerSlice,
  proxysite: ProxySiteSlice,
  user: UserSlice,
  cabinet: CabinetSlice,
  comparison: ComparisonSlice,
  promocodes: PromocodesSlice,
  freeproxy: FreeProxySlice,
  seoblock: SeoBlockSlice,
  faq: FAQSlice,
  tools: ToolsSlice
});

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredPaths: ["translation"]
        }
      }),
    preloadedState
  });
