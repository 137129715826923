import styled from "@emotion/styled";

import { FormError } from "../../../../../../../components/forms/FormError/FormError";

export const StyledProxyTypesBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const RestyledFormError = styled(FormError)`
  margin-left: 2px;
`;
