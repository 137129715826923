import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { ApiService } from "../../../../../../../services";
import { getAllContent } from "../../../../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllUser } from "../../../../../../../store/reducers/UserReducer/User.selectors";
import { Icon } from "../../../../../../ui/Icon/Icon";

import {
  StyledFeedBack,
  StyledFeedbackButton,
  StyledFeedbackCount,
  StyledFeedbackDivider
} from "./Feedback.styled";

export const Feedback = ({
  captchaRef,
  setTokenCaptcha,
  review,
  changeHandler
}) => {
  // **Redux state
  const { captchaConfig } = useSelector(getAllContent);
  const { isUserAuthenticated } = useSelector(getAllUser);

  const { t } = useTranslation();

  // **Local state
  const [isDisableButtons, setIsDisableButtons] = useState({
    yes: false,
    no: false
  });
  const { enable, headerName } = captchaConfig.data;

  const changeUsefulness = async (useful) => {
    let header = {};

    if (enable && !isUserAuthenticated) {
      try {
        const { response: token } = await captchaRef.current.execute({
          async: true
        });
        header = { [headerName]: token };
      } catch (ignore) {
        return;
      }
    }

    try {
      const response = await ApiService.changeReviewUsefulness(
        review.id,
        useful,
        header
      );

      if (response && response.status !== 200) {
        throw response;
      }

      changeHandler();

      setIsDisableButtons({
        ...isDisableButtons,
        yes: useful,
        no: !useful
      });
      toast.success(t("notifications.vote"));
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      if (enable && !isUserAuthenticated) {
        captchaRef.current.resetCaptcha();
        setTokenCaptcha(null);
      }
    }
  };

  return (
    <StyledFeedBack>
      <StyledFeedbackButton
        variant={"yes"}
        onClick={() => changeUsefulness(true)}
        disabled={isDisableButtons.yes}
      >
        <Icon name={"fingerUp"} />
      </StyledFeedbackButton>
      {review?.useful > 0 && (
        <StyledFeedbackCount variant={"yes"}>
          {review.useful}
        </StyledFeedbackCount>
      )}
      <StyledFeedbackDivider />
      {review?.unuseful > 0 && (
        <StyledFeedbackCount variant={"no"}>
          {review.unuseful}
        </StyledFeedbackCount>
      )}
      <StyledFeedbackButton
        variant={"no"}
        onClick={() => changeUsefulness(false)}
        disabled={isDisableButtons.no}
        type="button"
      >
        <Icon name={"fingerDown"} />
      </StyledFeedbackButton>
    </StyledFeedBack>
  );
};
