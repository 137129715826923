import styled from "@emotion/styled";

import { Breadcrumbs } from "../../components/common/Breadcrumbs/Breadcrumbs";

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  padding-bottom: 24px !important;
  padding-top: 40px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-top: 64px !important;
  }
`;
