export const portsSelectOptions = (t) => [
    {
      label: t("portScanner.form.popularPorts"),
      value: "POPULAR"
    },
    {
      label: t("portScanner.form.serverPorts"),
      value: "SERVER"
    },
    {
      label: t("portScanner.form.gamePorts"),
      value: "GAME"
    },
    {
      label: t("portScanner.form.applicationPorts"),
      value: "APPLICATION"
    },
    {
      label: t("portScanner.form.p2pPorts"),
      value: "P2P"
    }
  ];
