import { useTranslation } from "react-i18next";

import { useLangUrlDefault } from "../../../../hooks";
import { navigationFooter } from "../../../../utils/constants";

import { NavItem, NavList, StyledLink, StyledNav } from "./Navigation.styled";

export const Navigation = () => {
  const { t } = useTranslation();
  const [, hrefLang] = useLangUrlDefault();

  return (
    <StyledNav>
      <NavList>
        {navigationFooter.map(({ route, translation }) => (
          <NavItem key={route}>
            <StyledLink
              to={`${hrefLang}/${route}/`}
              title={t(`navigation.${translation}`)}
            >
              {t(`navigation.${translation}`)}
            </StyledLink>
          </NavItem>
        ))}
      </NavList>
    </StyledNav>
  );
};
