import PT from "prop-types";

import { Icon } from "../Icon/Icon";

import { StyledRating } from "./Rating.styled";

export const Rating = ({
  rating = 0,
  className,
  size = "md",
  background = true
}) => {
  const ratingNumber = Number(rating);

  return (
  <StyledRating
    className={className}
    rating={rating}
    size={size}
    background={background}
  >
    <Icon name={"starFill"} />
    {isNaN(ratingNumber) ? "0.0" : ratingNumber?.toFixed(1)}
  </StyledRating>
);
};

Rating.propTypes = {
  rating: PT.number,
  className: PT.string,
  size: PT.oneOf(["sm", "md_s", "md", "lg"]),
  background: PT.bool
};
