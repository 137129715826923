import PT from "prop-types";

import { StyledSkeleton } from "../../ui/Skeleton/components/SkeletonTableComponents.styled";
import { Title } from "../../ui/Title/Title";

import { RestyledSubtitle, WrapperStyled } from "./SectionHead.styled";

export const SectionHead = ({
  title,
  tag = "h2",
  description,
  className,
  loading
}) => (
  <WrapperStyled className={className}>
    <Title tag={tag} className={"section-title"}>
      {loading ? <StyledSkeleton /> : title}
    </Title>
    {(description || loading) && (
      <RestyledSubtitle className={"section-desctiption"}>
        {loading ? <StyledSkeleton count={3} /> : description}
      </RestyledSubtitle>
    )}
  </WrapperStyled>
);

SectionHead.propTypes = {
  title: PT.string,
  tag: PT.string,
  description: PT.string,
  className: PT.string
};
