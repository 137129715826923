import { useTranslation } from "react-i18next";

import {
  animationWebRTCLeakFix,
  animationWebRTCLeakMain
} from "../../../assets/animations";
import {
  fingerprintFaqImage,
  fingerprintFaqImage2x,
  webRTCLeakTestHeroImageMobile,
  webRTCLeakTestHeroImageMobile2x
} from "../../../assets/img";
import { SeoSection } from "../../../components/common/SeoSection/SeoSection";
import { Rive } from "../../../components/ui/Rive/Rive";
import { useSeoBlock } from "../../../hooks";
import { $api } from "../../../services/AxiosInstance";
import { AllActions } from "../../../store/reducers/AllActions";
import { parseSeoData } from "../../../utils/helpers";

import { WebRTCLeakTest } from "./WebRTCLeakTest/WebRTCLeakTest";
import { WebRTCLeakTestInfo } from "./WebRTCLeakTestInfo/WebRTCLeakTestInfo";
import {
  RestuledHowToFix,
  RestyledFAQ,
  RestyledMainSection
} from "./WebRTCLeakTestPage.styled";

const WebRTCLeakTestPage = () => {
  const { t } = useTranslation();
  const { data } = useSeoBlock(["toolsWebRTCHowToFix"]);
  const steps = parseSeoData(data?.toolsWebRTCHowToFix?.steps, "JSON") || [];
  return (
    <>
      <RestyledMainSection
        button={false}
        images={{
          mobile1x: webRTCLeakTestHeroImageMobile,
          mobile2x: webRTCLeakTestHeroImageMobile2x
        }}
        animation={
          <Rive
            src={animationWebRTCLeakMain}
            autoPlay
            top={-42}
            right={-71}
            bottom={-113}
            left={-71}
          />
        }
      />
      <WebRTCLeakTest />
      <WebRTCLeakTestInfo />
      {data?.toolsWebRTCHowToFix?.showOnFront && (
        <RestuledHowToFix
          imageMobile="img/webRTC-leak-test/webRTC-leak-fix-image-mobile.svg"
          imageDesktop="img/webRTC-leak-test/webRTC-leak-fix-image.svg"
          title={parseSeoData(data?.toolsWebRTCHowToFix?.title)}
          data={steps}
          animation={
            <Rive
              src={animationWebRTCLeakFix}
              autoPlay
              top={-58}
              right={-70}
              bottom={-58}
              left={-70}
            />
          }
        />
      )}
      <RestyledFAQ
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        tools
      />
      <SeoSection />
    </>
  );
};

export default WebRTCLeakTestPage;

WebRTCLeakTestPage.getServerSideState = async (store, params) => {
  const { data } = await $api.post("/api/front/faqs", {
    pageTag: params.tag,
    languageCode: params.locale,
    params: params.params
  });
  const fetchSeoBlockParams = {
    languageCode: params.locale,
    keys: [
      "toolsWebRTCWhatIsIt",
      "toolsWebRTCWhatDoesIt",
      "toolsWebRTCHowToFix"
    ]
  };
  const { data: seoBlock } = await $api.post(
    "/api/front/seoBlock",
    fetchSeoBlockParams
  );
  if (seoBlock) {
    store.dispatch(
      AllActions.setSeoBlock({ data: seoBlock, params: fetchSeoBlockParams })
    );
  }
  if (data) {
    store.dispatch(
      AllActions.setFaqContent({
        data,
        pageTag: params.tag,
        languageCode: params.locale
      })
    );
  }
};
