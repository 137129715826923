import { useTranslation } from "react-i18next";

import {
  animationDnsLeakFix,
  animationDnsLeakMain
} from "../../../assets/animations";
import {
  dnsLeakTestHeroImageMobile,
  fingerprintFaqImage,
  fingerprintFaqImage2x
} from "../../../assets/img";
import { SeoSection } from "../../../components/common/SeoSection/SeoSection";
import { Rive } from "../../../components/ui/Rive/Rive";
import { useSeoBlock } from "../../../hooks";
import { $api } from "../../../services/AxiosInstance";
import { AllActions } from "../../../store/reducers/AllActions";
import { parseSeoData } from "../../../utils/helpers";

import { DnsLeakTest } from "./DnsLeakTest/DnsLeakTest";
import { DnsLeakTestInfo } from "./DnsLeakTestInfo/DnsLeakTestInfo";
import {
  RestuledHowToFix,
  RestyledFAQ,
  RestyledMainSection
} from "./DnsLeakTestPage.styled";

const DnsLeakTestPage = () => {
  const { t } = useTranslation();
  const { data } = useSeoBlock(["toolsDnsHowToFix"]);

  const stepsData = parseSeoData(data?.toolsDnsHowToFix?.steps, "JSON") || [];
  return (
    <>
      <RestyledMainSection
        button={false}
        images={{
          mobile1x: dnsLeakTestHeroImageMobile,
          mobile2x: dnsLeakTestHeroImageMobile
        }}
        animation={
          <Rive
            src={animationDnsLeakMain}
            autoPlay
            top={-128}
            right={-47}
            bottom={-123}
            left={-47}
          />
        }
      />
      <DnsLeakTest />
      <DnsLeakTestInfo />
      {data?.toolsDnsHowToFix?.showOnFront && (
        <RestuledHowToFix
          title={parseSeoData(data?.toolsDnsHowToFix?.title)}
          imageDesktop="img/dns-leak-test/dns-leak-fix-image.svg"
          imageMobile="img/dns-leak-test/dns-leak-fix-image-mobile.svg"
          data={stepsData}
          animation={
            <Rive
              src={animationDnsLeakFix}
              autoPlay
              top={-53}
              right={-70}
              bottom={-53}
              left={-37}
            />
          }
        />
      )}
      <RestyledFAQ
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        tools
      />
      <SeoSection />
    </>
  );
};

export default DnsLeakTestPage;

DnsLeakTestPage.getServerSideState = async (store, params) => {
  const { data } = await $api.post("/api/front/faqs", {
    pageTag: params.tag,
    languageCode: params.locale,
    params: params.params
  });

  const fetchSeoBlockParams = {
    languageCode: params.locale,
    keys: ["toolsDnsHowToFix", "toolsDnsWhatIsDns", "toolsDnsWhatDoesDns"]
  };
  const { data: seoBlock } = await $api.post(
    "/api/front/seoBlock",
    fetchSeoBlockParams
  );
  store.dispatch(
    AllActions.setSeoBlock({ data: seoBlock, params: fetchSeoBlockParams })
  );

  if (data) {
    store.dispatch(
      AllActions.setFaqContent({
        data,
        pageTag: params.tag,
        languageCode: params.locale
      })
    );
  }
};
