import { StyledSkeleton } from "../../../../../components/ui/Skeleton/components/SkeletonTableComponents.styled";
import { InfoItemContainer, InfoItemTitle } from "../Info.styled";

import { StyledSocialItem, StyledSocialList } from "./Social.styled";
import { SocialLink } from "./SocialLink";

export const Socials = ({ title, active, data, isMedia = false }) => (
  <InfoItemContainer active={active}>
    <InfoItemTitle>{title}</InfoItemTitle>
    <StyledSocialList isMedia={isMedia}>
      {data.map((item, index) => (
        <StyledSocialItem key={`keySM${item?.code}${index}`}>
          <SocialLink
            code={item?.code}
            link={item?.link}
            name={
              isMedia && item?.name?.length
                ? item?.name?.toLowerCase()
                : item?.link
            }
            isMedia={isMedia}
          />
        </StyledSocialItem>
      ))}
    </StyledSocialList>
  </InfoItemContainer>
);

Socials.Skeleton = ({ active, isMedia = false }) => (
  <InfoItemContainer active={active}>
    <InfoItemTitle>
      <StyledSkeleton width={120} height={20} />
    </InfoItemTitle>
    <StyledSocialList isMedia={isMedia}>
      {[...Array(isMedia ? 6 : 2)].map((_, index) => (
        <StyledSocialItem key={index}>
          <StyledSkeleton width={20} height={20} />
          <StyledSkeleton width={70} style={{ marginLeft: "8px" }} />
        </StyledSocialItem>
      ))}
    </StyledSocialList>
  </InfoItemContainer>
);
