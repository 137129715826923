import PT from "prop-types";
import { useSelector } from "react-redux";

import { Pagination } from "../../../../components/ui/Pagination/Pagination";
import { getAllBlog } from "../../../../store/reducers/BlogReducer/Blog.selectors";
import {
  BlogDarkBackground,
  BlogListWrapper,
  StyledBlogList,
  StyledBlogListEmpty
} from "../Blog.styled";
import { BlogItem } from "../BlogItem/BlogItem";
import { BlogTabs } from "../BlogTabs/BlogTabs";

export const BlogList = ({ emptyText, pageParams, pageChangeHandler }) => {
  // **Redux state
  const { content } = useSelector(getAllBlog);

  return (
    <BlogDarkBackground>
      <BlogTabs pageChangeHandler={pageChangeHandler} />
      <StyledBlogList>
        <BlogListWrapper>
          {content.data?.content.map((item) => (
            <BlogItem key={item.id} data={item} />
          ))}
        </BlogListWrapper>
        {content.data.totalElements > 0 && (
          <Pagination
            className="blog-pagination"
            currentPage={pageParams.page + 1}
            rowsPerPage={pageParams.size}
            rowCount={content.data.totalElements}
            onChangePage={pageChangeHandler}
          />
        )}
        {!content.isLoading && content.data.totalElements === 0 && (
          <StyledBlogListEmpty>{emptyText}</StyledBlogListEmpty>
        )}
      </StyledBlogList>
    </BlogDarkBackground>
  );
};

BlogList.propTypes = {
  emptyText: PT.string.isRequired,
  category: PT.oneOfType([PT.bool, PT.string]).isRequired
};
