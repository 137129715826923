export const FAVICON_LINKS = [
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: "/favicons/apple-touch-icon.png"
  },
  {
    rel: "icon",
    type: "image/x-icon",
    href: "/favicons/favicon.ico"
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: "/favicons/favicon-32x32.png"
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: "/favicons/favicon-16x16.png"
  },
  {
    rel: "manifest",
    href: "/site.webmanifest"
  },
  {
    rel: "mask-icon",
    type: "image/svg+xml",
    href: "/favicons/safari-pinned-tab.svg",
    color: "#2A282F"
  }
];
