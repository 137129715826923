import styled from "@emotion/styled";

import { Icon } from "../Icon/Icon";

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    & span {
      color: ${(props) =>
        props.variant === "light"
          ? "white"
          : props.theme.colors["neutral-800"]};
      transition: all 0.3s ease;

      &::after {
        width: 100%;
        transition: all 0.3s ease;
      }
    }
  }
`;

export const StyledReadMore = styled.span`
  color: ${(props) =>
    props.variant === "light" ? "white" : props.theme.colors["neutral-500"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(props) => (props.variant === "light" ? "14px" : "16px")};
  font-style: normal;
  font-weight: 400;
  line-height: ${(props) => (props.variant === "light" ? "1.71" : "1.5")};
  margin-right: 8px;
  transition: all 0.3s ease;

  &::after {
    content: "";
    position: relative;
    top: -1px;
    width: 0px;
    height: 1px;
    display: block;
    background: ${(props) =>
      props.variant === "light" ? "white" : props.theme.colors["neutral-500"]};
    transition: all 0.3s ease;
  }
`;

export const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  color: ${(props) =>
    props.variant === "light" ? "white" : props.theme.colors["neutral-800"]};
`;
