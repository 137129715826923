import { AnonimityTime } from "../../pages/ToolsPages/MyAnonymityPage/IpDetails/AnonimityTime";
import {
  Flag,
  Point
} from "../../pages/ToolsPages/MyAnonymityPage/IpDetails/IpDetails.styled";

const preparelanguage = (language, t) => {
  if (!language) return null;
  const languagesArray = language.split(",");
  const languagesTemp = languagesArray.map((lang) => {
    let langCode = lang;
    if (lang.includes("-")) {
      const [, upper] = lang.split("-");
      langCode = upper;
    }
    if (lang.includes("_")) {
      const [, upper] = lang.split("_");
      langCode = upper;
    }
    return langCode.toLowerCase();
  });
  const languages = languagesTemp
    .filter((lang) => lang.length === 2)
    .filter((lang, index, self) => self.indexOf(lang) === index);
  if (languages.length === 0) return null;

  return {
    id: "language",
    title: t("myAnonymity.details.language"),
    image: "img/ui/language.svg",
    listData: languages.map((lang) => ({
      value: (
        <>
          <Flag countryCode={lang} />
          {lang.toUpperCase()}
        </>
      )
    }))
  };
};

const prepareOpenPorts = (openPorts) => {
  if (!openPorts) return null;
  const keys = Object.keys(openPorts);
  const preperedOpenPorts = keys.reduce((acc, key) => {
    if (openPorts[key] && openPorts[key].length) {
      return [...acc, `${key} - ${openPorts[key].join(", ")}`];
    }
    return acc;
  }, []);
  if (!preperedOpenPorts.length) return null;
  return prepareOpenPorts;
};

const renderEnabled = (value, t) =>
  value ? (
    <>
      <Point color="green" /> {t("myAnonymity.details.enabled")}
    </>
  ) : (
    <>
      <Point color="red" /> {t("myAnonymity.details.disabled")}
    </>
  );
export const getIpDetailsData = (anonymity, t) =>
  [
    {
      id: "location",
      title: t("myAnonymity.details.location"),
      image: "img/ui/location.svg",
      listData: [
        {
          key: "Country",
          value: (
            <>
              <Flag countryCode={anonymity?.countryCode} />
              {anonymity?.country}
            </>
          )
        },
        {
          key: "Region",
          value: anonymity?.region
        },
        {
          key: "City",
          value: anonymity?.city
        },
        {
          key: "ZIP",
          value: anonymity?.zipcode
        },
        {
          key: "Hostname",
          value: anonymity?.host
        },
        {
          key: "Reversed",
          value: anonymity?.reversed
        },
        {
          key: "IP range",
          value: anonymity?.range
        },
        {
          key: "ISP",
          value: anonymity?.isp
        },
        {
          key: "Organization",
          value: anonymity?.organization
        },
        {
          key: "AS Organization",
          value: anonymity?.asnOrganization
        },
        {
          key: "AS Number",
          value: anonymity?.asnNumber
        }
      ]
    },
    preparelanguage(anonymity?.language, t),
    {
      id: "browser",
      title: t("myAnonymity.details.browser"),
      image: "img/ui/browser.svg",
      listData: [
        { key: "Headers", value: anonymity?.userAgent },
        { key: "JavaScript", value: anonymity?.userAgent }
      ]
    },
    {
      id: "time",
      title: "Time",
      image: "img/ui/time.svg",
      listData: [
        { key: "Zone", value: anonymity?.timeZone },
        {
          key: "Local",
          value: (
            <AnonimityTime
              suppressHydrationWarning={true}
              time={anonymity?.systemTime}
            />
          )
        },
        {
          key: "System",
          value: (
            <AnonimityTime
              suppressHydrationWarning={true}
              time={anonymity?.time}
            />
          )
        }
      ]
    },
    // {
    //   id: "social Media",
    //   title: t("myAnonymity.details.socialMedia"),
    //   image: "img/ui/language.svg",
    //   listData: [
    //     // {
    //     //   value: (
    //     //     <>
    //     //       <SocialIcon src="img/icons/gmail.svg" alt="gmail" />
    //     //       Gmail
    //     //     </>
    //     //   )
    //     // },
    //     // {
    //     //   value: (
    //     //     <>
    //     //       <SocialIcon src="img/icons/facebook.svg" alt="facebook" />
    //     //       Facebook
    //     //     </>
    //     //   )
    //     // }
    //   ]
    // },
    {
      id: "interactive detection",
      title: t("myAnonymity.details.interactiveDetection"),
      image: "img/ui/interactive-detection.svg",
      listData: [
        {
          key: "Ip address",
          value: anonymity?.ip
        },
        { key: "Flash", value: anonymity?.flash },
        { key: "WebRTC", value: anonymity?.webRtc },
        { key: "Java (TCP)", value: anonymity?.java },
        { key: "Java (UDP)", value: anonymity?.java },
        { key: "Java (system)", value: anonymity?.java },
        { keyTitle: "DNS" },
        {
          key: "Browser",
          value: anonymity?.browser
        },
        { key: "Flash", value: anonymity?.flash },
        { key: "Java (request)", value: anonymity?.java },
        { key: "Java (system)", value: anonymity?.java },
        { keyTitle: "OS" },
        { key: "Headers", value: anonymity?.userAgent },
        { key: "javaScript", value: anonymity?.js },
        { key: "Java (request)", value: anonymity?.java },
        { key: "Java (system)", value: anonymity?.java },
        { keyTitle: "Ports" },
        {
          key: "Open ports",
          value: prepareOpenPorts(anonymity?.openPorts)
        }
      ]
    },
    {
      id: "navigator",
      title: t("myAnonymity.details.navigator"),
      image: "img/ui/navigator.svg",
      listData: [
        { key: "productSub", value: anonymity?.productSub },
        { key: "vendor", value: anonymity?.vendor },
        { key: "maxTouchPoints", value: anonymity?.maxTouchPoints },
        { key: "pdfViewerEnabled", value: anonymity?.pdfViewerEnabled },
        { key: "hardwareConcurrency", value: anonymity?.maxTouchPoints },
        { key: "cookieEnabled", value: anonymity?.cookie },
        { key: "appCodeName", value: anonymity?.app },
        { key: "appName", value: anonymity?.appType },
        { key: "appVersion", value: anonymity?.appMajorVersion },
        { key: "platform", value: anonymity?.platform },
        { key: "userAgent", value: anonymity?.userAgent },
        { key: "language", value: anonymity?.language },
        { key: "onLine", value: anonymity?.onLine },
        { key: "webdriver", value: anonymity?.webdriver },
        { key: "deviceMemory", value: anonymity?.deviceMemory }
      ]
    },
    {
      id: "screen",
      title: t("myAnonymity.details.screen"),
      image: "img/ui/screen.svg",
      listData: [
        { key: "colorDepth", value: anonymity?.color },
        { key: "pixelDepth", value: anonymity?.color },
        {
          key: "height",
          value: anonymity?.screen ? anonymity?.screen.split("x")[0] : null
        },
        {
          key: "width",
          value: anonymity?.screen ? anonymity?.screen.split("x")[1] : null
        },
        { key: "availHeight", value: anonymity?.availHeight },
        { key: "availWidth", value: anonymity?.availWidth },
        { key: "top", value: anonymity?.top },
        { key: "left", value: anonymity?.left },
        { key: "availTop", value: anonymity?.availTop },
        { key: "availLeft", value: anonymity?.availLeft },
        { key: "window size", value: anonymity?.screen }
      ]
    },
    {
      id: "scripts",
      title: t("myAnonymity.details.scripts"),
      image: "img/ui/scripts.svg",
      listData: [
        {
          key: "JavaScript",
          value: renderEnabled(anonymity?.javaScript, t)
        },
        {
          key: "Flash",
          value: renderEnabled(anonymity?.flash, t)
        },
        {
          key: "Java",
          value: renderEnabled(anonymity?.java, t)
        },
        {
          key: "ActiveX",
          value: renderEnabled(anonymity?.activeX, t)
        },
        {
          key: "WebRTC",
          value: renderEnabled(anonymity?.webRtc, t)
        },
        {
          key: "VBScript",
          value: renderEnabled(anonymity?.vbsScript, t)
        },
        {
          key: "AdBlock",
          value: renderEnabled(anonymity?.adBlock, t)
        }
      ]
    }
    // {
    //   id: "plugins",
    //   title: t("myAnonymity.details.plugins"),
    //   image: "img/ui/plugins.svg",
    //   listData: [
    //     { key: "PDF Viewer", value: "internal-pdf-viewer" },
    //     { key: "Chrome PDF Viewer", value: "internal-pdf-viewer" },
    //     { key: "Chromium PDF Viewer", value: "internal-pdf-viewer" },
    //     { key: "Microsoft Edge PDF Viewer", value: "internal-pdf-viewer" },
    //     { key: "WebKit built-in PDF", value: "internal-pdf-viewer" }
    //   ]
    // }
  ].filter((item) => item);
