import { useEffect, useId, useRef, useState } from "react";

import PT from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { StyledTooltip } from "../../../../components/ui/Tooltip/Tooltip.styled";
import { useLangUrlDefault } from "../../../../hooks";
import { getAllUser } from "../../../../store/reducers/UserReducer/User.selectors";
import { Icon } from "../../../ui/Icon/Icon";
import { StyledSkeleton } from "../../../ui/Skeleton/components/SkeletonTableComponents.styled";
import { Tooltip } from "../../../ui/Tooltip/Tooltip";
import { SubscribePromoModal } from "../../Modals/SubscribePromoModal/SubscribePromoModal";

import {
  StyledPromoButton,
  StyledPromoButtonsBox,
  StyledPromoCode,
  StyledPromoCodeBox,
  StyledPromoCopy
} from "./PromoButtons.styled";

export const PromoButtons = ({
  promocode,
  variant = "dark",
  siteId,
  className,
  loading
}) => {
  const { t } = useTranslation();
  const customToastId = useId();
  const promoTextId = useId();
  const promoCopyId = useId();
  const ref = useRef(null);
  const refBox = useRef(null);
  const [queryLang] = useLangUrlDefault();

  const { isUserAuthenticated } = useSelector(getAllUser);

  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [needTooltip, setNeedTooltip] = useState(false);
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (ref.current && refBox.current) {
      setNeedTooltip(ref.current.scrollWidth > refBox.current.clientWidth - 64);
    }
  }, [ref, show]);

  const showCode = () => {
    setShow(!show);
  };

  const triggerClose = () => setOpen((prev) => !prev);

  const onCopyHandler = () => {
    // event.stopPropagation();
    toast.success(t("notifications.copy"), {
      toastId: customToastId
    });
  };

  useEffect(() => {
    if (isUserAuthenticated) {
      setIsAuth(true);
    }
  }, [isUserAuthenticated]);

  return (
    <StyledPromoButtonsBox className={className} variant={variant}>
      {!loading ? (
        <>
          {!show && (
            <StyledPromoButton
              variant={variant}
              lang={queryLang}
              onClick={showCode}
            >
              {t("promocodes.showCode")}
              <Icon name={"ticket"} />
            </StyledPromoButton>
          )}
          {show && (
            <StyledPromoCodeBox variant={variant} ref={refBox}>
              <StyledPromoCode data-tooltip-id={promoTextId} ref={ref}>
                {promocode}
              </StyledPromoCode>
              {needTooltip ? (
                <StyledTooltip id={promoTextId} place={"top"}>
                  {promocode}
                </StyledTooltip>
              ) : null}
              <CopyToClipboard text={promocode} onCopy={onCopyHandler}>
                <StyledPromoCopy data-tooltip-id={promoCopyId} type="button">
                  <Icon name="copy" />
                </StyledPromoCopy>
              </CopyToClipboard>
              <StyledTooltip id={promoCopyId} place={"top"}>
                {t("promocodes.copy")}
              </StyledTooltip>
            </StyledPromoCodeBox>
          )}
          {variant !== "light" && isAuth ? (
            <Tooltip body={t("promocodes.subscribe")} place={"top-start"}>
              <StyledPromoButton
                icon
                variant={variant}
                onClick={triggerClose}
                type="button"
              >
                <Icon name={"mail"} />
              </StyledPromoButton>
            </Tooltip>
          ) : null}
          <SubscribePromoModal
            open={open}
            onClose={triggerClose}
            siteId={siteId}
          />
        </>
      ) : (
        <StyledSkeleton height={40} />
      )}
    </StyledPromoButtonsBox>
  );
};

PromoButtons.propTypes = {
  promocode: PT.string,
  className: PT.string,
  variant: PT.oneOf(["dark", "gray", "light", "table"])
};
