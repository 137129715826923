import * as yup from "yup";

import { REGEX_IP } from "../constants";

export const IpBlacklistCheckSchema = (trns) =>
  yup.object().shape({
    ipAddress: yup
      .string()
      .required(trns.form.rules.required)
      .matches(REGEX_IP, trns.form.rules.ip)
  });
