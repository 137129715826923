import FeedbackPage from "../pages/AuthPages/FeedbackPage/FeedbackPage";
import FeedbackSuccess from "../pages/AuthPages/FeedbackSuccess/FeedbackSuccess";
import LoginPage from "../pages/AuthPages/LoginPage/LoginPage";
import RecoveryPassPage from "../pages/AuthPages/RecoveryPassPage/RecoveryPassPage";
import RecoverySendPage from "../pages/AuthPages/RecoverySendPage/RecoverySendPage";
import RegistrationPage from "../pages/AuthPages/RegistrationPage/RegistrationPage";
import RegistrationSuccess from "../pages/AuthPages/RegistrationSuccess/RegistrationSuccess";

export const AuthRoutes = (ssr) => [
  {
    path: "/:lang?/login",
    tag: "login",
    type: "auth",
    component: ssr
      ? LoginPage
      : () => import("../pages/AuthPages/LoginPage/LoginPage"),
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/registration",
    tag: "registration",
    type: "auth",
    component: ssr
      ? RegistrationPage
      : () => import("../pages/AuthPages/RegistrationPage/RegistrationPage"),
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/registration/success",
    tag: "registration",
    type: "auth",
    component: ssr
      ? RegistrationSuccess
      : () =>
          import("../pages/AuthPages/RegistrationSuccess/RegistrationSuccess"),
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/recovery/",
    tag: "recovery",
    type: "auth",
    component: ssr
      ? RecoverySendPage
      : () => import("../pages/AuthPages/RecoverySendPage/RecoverySendPage"),
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/recovery/:token",
    tag: "recovery",
    type: "auth",
    component: ssr
      ? RecoveryPassPage
      : () => import("../pages/AuthPages/RecoveryPassPage/RecoveryPassPage"),
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/feedback",
    tag: "feedback",
    type: "auth",
    component: ssr
      ? FeedbackPage
      : () => import("../pages/AuthPages/FeedbackPage/FeedbackPage"),
    isAuthRequired: false,
    isSsr: false
  },
  {
    path: "/:lang?/feedback/success",
    tag: "feedback",
    type: "auth",
    component: ssr
      ? FeedbackSuccess
      : () => import("../pages/AuthPages/FeedbackSuccess/FeedbackSuccess"),
    isAuthRequired: false,
    isSsr: false
  }
];
