import { useSelector } from "react-redux";

import { getAllBlogInner } from "../../../store/reducers/BlogInnerReducer/BlogInner.selectors";

import { Content } from "./Content/Content";
import { Related } from "./Related/Related";

export const BlogInner = () => {
  // **Redux state
  const { content } = useSelector(getAllBlogInner);

  return (
    <section>
      <Content />
      {content?.data?.relatedArticles?.length > 0 && (
        <Related data={content?.data?.relatedArticles} />
      )}
    </section>
  );
};
