import styled from "@emotion/styled";

import { FLAGS_POSITION } from "./FlagsPosition.constant";

export const StyledFlag = styled.span`
  display: inline-block;
  background-image: url(/img/flags-sprite.png);
  width: 22px;
  height: 16px;
  flex: none;
  background-position: ${({ countryCode }) =>
    FLAGS_POSITION?.[countryCode]
      ? `${FLAGS_POSITION[countryCode].x}px ${FLAGS_POSITION[countryCode].y}px`
      : "0 0"};
`;

export const NoFlag = styled.img`
  display: inline-block;
  width: 22px;
  height: 16px;
  flex: none;
`;
