import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { SectionHead } from "../../../../../components/common/SectionHead/SectionHead";
import { useSeoBlock } from "../../../../../hooks";
import { parseSeoData } from "../../../../../utils/helpers";

export const ProxyContentTop = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { data } = useSeoBlock(["mainPageProxyTable"]);
  const activeTab = searchParams.get("fpt") || "IPv4";
  return (
    <SectionHead
      title={parseSeoData(data?.mainPageProxyTable?.title, "TEXT")?.replace(
        "{{proxyType}}",
        t(`proxyTypesGen.${activeTab}`)
      )}
      description={data?.mainPageProxyTable?.description?.content}
    />
  );
};
