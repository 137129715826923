import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";

export const ProxyContainer = styled(Container)`
  margin-top: 64px !important;
  padding-bottom: 48px !important;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-top: 120px !important;
    padding-bottom: 64px !important;
  }
`;

export const ProxyContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProxyCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 12px;
  background-color: ${(p) => p.theme.colors.white};

  box-shadow: ${(p) => p.theme.shadows.card};
  padding: 16px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-right: 12px;
    padding: 24px;
    width: calc(100% - 300px) !important;
  }
`;

export const ProxyViewMoreBox = styled.div`
  padding: 24px 16px;
  border: ${(props) => props.theme.borders.border};
  border-top: 0;
  border-radius: 0 0 12px 12px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-top: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
