import { useId } from "react";

import { useTranslation } from "react-i18next";

import {
  TableCol,
  TableRow,
  TableText
} from "../../../../../components/common/Table/ToolTable.styled";
import { Tooltip } from "../../../../../components/ui/Tooltip/Tooltip";

import { StyledStatus, StyledStatusWrapper } from "./ResultTable.styled";

export const ResultTableRow = ({ ip, type, status }) => {
  const { t } = useTranslation();
  const customTooltipId = useId();
  return (
    <TableRow
      height="64px"
      borderBottom="none"
      hoverBackground="none"
      cursor="default"
    >
      <TableCol width="33%" padding="12px 0 12px 24px">
        <TableText>{ip}</TableText>
      </TableCol>
      <TableCol width="33%" padding="12px 0 12px 24px">
        <TableText width="auto">{type || "Public IPv4"}</TableText>
      </TableCol>
      <TableCol width="33%" padding="12px 0 12px 24px">
        <StyledStatusWrapper>
          <StyledStatus statue={status ? "error" : "success"}>
            {status
              ? t("webRTCLeakTest.table.leak")
              : t("webRTCLeakTest.table.noLeak")}
          </StyledStatus>
          <Tooltip
            body={
              status
                ? t("webRTCLeakTest.table.leakTooltip")
                : t("webRTCLeakTest.table.noLeakTooltip")
            }
            maxWidth={180}
            noArrow
            id={customTooltipId}
            place="top"
          >
            <img src="img/icons/information.svg" alt="" />
          </Tooltip>
        </StyledStatusWrapper>
      </TableCol>
    </TableRow>
  );
};
