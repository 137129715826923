/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  StyledTable,
  TableBody,
  TableCol,
  TableHeadCol,
  TableHeadRow,
  TableRow,
  TableText,
  TableWrapper
} from "../../../../../components/common/Table/ToolTable.styled";
import { StyledLoading } from "../../../../../components/ui/Button/Button.styled";
import { Percent } from "../../../../../components/ui/Percent/Percent";
import { Tooltip } from "../../../../../components/ui/Tooltip/Tooltip";
import { handleScrollBottomTable } from "../../../../../utils/helpers/resultToolTable.helper";

import {
  InfoIcon,
  StyledTableWrapper,
  StyledValue,
  StyledValueWrapper,
  StyledAttribute
} from "./AttributesTable.styled";

export const AttributesTable = ({
  isLoading,
  data,
  margin = "0",
  searchValue,
  gradient
}) => {
  const [tableData, setTableData] = useState(data);
  const [scrollBottomTable, setScrollBottomTable] = useState(false);
  const { t } = useTranslation();

  const renderValue = (value) => {
    if (isLoading) {
      return <StyledLoading name="loading" />;
    } else if (value === true) {
      return <img src="img/icons/check-circle.svg" />;
    } else if (value === false) {
      return <img src="img/icons/dell-circle.svg" />;
    } else if (value === "") {
      return t("fingerprint.table.noValue");
    } else if (value && typeof value === "object") return (
        <StyledValueWrapper>
          {value?.map((item, i) => {
            if (i < 3) {
              return (
                <StyledValue key={item}>
                  <TableText>{item}</TableText>
                </StyledValue>
              );
            }
          })}
          {value?.length > 4 ? (
            <StyledValue key="others">
              <TableText>
                {t("fingerprint.table.and")} {value?.length - 3}{" "}
                {t("fingerprint.table.others")}
              </TableText>
            </StyledValue>
          ) : null}
        </StyledValueWrapper>
      );
    else return value;
  };

  useEffect(() => {
    const sanitizedSearchValue = searchValue?.trim().toLowerCase();

    if (sanitizedSearchValue) {
      const probableAttributes = data.filter((item) =>
        item.attribute?.toLowerCase().startsWith(sanitizedSearchValue)
      );
      const searchedAttributes = data.filter((item) =>
        item.attribute?.toLowerCase().includes(sanitizedSearchValue)
      );
      setTableData([
        ...new Set([].concat(probableAttributes, searchedAttributes))
      ]);
    } else {
      setTableData(data);
    }
  }, [data, searchValue]);

  return (
    <StyledTableWrapper scrollBottom={!gradient || scrollBottomTable}>
      <StyledTable margin={margin}>
        <TableWrapper>
          <TableHeadRow>
            <TableHeadCol width="64px" justifyContent="center">
              {"№"}
            </TableHeadCol>
            <TableHeadCol width="265px">
              {t("fingerprint.table.attribute")}
            </TableHeadCol>
            <TableHeadCol width="180px" padding="20px 20px 20px 0">
              {t("fingerprint.table.similarityRatio")}
            </TableHeadCol>
            <TableHeadCol minWidth="600px">
              {t("fingerprint.table.value")}
            </TableHeadCol>
          </TableHeadRow>
          <TableBody
            maxHeight="706px"
            onScroll={(e) => handleScrollBottomTable(e, setScrollBottomTable)}
          >
            {tableData?.map((item, index) => (
              <TableRow key={index} height="64px">
                <TableCol width="64px">
                  <TableText>
                    {index < 9 ? (
                      <TableText justifyContent="center">{`0${
                        index + 1
                      }.`}</TableText>
                    ) : (
                      <TableText justifyContent="center">{`${
                        index + 1
                      }.`}</TableText>
                    )}
                  </TableText>
                </TableCol>
                <TableCol width="265px" padding="12px 12px 12px 0">
                  <TableText display="block">
                    <StyledAttribute>{item.attribute}</StyledAttribute>
                    <Tooltip
                      body={item.tooltip}
                      place="top"
                      style={{ display: "inline-block" }}
                    >
                      <InfoIcon src="img/icons/info.svg" alt="Info" />
                    </Tooltip>
                  </TableText>
                </TableCol>
                <TableCol width="180px">
                  <TableText>
                    {isNaN(Number(item?.similarityRatio)) ? (
                      item.similarityRatio
                    ) : (
                      <Percent
                        number={item.similarityRatio}
                        color={
                          Number(item?.similarityRatio) >= 50
                            ? "success"
                            : Number(item?.similarityRatio) >= 10
                            ? "warning"
                            : "error"
                        }
                      />
                    )}
                  </TableText>
                </TableCol>
                <TableCol minWidth="600px">
                  <TableText>{renderValue(item?.value)}</TableText>
                </TableCol>
              </TableRow>
            ))}
          </TableBody>
        </TableWrapper>
      </StyledTable>
    </StyledTableWrapper>
  );
};
