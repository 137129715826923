import { StyledSkeleton } from "../../../../../../components/ui/Skeleton/components/SkeletonTableComponents.styled";
import { useLangUrlDefault } from "../../../../../../hooks";

import {
  StyledProxyTypes,
  StyledProxyTypesItem,
  StyledProxyTypesList
} from "./ProxyTypes.styled";

export const ProxyTypes = ({ data, loading }) => {
  const [queryLang] = useLangUrlDefault();

  if (!loading && !data?.length) {
    return null;
  }

  const currentProxy = data
    ?.map((item) => item?.proxyTypeLocalization?.[queryLang])
    .filter(Boolean);

  const currentData = loading
    ? Array.from({ length: 6 }, (_, i) => i)
    : currentProxy;

  return (
    <StyledProxyTypes>
      <StyledProxyTypesList>
        {currentData?.map((item) => (
          <StyledProxyTypesItem key={`key-alternative-${item}`}>
            {!loading ? item : <StyledSkeleton width={100} height={14} />}
          </StyledProxyTypesItem>
        ))}
      </StyledProxyTypesList>
    </StyledProxyTypes>
  );
};
