export const setMobileMenuReducer = (state, action) => {
  state.isMobileMenuOpened = action.payload;
};

export const setBurgerMenuReducer = (state, action) => {
  state.isBurgerMenuOpened = action.payload;
};

export const setServiceMenuReducer = (state, action) => {
  state.isServiceMenuOpened = action.payload;
};

export const setPreviousLangReducer = (state, action) => {
  state.previousLang = action.payload;
};

export const setServicesOpenedReducer = (state, action) => {
  state.isServicesOpened = action.payload;
};

export const setLocationOpenedReducer = (state, action) => {
  state.isLocationOpened = action.payload;
};

export const setSelectedLocationReducer = (state, action) => {
  state.selectedLocation = action.payload;
};

export const setServicesMenuStepReducer = (state, action) => {
  state.servicesMenuStep = action.payload;
};

export const setSearchCountryReducer = (state, action) => {
  state.searchCountry = action.payload;
};
