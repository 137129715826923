import { useTranslation } from "react-i18next";

import { Pagination } from "../../ui/Pagination/Pagination";

import {
  StyledPaginationBox,
  StyledShowMoreButton
} from "./RenderReviews.styled";
import { Reviews } from "./Reviews/Reviews";

export const RenderReviews = ({
  siteTitle,
  reviews,
  totalElements,
  pageSize,
  currentPage,
  changeHandler,
  onChangePage,
  handlerShowMore,
  paginationDisabled,
  captchaRef,
  setTokenCaptcha,
  isDashboard
}) => {
  const { t } = useTranslation();

  const onShowMore = () => handlerShowMore?.();

  return (
    <>
      <Reviews
        siteTitle={siteTitle}
        captchaRef={captchaRef}
        setTokenCaptcha={setTokenCaptcha}
        reviews={reviews}
        changeHandler={changeHandler}
        isDashboard={isDashboard}
      />
      {!isDashboard && totalElements > pageSize * currentPage && (
        <StyledShowMoreButton onClick={onShowMore} iconRight={"refresh"}>
          {t("reviews.showMore")}
        </StyledShowMoreButton>
      )}
      {totalElements !== 0 && totalElements > pageSize && (
        <StyledPaginationBox isDashboard={isDashboard}>
          <Pagination
            rowCount={totalElements}
            rowsPerPage={pageSize}
            currentPage={currentPage}
            pageRangeDisplayed={paginationDisabled}
            marginPagesDisplayed={paginationDisabled}
            onChangePage={onChangePage}
          />
        </StyledPaginationBox>
      )}
    </>
  );
};
