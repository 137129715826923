import styled from "@emotion/styled";

export const StyledPersonalInfo = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  background: rgba(246, 247, 249, 0.5);
`;
export const StyledPersonalInfoTitle = styled.p`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 143%;
  text-transform: uppercase;
`;

export const StyledPersonalInfoInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  @media (min-width: 656px) {
    flex-direction: row;
    align-items: center;

    & > * {
      margin: 0 !important;
    }
  }
`;
