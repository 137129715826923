import { useTranslation } from "react-i18next";

import {
  ResultItem,
  ResultItemLabel,
  ResultItemValue,
  ResultList
} from "./ResultTable.styled";

export const ResultsTable = ({ data }) => {
  const { t } = useTranslation();
  const renderBoolValue = (key) => {
    if (data[key]) {
      return (
        <>
          <img src="img/icons/check-green.svg" alt="" />
          <span>{t("ui.yesNo.yes")}</span>
        </>
      );
    } else {
      return (
        <>
          <img src="img/icons/no.svg" alt="" />
          <span>{t("ui.yesNo.no")}</span>
        </>
      );
    }
  };

  const Data = [
    {
      key: "provider",
      value: data?.asnOrganization,
      src: "img/icons/provider.svg",
      alt: t("myAnonymity.table.provider")
    },
    {
      key: "host",
      value: data?.host,
      src: "img/icons/hostname.svg",
      alt: t("myAnonymity.table.hostname")
    },
    {
      key: "os",
      value: data?.os,
      src: "img/icons/os.svg",
      alt: t("myAnonymity.table.os")
    },
    {
      key: "browser",
      value: data?.browser,
      src: "img/icons/browser.svg",
      alt: t("myAnonymity.table.browser")
    },
    {
      key: "dns",
      value: data.dnsHostName,
      src: "img/icons/dns.svg",
      alt: t("myAnonymity.table.dns")
    },
    {
      key: "proxy",
      value: renderBoolValue("proxy"),
      src: "img/icons/proxy.svg",
      alt: t("myAnonymity.table.proxy")
    },
    {
      key: "anonymizer",
      value: renderBoolValue("anonymizer"),
      src: "img/icons/anonymizer.svg",
      alt: t("myAnonymity.table.anonymizer")
    },
    {
      key: "inBlacklist",
      value: renderBoolValue("inBlacklist"),
      src: "img/icons/blacklist.svg",
      alt: t("myAnonymity.table.blacklist")
    }
  ];

  return (
    <ResultList>
      {Data.map((item) => (
        <ResultItem key={item.key}>
          <ResultItemLabel>
            <img src={item.src} alt={item.alt} width="24px" height="24px" />
            <span>{item.alt}</span>
          </ResultItemLabel>
          <ResultItemValue>{item.value}</ResultItemValue>
        </ResultItem>
      ))}
    </ResultList>
  );
};
