import styled from "@emotion/styled";

export const InfoIcon = styled.img`
  margin-bottom: -6px;
`;

export const StyledValue = styled.div`
  background-color: #f5f6f7;
  padding: 10px 8px;
  border-radius: 10px;

  & span {
    text-transform: capitalize;
  }
`;

export const StyledValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const StyledTableWrapper = styled.div`
  position: relative;
  &::after {
    content: "";
    position: absolute;
    pointer-events: none;
    right: 0;
    bottom: 24px;
    height: 64px;
    width: 100%;
    background: ${(p) =>
      p.scrollBottom
        ? "transparent"
        : "linear-gradient(0deg, #FFFFFF 35%, rgba(255, 255, 255, 0) 170.01%)"};

    @media screen and (min-width: ${({ theme }) => theme.screenSizes.xlMin}) {
      right: 30px;
      width: calc(100% - 30px);
    }
  }
`;

export const StyledAttribute = styled.span`
  margin-right: 8px;
`;
