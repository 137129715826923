import styled from "@emotion/styled";

export const StyledTitleWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 32px;
  }

  @media (min-width: ${(props) => props.theme.screenSizes.lgMin}) {
    margin-bottom: 48px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin-bottom: 0;
    }
  }
`;

export const StyledTableWrapper = styled.div`
  position: relative;
  &::after {
    content: "";
    position: absolute;
    pointer-events: none;
    right: 0;
    bottom: 24px;
    height: 64px;
    width: 100%;
    background: ${(p) =>
      p.scrollBottom
        ? "transparent"
        : "linear-gradient(0deg, #fff 23.68%, #ffffff00 63.16%)"};

    @media screen and (min-width: ${({ theme }) => theme.screenSizes.xlMin}) {
      right: 30px;
      width: calc(100% - 24px);
    }
  }
`;
