import PT from "prop-types";

import {
  StyledCard,
  StyledContent,
  StyledIcon,
  StyledText
} from "./InfoMessage.styled";

export const InfoMessage = ({ text, className }) => (
  <StyledCard className={className}>
    <StyledContent>
      <StyledIcon src="img/ui/info.svg" />
      <StyledText>{text}</StyledText>
    </StyledContent>
  </StyledCard>
);

InfoMessage.propTypes = {
  text: PT.string,
  className: PT.string
};
