import styled from "@emotion/styled";

import { Select } from "../../../Select/Select";

export const ItemsOnPageStyled = styled.div`
  display: none;

  color: ${({ theme }) => theme.colors["neutral-500"]};
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  line-height: 140%;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;

export const RestyledSelect = styled(Select)`
  .form-select__control {
    padding: 9px 7px;
  }

  .form-select__value-container {
    color: ${({ theme }) => theme.colors["neutral-500"]};
    font-family: ${({ theme }) => theme.fontFamily.lato};
    font-size: ${({ theme }) => theme.fontSizes.s};
    line-height: 140%;
  }

  .form-select__indicator {
    & > svg {
      width: 20px;
      height: 20px;
    }
  }

  .form-select__menu {
    min-width: fit-content;
    right: 0;
  }
`;
