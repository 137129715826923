import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";

export const ProxyPageContainer = styled(Container)`
  padding-bottom: 64px !important;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-bottom: 120px !important;
  }
`;

export const ProxyPaginationBox = styled.div`
  border: ${(p) => p.theme.borders.border};
  border-top: 0;
  border-right: 0;
  border-radius: 0 0 0 12px;
  padding: 16px;

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    border-radius: 0 0 12px 12px;
    border-right: ${(p) => p.theme.borders.border};
    & > div {
      padding-top: 10px;
    }
  }
`;
