import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";
import { Title } from "../../../../components/ui/Title/Title";

export const RectyledContainer = styled(Container)`
  position: relative;
  z-index: 2;
  margin-top: -35px;
  padding-bottom: 64px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    margin-top: -165px;
    padding-bottom: 0;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors["background-color"]};
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.card};
  padding: 0;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    padding: 36px;
    background: ${({ theme }) => theme.colors.white};
    margin-top: -140px;
    flex-direction: row;
    min-height: 545px;
  }
`;

export const TestWrapper = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 24px;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.card};

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    padding: 0;
    margin-bottom: 0;
    border-radius: 0;
    box-shadow: none;
  }
`;

export const TestCard = styled.div`
  background: ${({ theme }) => theme.colors["background-color"]};
  border-radius: 12px;
  padding: 12px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    width: 47%;
    max-width: 360px;
    min-width: 360px;
    margin-right: 36px;
    margin-left: 0;
    margin-bottom: 0;
    padding: 24px;
    padding-top: 36px;
  }
`;

export const ImageWrapper = styled.div`
  min-height: 215px;

  & img {
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const RestyledTitle = styled(Title)`
  font-size: ${({ theme }) => theme.fontSizes.xl};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 120%;
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : "16px")} !important;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    font-size: 32px;
    line-height: 120%;
    margin-bottom: ${({ mb }) => (mb ? `${mb}px` : "24px")} !important;
  }

  & span {
    color: ${({ theme }) => theme.colors["main-700"]};
  }
`;

export const InfoMessage = styled.p`
  display: flex;
  align-items: center;
  gap: 12px
  color: ${({ theme }) => theme.colors["neutral-500"]};
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: 140%;
  margin-bottom: 24px;

  img {
   flex-shrink: 0;
  }
`;

export const ResultsWrapper = styled.div`
  width: 100%;
  border-radius: 12px;
`;
