import styled from "@emotion/styled";

export const StyledProgressBar = styled.div`
  width: 157px;
  height: 157px;
  border-radius: 50%;
  border: 2px solid rgba(216, 218, 224, 0.25);
  padding: 21px;
  position: relative;
`;

export const StyledProgressCircleImg = styled.img`
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
`;

export const StyledProgressText = styled.div`
  display: flex;
  align-items: flex-start;

  color: ${({ theme }) => theme.colors["neutral-800"]};
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-size: ${({ theme }) => theme.fontSizes.xxl};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: 1.05;
  &:after {
    content: "%";
    font-size: ${({ theme }) => theme.fontSizes.xl};
    line-height: 1.21;
  }
`;

export const StyledProgressPercent = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xl};
  line-height: 1.21;
`;
