import { Navigate } from "react-router-dom";

import AddEditPromocode from "../pages/Dashboard/AddEditPromocode/AddEditPromocode";
import AddEditService from "../pages/Dashboard/AddEditService/AddEditService";
import Profile from "../pages/Dashboard/Profile/Profile";
import Promocodes from "../pages/Dashboard/Promocodes/Promocodes";
import Reviews from "../pages/Dashboard/Reviews/Reviews";
import Services from "../pages/Dashboard/Services/Services";

export const DashboardRoutes = (ssr) => [
  {
    path: "/:lang?/dashboard/profile",
    tag: "dashboard-profile",
    type: "dashboard",
    component: ssr
      ? Profile
      : () => import("../pages/Dashboard/Profile/Profile"),
    isAuthRequired: true
  },
  {
    path: "/:lang?/dashboard/services",
    tag: "dashboard-services",
    type: "dashboard",
    component: ssr
      ? Services
      : () => import("../pages/Dashboard/Services/Services"),
    isAuthRequired: true
  },
  {
    path: "/:lang?/dashboard/services/create",
    tag: "dashboard-services-create",
    type: "dashboard",
    component: ssr
      ? AddEditService
      : () => import("../pages/Dashboard/AddEditService/AddEditService"),
    isAuthRequired: true
  },
  {
    path: "/:lang?/dashboard/services/edit/:id",
    tag: "dashboard-services-edit",
    type: "dashboard",
    component: () => import("../pages/Dashboard/AddEditService/AddEditService"),
    element: AddEditService,
    isAuthRequired: true
  },
  {
    path: "/:lang?/dashboard/reviews",
    tag: "dashboard-reviews",
    type: "dashboard",
    component: ssr
      ? Reviews
      : () => import("../pages/Dashboard/Reviews/Reviews"),
    isAuthRequired: true
  },
  {
    path: "/:lang?/dashboard/promocodes",
    tag: "dashboard-promocodes",
    type: "dashboard",
    component: ssr
      ? Promocodes
      : () => import("../pages/Dashboard/Promocodes/Promocodes"),
    isAuthRequired: true
  },
  {
    path: "/:lang?/dashboard/promocodes/create",
    tag: "dashboard-promocodes-create",
    type: "dashboard",
    component: ssr
      ? AddEditPromocode
      : () => import("../pages/Dashboard/AddEditPromocode/AddEditPromocode"),
    isAuthRequired: true
  },
  {
    path: "/:lang?/dashboard/promocodes/edit/:id",
    tag: "dashboard-promocodes-edit",
    type: "dashboard",
    component: ssr
      ? AddEditPromocode
      : () => import("../pages/Dashboard/AddEditPromocode/AddEditPromocode"),
    element: AddEditPromocode,
    isAuthRequired: true
  },
  {
    path: "/:lang?/dashboard/*",
    element: <Navigate to="/dashboard/profile" replace={true} />,
    isNavigate: true
  }
];
