import { StyledFlag } from "../../pages/ToolsPages/HttpHeadersCheckerPage/HttpHeaderCheck/HttpHeaderCheck.styled";

import { convertCountryCodeAlpha3ToAlpha2 } from "./convertCountryCodeAlpha3ToAlpha2.helper";

export const getSelectCountryOptions = (countries, queryLang) =>
  countries.data?.map((country) => {
    const alpha2 = convertCountryCodeAlpha3ToAlpha2(
      country?.code
    ).toLowerCase();
    const name = queryLang ? country?.localization[queryLang] : "";
    return {
      value: country?.code,
      label: (
        <>
          <StyledFlag countryCode={alpha2} />
          <span>{name}</span>
        </>
      )
    };
  });
