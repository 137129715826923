import styled from "@emotion/styled";

import { Locations } from "../Locations/Locations";
import { NavigationItem } from "../Navigation/NavigationItem";

export const StyledProxiesNavigation = styled.div`
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 32px 0;
  }
`;

export const StyledProxiesNavigationItem = styled(NavigationItem)`
  border-right: none !important;
`;

export const StyledLocationsList = styled(Locations)`
  width: 100%;
  margin-top: 16px;
  display: ${(p) => (p.isOpened ? "block" : "none")};
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: block;
  }
`;
