import BrowserInformationPage from "../pages/ToolsPages/BrowserInformationPage/BrowserInformationPage";
import DnsLeakTestPage from "../pages/ToolsPages/DnsLeakTestPage/DnsLeakTestPage";
import EvercookieTestPage from "../pages/ToolsPages/EvercookieTestPage/EvercookieTestPage";
import FingerprintPage from "../pages/ToolsPages/FingerprintPage/FingerprintPage.jsx";
import FreeProxyPage from "../pages/ToolsPages/FreeProxyPage/FreeProxyPage";
import HttpHeadersCheckerPage from "../pages/ToolsPages/HttpHeadersCheckerPage/HttpHeadersCheckerPage";
import IpBlacklistCheckPage from "../pages/ToolsPages/IpBlacklistCheckPage/IpBlacklistCheckPage";
import MyAnonymityPage from "../pages/ToolsPages/MyAnonymityPage/MyAnonymityPage";
import PortScannerPage from "../pages/ToolsPages/PortScannerPage/PortScannerPage";
import TracingIpPage from "../pages/ToolsPages/TracingIpPage/TracingIpPage";
import WebRTCLeakTestPage from "../pages/ToolsPages/WebRTCLeakTestPage/WebRTCLeakTestPage";

export const ToolsRoutes = (ssr) => [
  {
    path: "/:lang?/tools/fingerprint",
    tag: "fingerprint",
    type: "tool",
    component: ssr
      ? FingerprintPage
      : () => import("../pages/ToolsPages/FingerprintPage/FingerprintPage"),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/port-scanner",
    tag: "port-scanner",
    type: "tool",
    component: ssr
      ? PortScannerPage
      : () => import("../pages/ToolsPages/PortScannerPage/PortScannerPage"),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/tracing-ip",
    tag: "tracing-ip",
    type: "tool",
    component: ssr
      ? TracingIpPage
      : () => import("../pages/ToolsPages/TracingIpPage/TracingIpPage"),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/dns-leak-test",
    tag: "dns-leak-test",
    type: "tool",
    component: ssr
      ? DnsLeakTestPage
      : () => import("../pages/ToolsPages/DnsLeakTestPage/DnsLeakTestPage"),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/ip-blacklist-check",
    tag: "ip-blacklist-check",
    type: "tool",
    component: ssr
      ? IpBlacklistCheckPage
      : () =>
          import(
            "../pages/ToolsPages/IpBlacklistCheckPage/IpBlacklistCheckPage"
          ),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/free-proxy",
    tag: "free-proxy",
    type: "tool",
    component: ssr
      ? FreeProxyPage
      : () => import("../pages/ToolsPages/FreeProxyPage/FreeProxyPage"),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/my-anonymity",
    tag: "my-anonymity",
    type: "tool",
    component: ssr
      ? MyAnonymityPage
      : () => import("../pages/ToolsPages/MyAnonymityPage/MyAnonymityPage"),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/webrtc-leak-test",
    tag: "webrtc-leak-test",
    type: "tool",
    component: ssr
      ? WebRTCLeakTestPage
      : () =>
          import("../pages/ToolsPages/WebRTCLeakTestPage/WebRTCLeakTestPage"),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/http-headers-checker",
    tag: "http-headers-checker",
    type: "tool",
    component: ssr
      ? HttpHeadersCheckerPage
      : () =>
          import(
            "../pages/ToolsPages/HttpHeadersCheckerPage/HttpHeadersCheckerPage"
          ),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/evercookie-test",
    tag: "evercookie-test",
    type: "tool",
    component: ssr
      ? EvercookieTestPage
      : () =>
          import("../pages/ToolsPages/EvercookieTestPage/EvercookieTestPage"),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/browser-information",
    tag: "browser-information",
    type: "tool",
    component: ssr
      ? BrowserInformationPage
      : () =>
          import(
            "../pages/ToolsPages/BrowserInformationPage/BrowserInformationPage"
          ),
    isAuthRequired: false,
    isSsr: true
  }
];
