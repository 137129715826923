import moment from "moment";

import { getDntActive } from "./getDntActive";

export const getJsAttributes = () => ({
  dateJs: moment().format(),
  userAgentJs: navigator.userAgent,
  languageJs: navigator.language,
  window: {
    width: window.screen.width,
    height: window.screen.height
  },
  flash:
    typeof navigator.plugins !== "undefined" &&
    typeof navigator.plugins["Shockwave Flash"] === "object",
  java: navigator.javaEnabled(),
  activeX: typeof window.ActiveXObject === "undefined",
  webRtc: true,
  doNotTrack: getDntActive(),
  mobile: navigator.userAgentData?.mobile,
  isVBScript:
    !!navigator.userAgent.match(/Trident/) ||
    !!navigator.userAgent.match(/MSIE/),
  color: window.screen?.colorDepth,
  dpi: window.devicePixelRatio
});
