import { useState } from "react";

import { useTranslation } from "react-i18next";

import { handleScrollBottomTable } from "../../../../../utils/helpers/resultToolTable.helper";

import {
  StyledNoData,
  StyledTable,
  StyledTableWrapper,
  TableBody,
  TableCol,
  TableHeadCol,
  TableHeadRow,
  TableRow,
  TableText,
  TableWrapper
} from "./ResultTable.styled";

export const ResultsTable = ({ isLoading, data }) => {
  const { t } = useTranslation();

  const [scrollBottomTable, setScrollBottomTable] = useState(false);

  return (
    <StyledTableWrapper scrollBottom={scrollBottomTable}>
      <StyledTable>
        <TableWrapper>
          <TableHeadRow>
            <TableHeadCol width="235px" desktopWidth="35%">
              {t("httpHeadersChecker.table.header")}
            </TableHeadCol>
            <TableHeadCol width="446px" desktopWidth="65%">
              {t("httpHeadersChecker.table.value")}
            </TableHeadCol>
          </TableHeadRow>
          <TableBody
            onScroll={(e) => handleScrollBottomTable(e, setScrollBottomTable)}
          >
            {!isLoading && data?.length === 0 ? (
              <StyledNoData>
                {t("proxyPage.content.table.noDataComponent")}
              </StyledNoData>
            ) : (
              data.map((item, index) => (
                <TableRow key={index}>
                  <TableCol width="235px" desktopWidth="35%">
                    <TableText>{item.header}</TableText>
                  </TableCol>
                  <TableCol width="446px" desktopWidth="65%">
                    <TableText>{item.value}</TableText>
                  </TableCol>
                </TableRow>
              ))
            )}
          </TableBody>
        </TableWrapper>
      </StyledTable>
    </StyledTableWrapper>
  );
};
