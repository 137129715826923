import styled from "@emotion/styled";

import { Modal } from "../../../ui/Modal/Modal";

export const RestyledModal = styled(Modal)`
  height: 100%;
  max-height: 600px;
  padding-right: 12px;
  & > div {
    padding-right: 12px;
    overflow-y: auto;
    & > button {
      right: 12px;
    }
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    padding-right: 18px;
    & > div {
      padding-right: 18px;
      & > button {
        right: 18px;
      }
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  margin-top: 40px;
  width: 100%;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  @media (min-width: 640px) {
    @media (max-height: 480px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export const CropperImageBlock = styled.div`
  width: 100%;
  min-height: 150px;
  max-height: 300px;
  overflow: hidden;

  & > img {
    display: block;
    max-width: 100%;
  }
`;

export const CropperActionBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  min-width: 300px;
  width: 100%;
`;

export const CropperPreviewBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

export const CropperPreviewItem = styled.div`
  position: relative;
  padding: 16px;
  border-radius: 12px;
  min-width: 64px;
  min-height: 64px;
  background-color: ${({ theme }) => theme.colors["background-color"]};

  & > img {
    display: block;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
    min-width: 0;
    min-height: 0;
    width: 100%;
    max-width: 48px;
    max-height: 48px;
  }
`;

export const ActionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    flex-direction: row;
    justify-content: flex-end;
  }
  @media (max-height: 480px) {
    display: flex !important;
    flex-direction: column !important;
  }
`;
