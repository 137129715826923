import { useTranslation } from "react-i18next";

import {
  StyledTable,
  TableBody,
  TableHeadCol,
  TableHeadRow,
  TableWrapper
} from "../../../../../components/common/Table/ToolTable.styled";

import { ResultTableRow } from "./ResultTableRow";

export const ResultsTable = ({ data, status, userIp }) => {
  const { t } = useTranslation();
  return (
    <StyledTable padding="0" boxShadow="none" overflow="visible" display="none">
      <TableWrapper>
        <TableHeadRow height="48px">
          <TableHeadCol width="33%" padding="20px 0 20px 24px">
            {t("webRTCLeakTest.table.ip")}
          </TableHeadCol>
          <TableHeadCol width="33%" padding="20px 0 20px 24px">
            {t("webRTCLeakTest.table.type")}
          </TableHeadCol>
          <TableHeadCol width="33%" padding="20px 0 20px 24px">
            {t("webRTCLeakTest.table.status")}
          </TableHeadCol>
        </TableHeadRow>
        <TableBody maxHeight="1086px">
          {status ? (
            data.map((item, index) => (
              <ResultTableRow
                key={index}
                ip={item.ip}
                type={item.type}
                status={status}
              />
            ))
          ) : (
            <ResultTableRow ip={userIp} status={status} />
          )}
        </TableBody>
      </TableWrapper>
    </StyledTable>
  );
};
