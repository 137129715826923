import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { SectionSearch } from "../../../../components/common/SectionSearch/SectionSearch";
import { useDispatchedActions } from "../../../../hooks";
import { getAllTools } from "../../../../store/reducers/ToolsReducer/Tools.selectors";
import { permissionsNames } from "../../../../utils/constants/permissionsNames";
import {
  getHttpFingerprintTableData,
  getJsFingerprintTableData
} from "../../../../utils/helpers/getFingerprintTableData.helpers";

import { AttributesTable } from "./AttributesTable/AttributesTable";
import { FingerprintContainer, FingerprintSection } from "./Fingerprint.styled";

export const Fingerprint = () => {
  const { t } = useTranslation();

  const [httpAttributes, setHttpAttributes] = useState("");
  const [jsAttributes, setJsAttributes] = useState("");
  const [permissions, setPermissions] = useState([]);

  const { anonymity } = useSelector(getAllTools);
  const { fetchAnonymity } = useDispatchedActions();

  const getAllPermissions = async () => {
    const allPermissions = [];
    // eslint-disable-next-line no-undef
    await Promise.all(
      permissionsNames.map(async (permissionName) => {
        try {
          let permission;
          switch (permissionName) {
            case "push":
              permission = await navigator.permissions.query({
                name: permissionName,
                userVisibleOnly: true
              });
              break;
            default:
              permission = await navigator.permissions.query({
                name: permissionName
              });
          }
          allPermissions.push({ permissionName, state: permission.state });
        } catch (e) {
          allPermissions.push({
            permissionName,
            state: "error",
            errorMessage: e.toString()
          });
        }
      })
    );
    return setPermissions(allPermissions);
  };

  useEffect(() => {
    getAllPermissions();
    if (!anonymity?.isLoaded) {
      fetchAnonymity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FingerprintSection>
      <FingerprintContainer>
        <SectionSearch
          title={t("fingerprint.httpAttributes")}
          placeholder={t("fingerprint.searchAttributes")}
          value={httpAttributes}
          onChange={(value) => setHttpAttributes(value)}
        />
        <div id="httpAttributes" />
        <AttributesTable
          data={getHttpFingerprintTableData(anonymity?.data, t)}
          isLoading={anonymity?.loading}
          margin="0 0 88px 0"
          searchValue={httpAttributes}
        />
        <SectionSearch
          title={t("fingerprint.javaScriptAttributes")}
          placeholder={t("fingerprint.searchAttributes")}
          value={jsAttributes}
          onChange={(value) => setJsAttributes(value)}
        />
        <AttributesTable
          data={getJsFingerprintTableData(anonymity?.data, t, permissions)}
          searchValue={jsAttributes}
          gradient
        />
      </FingerprintContainer>
    </FingerprintSection>
  );
};
