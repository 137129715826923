/* eslint-disable no-undef */
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { ExpandableComponent } from "../../../../../components/common/ExpandableCompononent/ExpandableComponent";
import { StyledDataTable } from "../../../../../components/common/Table/Table.styled";
import {
  BoxCell,
  CustomExpandCell,
  HeaderNameCell,
  NoDataComponent,
  SiteNameCell,
  TextCell
} from "../../../../../components/common/TableColumns";
import { YesNo } from "../../../../../components/common/YesNo/YesNo";
import { Rating } from "../../../../../components/ui/Raiting/Rating";
import { SkeletonMainPageTable } from "../../../../../components/ui/Skeleton/SkeletonMainPageTable/SkeletonMainPageTable";
import { TextButton } from "../../../../../components/ui/TextButton/TextButton";
import { useLangUrlDefault } from "../../../../../hooks";
import { time } from "../../../../../utils/helpers";

export const ProxyTable = ({ isLoading, tableData, withOutPagination }) => {
  const [queryLang, hrefLang] = useLangUrlDefault();

  const { t } = useTranslation();

  const [data, setData] = useState(tableData);

  const handleExpand = (event) => {
    const id = event.currentTarget.id.replace("expand-", "");
    const newData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          expanded: !item.expanded
        };
      }
      return { ...item, expanded: false };
    });
    setData(newData);
  };

  const columns = [
    {
      name: (
        <HeaderNameCell minWidth={240}>
          {t("proxyPage.content.table.name.name")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell minWidth={240}>
          <SiteNameCell
            imgUrl={row?.imageFile}
            name={row?.name}
            rating={row?.rating}
            showAnnotation
            positive={row?.ratingPositive}
            negative={row?.ratingNegative}
            showReviewButton={row?.showReviewButton}
            reviewButtonUrl={row?.reviewButtonUrl}
            // linkToReview={`${hrefLang}/proxy/${row.alias}/#reviews`}
          />
        </BoxCell>
      ),
      width: "240px"
    },
    {
      name: (
        <HeaderNameCell minWidth={100} sortable>
          {t("proxyPage.content.table.rating.name")}
        </HeaderNameCell>
      ),

      cell: (row) => (
        <BoxCell minWidth={100}>
          <Rating rating={row?.rating} size={"md"} />
        </BoxCell>
      ),
      sortable: true,
      selector: (row) => row?.rating
    },

    {
      name: (
        <HeaderNameCell width={100} sortable>
          {t("proxyPage.content.table.minPrice.title")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell width={100}>
          <TextCell>{`$${row?.price}`}</TextCell>
        </BoxCell>
      ),
      sortable: true,
      selector: (row) => row.price
      // width: "100px"
    },
    {
      name: (
        <HeaderNameCell width={110} sortable>
          {t("proxyPage.content.table.minRent.title")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell width={110}>
          <TextCell>
            {time(
              row?.minRentPeriod?.periodName,
              row?.minRentPeriod?.periodValue,
              queryLang,
              t("times", { returnObjects: true })
            )}
          </TextCell>
        </BoxCell>
      ),
      sortable: true,
      selector: (row) => row?.minRentPeriod?.periodMilliseconds
      // width: "110px"
    },
    {
      name: (
        <HeaderNameCell width={94}>
          {t("proxyPage.content.table.freeTest.title")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell width={94}>
          <YesNo value={row?.freeTest} />
        </BoxCell>
      )

      // width: "94px"
    },
    {
      name: (
        <HeaderNameCell width={105}>
          {t("proxyPage.content.table.site.title")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell width={105}>
          <TextButton
            size={"sm"}
            color={"table"}
            iconRight={"arrowAltRight"}
            linkTo={`${hrefLang}/proxy/${row.alias}/`}
          >
            {t("proxyPage.content.table.site.btn")}
          </TextButton>
        </BoxCell>
      )
      // width: "105px"
    },
    {
      cell: (row) => (
        <BoxCell width={44}>
          <CustomExpandCell
            id={`expand-${row.id}`}
            open={row?.expanded}
            onClick={handleExpand}
          />
        </BoxCell>
      ),
      width: "44px"
    }
  ];
  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  return (
    <StyledDataTable
      styleRules={{
        showedAll: withOutPagination
      }}
      columns={columns}
      fixedHeader
      fixedHeaderScrollHeight="1000px"
      data={data}
      progressPending={isLoading}
      progressComponent={<SkeletonMainPageTable cells={10} />}
      persistTableHead={!isLoading}
      noDataComponent={<NoDataComponent bordered />}
      expandableRowExpanded={(row) => row?.expanded}
      expandableRowsComponent={ExpandableComponent}
      expandableRows
      expandableRowsHideExpander
    />
  );
};
