import { useTranslation } from "react-i18next";

import animation from "../../../assets/animations/fingerprint.riv";
import {
  fingerprintFaqImage,
  fingerprintFaqImage2x,
  fingerprintHeroImageMobile,
  fingerprintHeroImageMobile2x
} from "../../../assets/img";
import { SeoSection } from "../../../components/common/SeoSection/SeoSection";
import { Rive } from "../../../components/ui/Rive/Rive";
import { $api } from "../../../services/AxiosInstance";
import { AllActions } from "../../../store/reducers/AllActions";
import { scrollToWithOffset } from "../../../utils/helpers/scroll.helper";

import { Fingerprint } from "./Fingerprint/Fingerprint";
import { RestyledFAQ, RestyledMainSection } from "./FingerprintPage.styled";

const FingerprintPage = () => {
  const { t } = useTranslation();

  const handleButtonClick = () => {
    scrollToWithOffset("httpAttributes", -50);
  };

  return (
    <>
      <RestyledMainSection
        buttonLabel={t("fingerprint.main.buttonLabel")}
        images={{
          mobile1x: fingerprintHeroImageMobile,
          mobile2x: fingerprintHeroImageMobile2x
        }}
        handleButtonClick={handleButtonClick}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-71}
            right={-63}
            bottom={-71}
            left={-63}
          />
        }
      />
      <Fingerprint />
      <RestyledFAQ
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        tools
      />
      <SeoSection />
    </>
  );
};

export default FingerprintPage;

FingerprintPage.getServerSideState = async (store, params) => {
  const { data } = await $api.post("/api/front/faqs", {
    pageTag: params.tag,
    languageCode: params.locale,
    params: params.params
  });

  if (data) {
    store.dispatch(
      AllActions.setFaqContent({
        data,
        pageTag: params.tag,
        languageCode: params.locale
      })
    );
  }
};
