import { useState } from "react";

import { useTranslation } from "react-i18next";

import { FormList } from "../../../../../../../components/forms/FormList/FormList";
import { LabelCountry } from "../../../../../../../components/ui/LabelContry/LabelCountry";
import { useLangUrlDefault } from "../../../../../../../hooks";
import { StyledSelect } from "../../../../../AddEditPromocode/Form/BlockDescriptions/BlockDescriptions.styled";
import { CardInfo } from "../../../../../components/CardInfo/CardInfo";

import {
  StyledAdvantagesBox,
  StyledAdvantagesHeader,
  StyledAdvantagesTitle
} from "./Advatages.styled";

export const Advantages = () => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();
  const [lang, setLang] = useState(queryLang);

  const options = [
    {
      value: "en",
      label: <LabelCountry code="en">{t("languages.en")}</LabelCountry>
    },
    {
      value: "ru",
      label: <LabelCountry code="ru">{t("languages.ru")}</LabelCountry>
    },
    {
      value: "ua",
      label: <LabelCountry code="ua">{t("languages.ua")}</LabelCountry>
    }
  ];

  return (
    <CardInfo>
      <StyledAdvantagesHeader>
        <StyledAdvantagesTitle>
          {t("dashboard.services.addEdit.blocks.type.advantages.title")}
        </StyledAdvantagesTitle>
        <StyledSelect
          defaultValue={queryLang}
          value={lang}
          onChange={setLang}
          options={options}
          noMargin
          advantages
          size={"sm"}
        />
      </StyledAdvantagesHeader>

      {options.map((option) => (
        <StyledAdvantagesBox key={option.value} hidden={option.value !== lang}>
          <FormList
            name={`advantages.${option.value}`}
            placeholder={t(
              "dashboard.services.addEdit.blocks.type.advantages.placeholder"
            )}
            addButtonText={t(
              "dashboard.services.addEdit.blocks.type.advantages.add"
            )}
          />
        </StyledAdvantagesBox>
      ))}
    </CardInfo>
  );
};
