import "moment/locale/ru";
import "moment/locale/uk";
import Moment from "react-moment";

import { Stars } from "../../../../components/ui/Stars/Stars";
import { useLangUrlDefault } from "../../../../hooks";
import { StyledSkeleton } from "../../../ui/Skeleton/components/SkeletonTableComponents.styled";

import {
  StyledBoxTextSkeleton,
  StyledRecentReviewCard,
  StyledRecentReviewHeader,
  StyledRecentReviewInfo,
  StyledRecentReviewName,
  StyledRecentReviewNameBox,
  StyledRecentReviewStarsBox,
  StyledRecentReviewText
} from "./RecentReview.styled";

export const Review = ({ review, className = "", itemHovered, loading }) => {
  const [queryLang] = useLangUrlDefault();
  return (
    <StyledRecentReviewCard className={className} hovered={itemHovered}>
      <StyledRecentReviewHeader>
        <StyledRecentReviewNameBox>
          {loading ? (
            <StyledSkeleton width={100} height={14} />
          ) : (
            <StyledRecentReviewName>
              {review?.userName?.includes("@")
                ? review?.userName.split("@")[0]
                : review?.userName}
            </StyledRecentReviewName>
          )}
          {loading ? (
            <StyledSkeleton width={80} height={12} />
          ) : (
            <StyledRecentReviewInfo
              className={"recent_info"}
              hovered={itemHovered}
            >
              {review?.siteAlias}
              <Moment
                className="recent_date"
                format="MMM DD, YYYY"
                locale={queryLang === "ua" ? "uk" : queryLang}
                suppressHydrationWarning
              >
                {new Date(review?.date)}
              </Moment>
            </StyledRecentReviewInfo>
          )}
        </StyledRecentReviewNameBox>
        <StyledRecentReviewStarsBox>
          {loading ? (
            <StyledSkeleton width={90} height={21} />
          ) : (
            <Stars value={review?.rating} />
          )}
        </StyledRecentReviewStarsBox>
      </StyledRecentReviewHeader>
      {loading ? (
        <StyledBoxTextSkeleton>
          <StyledSkeleton height={14} count={2} />
        </StyledBoxTextSkeleton>
      ) : (
        <StyledRecentReviewText>
          {review?.translation?.[queryLang]?.reviewBody || review?.reviewBody}
        </StyledRecentReviewText>
      )}
    </StyledRecentReviewCard>
  );
};
