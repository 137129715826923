import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { TextButton } from "../../../../../../ui/TextButton/TextButton";

import { StyledRenderText, StyledRenderTextWrapper } from "./RenderText.styled";

export const RenderText = ({ sub, toggleReview, children, nestingLevel }) => {
  const ref = useRef(null);
  const { t } = useTranslation();

  const [fullSize, setFullSize] = useState(0);
  const [showButton, setShowButton] = useState(false);
  const showFull = () => {
    if (fullSize) {
      setFullSize(0);
    } else {
      setFullSize(ref.current?.scrollHeight + 100);
    }
    toggleReview?.(true);
  };

  useEffect(() => {
    if (ref.current?.scrollHeight > ref.current?.clientHeight) {
      setShowButton(true);
    }
    const handleResize = () => {
      if (ref.current?.scrollHeight < 195) {
        setShowButton(false);
      } else {
        setShowButton(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return (
    <StyledRenderTextWrapper sub={sub}>
      <StyledRenderText
        ref={ref}
        fullSize={fullSize}
        showedFull={!showButton || !!fullSize}
        sub={sub}
        nestingLevel={nestingLevel}
      >
        {children}
      </StyledRenderText>
      {showButton ? (
        <TextButton onClick={showFull}>
          {fullSize ? t("reviews.showLess") : t("reviews.showFull")}
        </TextButton>
      ) : null}
    </StyledRenderTextWrapper>
  );
};
