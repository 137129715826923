import styled from "@emotion/styled";

import { Flag } from "../Flag/Flag";

export const LabelCountryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  color: ${({ theme }) => theme.colors["neutral-800"]};
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  line-height: 140%;
`;

export const LabelCountryFlag = styled(Flag)`
  border-radius: 2px;
`;
