import { useTranslation } from "react-i18next";

import animation from "../../../assets/animations/blacklist.riv";
import {
  fingerprintFaqImage,
  fingerprintFaqImage2x,
  ipBlacklistCheckHeroImageMobile
} from "../../../assets/img";
import { SeoSection } from "../../../components/common/SeoSection/SeoSection";
import { Rive } from "../../../components/ui/Rive/Rive";
import { $api } from "../../../services/AxiosInstance";
import { AllActions } from "../../../store/reducers/AllActions";

import {
  RestyledMainSection,
  StyledFAQSection
} from "./IpBlacklistCheckPage.styled";
import { IpCheck } from "./IpCheck/IpCheck";

const IpBlacklistCheckPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <RestyledMainSection
        button={false}
        images={{
          mobile1x: ipBlacklistCheckHeroImageMobile,
          mobile2x: ipBlacklistCheckHeroImageMobile
        }}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-70}
            right={-73}
            bottom={-116}
            left={-70}
          />
        }
      />
      <IpCheck />
      <StyledFAQSection
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        tools
      />
      <SeoSection />
    </>
  );
};

export default IpBlacklistCheckPage;

IpBlacklistCheckPage.getServerSideState = async (store, params) => {
  const { data } = await $api.post("/api/front/faqs", {
    pageTag: params.tag,
    languageCode: params.locale,
    params: params.params
  });

  if (data) {
    store.dispatch(
      AllActions.setFaqContent({
        data,
        pageTag: params.tag,
        languageCode: params.locale
      })
    );
  }
};
