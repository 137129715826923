import PT from "prop-types";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";

import { useLangUrlDefault } from "../../../../hooks";

import { StyledEternalBox, StyledPeriodCell } from "./PeriodCell.style";

export const PeriodCell = ({ eternal = false, startDate, endDate }) => {
  // **Props
  const [queryLang] = useLangUrlDefault();
  const { t } = useTranslation();
  return (
    <StyledPeriodCell>
      {eternal ? (
        <StyledEternalBox>{t("table.cell.period.eternal")}</StyledEternalBox>
      ) : (
        <>
          <Moment
            format="MMM DD,YYYY"
            locale={queryLang === "ua" ? "uk" : queryLang}
          >
            {new Date(startDate)}
          </Moment>
          {"-"}
          <Moment
            format="MMM DD,YYYY"
            locale={queryLang === "ua" ? "uk" : queryLang}
          >
            {new Date(endDate)}
          </Moment>
        </>
      )}
    </StyledPeriodCell>
  );
};

PeriodCell.propTypes = {
  eternal: PT.bool,
  startDate: PT.string,
  endDate: PT.string
};
