import { useEffect, useState } from "react";

import { useFormContext, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormInput } from "../../../../../components/forms/FormInput/FormInput";
import { LabelCountry } from "../../../../../components/ui/LabelContry/LabelCountry";
import { useLangUrlDefault } from "../../../../../hooks";
import { FormBlock } from "../../../components/FormBlock/FormBlock";

import { StyledBoxHidden, StyledSelect } from "./BlockDescriptions.styled";

export const BlockDescriptions = () => {
  const { t } = useTranslation();

  const [queryLang] = useLangUrlDefault();
  const [lang, setLang] = useState(queryLang);
  const { errors } = useFormState();
  const { getValues } = useFormContext();
  const options = [
    {
      value: "en",
      label: <LabelCountry code="en">{t("languages.en")}</LabelCountry>
    },
    {
      value: "ru",
      label: <LabelCountry code="ru">{t("languages.ru")}</LabelCountry>
    },
    {
      value: "ua",
      label: <LabelCountry code="ua">{t("languages.ua")}</LabelCountry>
    }
  ];

  useEffect(() => {
    const { description } = getValues();
    if (errors?.description?.type === "description-test") {
      const lang = options.find(
        (option) => !description?.[option.value]
      )?.value;
      if (lang) {
        setLang(lang);
      }
      return;
    }
    if (errors?.description?.type === "description-min") {
      const lang = options.find(
        (option) => description[option.value]?.length < 50
      )?.value;
      if (lang) {
        setLang(lang);
      }
      return;
    }
    if (errors?.description?.type === "description-max") {
      const lang = options.find(
        (option) => description[option.value]?.length > 100
      )?.value;
      if (lang) {
        setLang(lang);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
  return (
    <FormBlock
      title={t("dashboard.promocodes.addEdit.blocks.description.title")}
      subtitle={t("dashboard.promocodes.addEdit.blocks.description.subtitle")}
    >
      <StyledSelect
        defaultValue={queryLang}
        value={lang}
        onChange={setLang}
        options={options}
        size={"sm"}
      ></StyledSelect>
      {options.map((option) => (
        <StyledBoxHidden key={option.value} hidden={lang !== option.value}>
          <FormInput
            name={`description.${option.value}`}
            label={t("promocodes.description.label")}
            type={"textarea"}
            showError
            showMaxLenght={100}
            placeholder={t("promocodes.description.placeholder")}
            errorPath={"description"}
          />
        </StyledBoxHidden>
      ))}
    </FormBlock>
  );
};
