import styled from "@emotion/styled";

import mobileStarImage from "../../../../assets/img/icons/black-star-mobile.svg";
import desktopStarImage from "../../../../assets/img/icons/black-star.svg";

export const Title = styled.h1`
  margin-bottom: 16px;

  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.xxl};

  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 1.11;
  letter-spacing: -0.54px;
  color: ${(p) => p.theme.colors.white};

  @media (min-width: 1200px) {
    font-size: 72px;
    line-height: 1.08;
    letter-spacing: -2px;
    text-align: left;
  }

  span {
    display: inline-block;
    position: relative;
    padding-left: 5px;
    padding-right: 15px;
    background-color: ${(p) => p.theme.colors["main-500"]};
    color: ${(p) => p.theme.colors["neutral-900"]};

    &::after {
      content: "";
      position: absolute;
      bottom: -12px;
      right: 0;
      width: 0;
      height: 0;
      border-color: transparent ${(p) => p.theme.colors["main-500"]} transparent
        transparent;
      border-style: solid;
      border-width: 0 15px 18px 20px;

      @media (min-width: 992px) {
        bottom: -17px;
        border-width: 0 20px 23px 20px;
      }
    }
  }
`;

export const StarTitle = styled(Title)`
  span {
    padding-right: 18px;

    @media (min-width: 1201px) {
      padding-right: 23px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 16px;
      height: 15px;
      right: 2px;
      top: 2px;
      background-image: url(${mobileStarImage});

      @media (min-width: 1201px) {
        width: 24px;
        height: 23px;
        right: 3px;
        top: 2px;
        background-image: url(${desktopStarImage});
      }
    }
  }
`;
