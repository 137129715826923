import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { Icon } from "../../../ui/Icon/Icon";

export const StyledNavigation = styled.div`
  display: none;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    border-radius: 0px 0px 12px 0px;
    background: rgba(28, 32, 39, 0.59);
    backdrop-filter: blur(17.5px);
  }
`;

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledListItem = styled.li`
  padding: 12px 0px;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.09);
`;

export const StyledListLink = styled(Link)`
  color: white;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const StyledTitle = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42;
  text-transform: uppercase;
  margin-bottom: 24px;
`;

export const StyledPreview = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 12px;
  background: #0f1319;
  margin-bottom: 32px;
  width: 100%;
  gap: 24px;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

export const StyledImage = styled.picture`
  min-width: 127px;
  min-height: 126px;
  width: 146px;
  height: 154px;

  img {
    height: 100%;
  }
`;

export const StyledPreviewTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledPreviewTitle = styled.p`
  color: white;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
`;

export const StyledContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledDescription = styled.p`
  color: rgba(255, 255, 255, 0.5);
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 12px;
  font-weight: 400;
  line-height: 1.32;
  margin-bottom: 12px;
`;

export const StyledIcon = styled(Icon)`
  color: white;
  margin-left: 12px;
`;
