import PT from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useDispatchedActions } from "../../../hooks";
import { getAllSite } from "../../../store/reducers/SiteReducer/Site.selectors";

import { StyledBurger, StyledBurgerWrapper, StyledSpan } from "./Burger.styled";

export const Burger = ({ className, variant = "dark" }) => {
  // **Redux state
  const { isBurgerMenuOpened } = useSelector(getAllSite);
  const { t } = useTranslation();

  // Dispatch
  const {
    setBurgerMenu,
    setServiceMenu,
    setLocationOpened,
    setServicesOpened,
    setServicesMenuStep,
    setSelectedLocation,
    setSearchCountry
  } = useDispatchedActions();

  const clickHandler = () => {
    setServiceMenu(false);
    setBurgerMenu(!isBurgerMenuOpened);
    setServicesMenuStep("locations");
    setLocationOpened(false);
    setServicesOpened(false);
    setSelectedLocation("");
    setSearchCountry("");
  };

  return (
    <StyledBurgerWrapper
      onClick={clickHandler}
      variant={variant}
      className={className}
      active={isBurgerMenuOpened}
    >
      <StyledBurger
        variant={variant}
        active={isBurgerMenuOpened}
        aria-label={
          isBurgerMenuOpened ? t("header.ariaClose") : t("header.ariaOpen")
        }
        aria-expanded={isBurgerMenuOpened ? "true" : "false"}
        type="button"
      >
        <StyledSpan />
      </StyledBurger>
    </StyledBurgerWrapper>
  );
};

Burger.propTypes = {
  className: PT.string,
  variant: PT.oneOf(["light", "dark"])
};
