import "moment/locale/ru";
import "moment/locale/uk";
import PT from "prop-types";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { Link } from "react-router-dom";

import { useLangUrlDefault } from "../../../../../hooks";
import { Category, Date } from "../../Blog.styled";

import { LatestItemTitle, LatestItemWrapper } from "./LatestItem.styled";

export const LatestItem = ({ data = {} }) => {
  const [queryLang, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();

  return (
    <LatestItemWrapper>
      <Category>
        {data.category
          ? t("blogPage.item.categories.article")
          : t("blogPage.item.categories.news")}
      </Category>
      <Link to={`${hrefLang}/blog/${data.alias}/`}>
        <LatestItemTitle>{data.title}</LatestItemTitle>
      </Link>
      <Date suppressHydrationWarning>
        {data?.creationDate && (
          <Moment
            format="MMMM DD, YYYY"
            locale={queryLang === "ua" ? "uk" : queryLang}
          >
            {data?.creationDate}
          </Moment>
        )}
      </Date>
    </LatestItemWrapper>
  );
};

LatestItem.propTypes = {
  data: PT.shape({})
};
