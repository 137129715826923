import styled from "@emotion/styled";

import { Icon } from "../../ui/Icon/Icon";

export const ExportWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ExportName = styled.span`
  color: ${(p) => p.theme.colors.black};
  text-align: center;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
  margin-right: 4px;
`;

export const RestyledIcon = styled(Icon)`
  color: ${(p) => p.theme.colors["neutral-promo-date"]};
  transition: color 0.3s ease;

  &:hover {
    color: ${(p) => p.theme.colors["main-600"]};

    transition: color 0.3s ease;
  }
`;

export const ExportVariants = styled.div`
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: 24px;
`;

export const ExportVariant = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  color: ${(p) => p.theme.colors["neutral-500"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
  cursor: pointer;

  &::after {
    content: "";
    position: relative;
    top: -1px;
    width: 0px;
    height: 1px;
    display: block;
    background: ${(p) => p.theme.colors["main-700"]};
    transition: all 0.3s ease;
  }

  &:hover,
  &:active,
  &:focus {
    color: ${(p) => p.theme.colors["main-700"]};
    &::after {
      width: 100%;
      transition: all 0.3s ease;
    }
  }
`;
