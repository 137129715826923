import styled from "@emotion/styled";
import ReactPaginate from "react-paginate";

import { Icon } from "../../../Icon/Icon";

export const PaginationStyled = styled(ReactPaginate)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  list-style: none;
  padding: 0;
  margin: 0;

  & li {
    & > a {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: ${({ theme }) => theme.colors["neutral-400"]};
      font-family: ${({ theme }) => theme.fontFamily.lato};
      font-size: ${({ theme }) => theme.fontSizes.m};
      font-weight: ${({ theme }) => theme.fontWeight.normal};
      font-style: ${({ theme }) => theme.fontStyle.normal};
      line-height: 150%;

      outline: none;
      text-align: center;
      border-radius: 8px;
      background-color: transparent;

      transition: color 0.3s ease, font-weight 0.3s ease,
        background-color 0.5s ease;

      &:hover,
      &:focus {
        color: ${({ theme }) => theme.colors["neutral-800"]};
        cursor: pointer;
        transition: color 0.3s ease;
      }
    }

    &.page {
      @media (max-width: 480px) {
        display: none;
      }
    }

    &.break {
      display: none;
      @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
        display: block;
      }
    }

    &.selected {
      & > a {
        font-weight: 600;
        color: ${({ theme }) => theme.colors["neutral-800"]};
        background-color: ${({ theme }) => theme.colors["background-color"]};

        transition: color 0.3s ease, font-weight 0.3s ease,
          background-color 0.5s ease;
      }
    }

    &.disabled {
      & > a {
        color: ${({ theme }) => theme.colors["neutral-400"]} !important;
        background-color: transparent !important;
        pointer-events: none;

        transition: color 0.3s ease, background-color 0.3s ease;
      }
    }
  }
`;
export const RestyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  transform: rotate(${({ rotate }) => rotate || 0}deg);
`;
