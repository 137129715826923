import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import { Button } from "../../../ui/Button/Button";
import { Logo } from "../../../ui/Logo/Logo";

export const MenuBackDrop = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.32);

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    display: none;
  }
`;

export const MenuWrapper = styled.div`
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;

  height: ${({ type }) => {
    if (type === "logout") {
      return "285px";
    }
    if (type === "addSite") {
      return "128px";
    }
    return "80px";
  }};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-image: radial-gradient(
    circle at top,
    transparent 32px,
    ${({ theme }) => theme.colors["background-color-black"]} 32px
  );
  background-image: url("data:image/svg+xml,%3Csvg width='360' height='80' viewBox='0 0 360 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M180 32C190.682 32 200.141 26.7664 205.953 18.7236C212.423 9.7711 220.954 0 232 0H348C354.627 0 360 5.37258 360 12V79C360 79.5523 359.552 80 359 80H0.999992C0.447707 80 0 79.5523 0 79V12C0 5.37258 5.37258 0 12 0H128C139.046 0 147.577 9.77109 154.047 18.7236C159.859 26.7664 169.318 32 180 32Z' fill='%23141921'/%3E%3C/svg%3E%0A");
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  border-radius: 12px 12px 0 0;

  transition: height 0.3s ease;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    height: 100vh;

    align-items: center;
    background-image: none;
    background-color: ${({ theme }) => theme.colors["background-color-black"]};
    border-radius: 0 12px 12px 0;
    width: 240px;
    box-shadow: 0px 10px 60px 0px rgba(135, 140, 189, 0.25);

    transition: height 0s ease;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px 12px 0 0;
    background-image: radial-gradient(
      circle at top,
      transparent 64px,
      ${({ theme }) => theme.colors["background-color-black"]} 64px
    );

    @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
      display: none;
    }
  }
`;

export const RestyledLogo = styled(Logo)`
  display: none;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 28px 20px;
  }
`;

export const MenuLogout = styled.div`
  position: relative;
  z-index: 3;
  padding: 0 16px 24px;
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-style: ${({ theme }) => theme.fontStyle.normal};
`;

export const LogoutTitle = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.l};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 4px;
`;

export const UserEmail = styled.p`
  color: ${({ theme }) => theme.colors["neutral-300"]};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: 140%;
  margin-bottom: 32px;
`;

export const ButtonLogout = styled(Button)`
  margin-top: 12px;

  border-radius: 8px;
  background-image: none;
  background: rgba(255, 255, 255, 0.05);
  color: ${({ theme }) => theme.colors.white};
  padding: 8px 12px;
  height: 60px;

  justify-content: space-between;

  transition: background 0.3s ease;

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.08);
    transition: background 0.3s ease;
  }

  & > svg {
    width: 24px;
    height: 24px;
    margin: 10px;
  }
`;

export const ButtonCreate = styled(Button)`
  position: relative;
  z-index: 1;
  min-height: 48px;

  display: ${({ visible }) => (visible ? "flex" : "none")};

  margin: 56px auto 24px;
  min-width: 200px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    display: flex;
    margin: 28px 0;
    border-radius: 8px;
  }
`;

export const ButtonMobileMenu = styled(Button)`
  position: absolute;
  top: 0;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 48px;
  background-image: none;
  background-color: ${({ theme, menuType }) =>
    menuType === "logout"
      ? theme.colors["main-400"]
      : theme.colors["main-500"]};

  border-radius: 50%;
  padding: 0;

  transition: background-color 0.3s ease;

  & > svg {
    width: 24px;
    height: 24px;
    transform: rotate(${({ open }) => (open ? "45deg" : "0")});

    transition: transform 0.3s ease;
  }

  & > div {
    display: ${({ menuType }) => (menuType !== "logout" ? "none" : "block")};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    display: none;
  }
`;

export const Menu = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  padding-right: 6px;

  color: ${({ theme }) => theme.colors["neutral-300"]};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  line-height: 130%;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    flex-direction: column;
    margin-right: auto;
    margin-bottom: auto;
    overflow-y: auto;

    width: 100%;
    max-width: 230px;
    font-size: ${({ theme }) => theme.fontSizes.m};
    line-height: 140%;
    gap: 12px;
  }
`;

export const MobileCenterMenuSeparator = styled.div`
  min-width: 60px;
  height: 0px;
  align-self: flex-end;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    display: none;
  }
`;

export const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding-top: 18px;
  padding-bottom: 8px;
  border-bottom: 2px solid transparent;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 60px;
  text-transform: capitalize;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;

  & img {
    width: 24px;
    height: 24px;
  }

  &[aria-current="page"],
  &[class*="active"] {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    border-color: ${({ theme }) => theme.colors["main-500"]};
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.05) 100%
    );
    transition: background-color 0.3s ease, color 0.3s ease,
      border-color 0.3s ease;

    @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
      background: rgba(255, 255, 255, 0.08);
    }
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    border-color: ${({ theme }) => theme.colors["main-500"]};
    background: rgba(255, 255, 255, 0.05);
    transition: background-color 0.3s ease, color 0.3s ease,
      border-color 0.3s ease;
  }

  &:active {
    background: rgba(255, 255, 255, 0.12) !important;
    transition: background-color 0.3s ease, color 0.3s ease,
      border-color 0.3s ease;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    min-height: 56px;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    padding: 16px 20px 16px 18px;
    border-bottom: 0px;
    border-left: 2px solid transparent;
    border-radius: 0 8px 8px 0;
    width: 100%;
    max-width: 220px;

    &[aria-current="page"],
    &[class*="active"] {
      background: rgba(255, 255, 255, 0.08);
    }
  }

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
