import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import animationGuide from "../../../assets/animations/proxy_purchasing_guide.riv";
import animationService from "../../../assets/animations/proxy_service.riv";
import {
  howToBuyIpv4ProxyImageMobile,
  howToBuyIpv4ProxyImageMobile2x,
  ipv4ProxyImageMobile,
  ipv4ProxyImageMobile2x
} from "../../../assets/img";
import { Rive } from "../../../components/ui/Rive/Rive";
import { useLangUrlDefault, useSeoBlock } from "../../../hooks";
import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";
import { parseSeoData } from "../../../utils/helpers";

import {
  StyledContainer,
  StyledContent,
  StyledDottedListItem,
  StyledGuideAnimation,
  StyledHeading,
  StyledImage,
  StyledItemDescription,
  StyledItemTitle,
  StyledListItem,
  StyledListTitle,
  StyledSection,
  StyledServiceAnimation,
  StyledSubheading
} from "./HowToBuy.styled";

export const HowToBuy = () => {
  const [searchParams] = useSearchParams();
  const [queryLang] = useLangUrlDefault();

  const { t } = useTranslation();
  const { data } = useSeoBlock(["howToBuy", "infoAboutProxyType"]);
  const { proxyTypes } = useSelector(getAllContent);
  const activeType = searchParams.get("fpt") || "IPv4";

  const proxyType = proxyTypes.data?.[queryLang]?.find(
    (item) => item.type === activeType
  );

  const numberedList = useMemo(() => {
    const list = parseSeoData(data?.howToBuy?.list, "JSON");
    if (!list || !list?.length) return null;
    return (
      <ul>
        {list.map((item, index) => (
          <StyledListItem key={index}>
            <StyledItemTitle>{`${index + 1}. ${item.title}`}</StyledItemTitle>
            <StyledItemDescription>{item.description}</StyledItemDescription>
          </StyledListItem>
        ))}
      </ul>
    );
  }, [data]);

  const dottedList = useMemo(() => {
    const list = parseSeoData(data?.infoAboutProxyType?.list, "JSON");
    if (!list || !list?.length) return null;
    return (
      <ul>
        {list.map((item, index) => (
          <StyledDottedListItem key={index}>
            <div>
              <img src="img/icons/dot.svg" alt="Dot icon" />
            </div>
            {item}
          </StyledDottedListItem>
        ))}
      </ul>
    );
  }, [data]);

  return (
    <>
      {data?.howToBuy?.showOnFront && (
        <StyledSection background="dark">
          <StyledContainer>
            <StyledGuideAnimation>
              <Rive
                src={animationGuide}
                autoPlay
                top={-82}
                right={-80}
                bottom={-81}
                left={-81}
              />
            </StyledGuideAnimation>
            <StyledContent>
              <StyledHeading marginBottom="small">
                {parseSeoData(data?.howToBuy?.title, "TEXT")}
              </StyledHeading>
              <StyledSubheading>
                {parseSeoData(data?.howToBuy?.subtitle)}
              </StyledSubheading>
              {numberedList}
            </StyledContent>
            <StyledImage variant="dark">
              <source
                srcSet={`${howToBuyIpv4ProxyImageMobile} 1x, ${howToBuyIpv4ProxyImageMobile2x} 2x`}
              />
              <img
                src={howToBuyIpv4ProxyImageMobile2x}
                alt={`${t("howToBuy.title")} ${proxyType} ${t(
                  "howToBuy.proxy"
                )}`}
                loading="lazy"
              />
            </StyledImage>
          </StyledContainer>
        </StyledSection>
      )}
      {data?.infoAboutProxyType?.showOnFront && (
        <StyledSection background="light">
          <StyledContainer>
            <StyledContent>
              <StyledHeading>
                {parseSeoData(data?.infoAboutProxyType?.title, "TEXT")}
              </StyledHeading>
              <StyledSubheading>
                {parseSeoData(data?.infoAboutProxyType?.description, "TEXT")}
              </StyledSubheading>
              <StyledListTitle>
                {parseSeoData(data?.infoAboutProxyType?.subtitle, "TEXT")}
              </StyledListTitle>
              {dottedList}
            </StyledContent>
            <StyledServiceAnimation>
              <Rive
                src={animationService}
                autoPlay
                top={-30}
                right={-39}
                bottom={-62}
                left={-64}
              />
            </StyledServiceAnimation>
            <StyledImage variant="light">
              <source
                srcSet={`${ipv4ProxyImageMobile} 1x, ${ipv4ProxyImageMobile2x} 2x`}
              />
              <img src={ipv4ProxyImageMobile} alt="IPv4 Proxy" loading="lazy" />
            </StyledImage>
          </StyledContainer>
        </StyledSection>
      )}
    </>
  );
};
