import { useEffect, useId, useRef, useState } from "react";

import { StyledTooltip } from "../../../../../components/ui/Tooltip/Tooltip.styled";
import { converToCurrentSocialLink } from "../../../../../utils/helpers/converToCurrentSocialLInk.helpers";

import { StyledContactLink } from "./Social.styled";

export const SocialLink = ({ code, link, name, isMedia }) => {
  const ref = useRef();
  const id = useId();

  const [needTooltip, setNeedTooltip] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setNeedTooltip(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, [ref]);

  return (
    <>
      <StyledContactLink
        to={converToCurrentSocialLink(link, code)}
        target="_blank"
        rel="noreferrer nofollow"
        capitalize={isMedia ? "capitalize" : "none"}
      >
        {code && <img src={`/img/social/${code}.svg`} alt={code} />}
        <span ref={ref} data-tooltip-id={id}>
          {name}
        </span>
      </StyledContactLink>
      {needTooltip ? (
        <StyledTooltip id={id} target={ref}>
          {link}
        </StyledTooltip>
      ) : null}
    </>
  );
};
