import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";
import { Flag } from "../../../../components/ui/Flag/Flag";
import { Icon } from "../../../../components/ui/Icon/Icon";
import { Tooltip } from "../../../../components/ui/Tooltip/Tooltip";

export const StyledContainer = styled(Container)`
  padding-bottom: 32px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-bottom: 0 !important;
  }
`;

export const StyledInnerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 7;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0px 5px 28px 0px rgba(135, 140, 189, 0.08);
  margin-top: -35px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 36px;
    margin-top: -190px;
    flex-direction: row !important;
    align-items: center;
    min-height: 480px;
  }
`;
export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-bottom: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
    margin-right: 36px;
  }
`;
export const StyledIp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: center;
  }
`;
export const StyledIpWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background: ${(p) => p.theme.colors["background-color"]};
  padding: 16px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 16px 24px;
  }
`;

export const StyledIpTitle = styled.p`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
    margin-right: 12px;
  }
`;

export const StyledFlag = styled(Flag)`
  margin-right: 12px;
  transform: scale(1.3);
  margin-top: 2px;
  border-radius: 2px;
`;

export const StyledIpAddress = styled.div`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
`;

export const StyledIpAddressWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  color: ${(p) => p.theme.colors["neutral-400"]};
  margin-left: 8px;
  margin-bottom: 2px;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover,
  &:focus {
    color: ${(p) => p.theme.colors["main-700"]};
    transition: color 0.3s ease;
  }
`;

export const StyledCountry = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors["neutral-500"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
`;

export const StyledCountryIcon = styled(Icon)`
  color: ${(p) => p.theme.colors["neutral-400"]};
  margin-right: 8px;
`;

export const StyledResults = styled.div`
  width: 100%;
  min-height: 324px;
  border-radius: 12px;
  background: ${(p) => p.theme.colors["background-color"]};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    min-height: 352px;
  }
`;

export const StyledResultsContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledResultsLoading = styled.div`
  width: 100%;
  min-height: 324px;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 12px;
  background: ${(p) => p.theme.colors["background-color"]};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    min-height: 352px;
  }
`;

export const StyledImage = styled.img`
  width: 84px;
  height: 84px;
  flex-shrink: 0;
  margin-bottom: 16px;
`;
export const StyledText = styled.p`
  color: ${(p) => p.theme.colors["neutral-800"]};
  text-align: center;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 8px;
`;
export const StyledBrowser = styled.h3`
  color: ${(p) => p.theme.colors["neutral-800"]};
  text-align: center;
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.21;
  margin-bottom: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.19;
    margin-bottom: 36px;
  }
`;
export const StyledTextOverflow = styled.span`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 240px;
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
`;
export const StyledTooltip = styled(Tooltip)`
  > div:first-of-type {
    white-space: normal;
    word-break: break-all;
  }
`;
