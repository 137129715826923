import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";

export const DnsInfoSection = styled.div`
  overflow: hidden;
  background-color: ${(p) => (p.light ? p.theme.colors.white : "transparent")};
  padding-top: ${(p) => (p.light ? 64 : 58)}px;
  padding-bottom: ${(p) => (p.light ? 64 : 76)}px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    padding-top: ${(p) => (p.light ? 40 : 65)}px;
    padding-bottom: ${(p) => (p.light ? 35 : 64)}px;
  }
`;

export const RestylredContainer = styled(Container)`
  display: flex;
  flex-direction: ${(p) => (p.light ? "column-reverse" : "column")};

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    margin-bottom: 0;
    ${(p) => (p.light ? "margin-left: 82px;" : "margin-right: 64px;")}
  }
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors["neutral-600"]};
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-size: ${({ theme }) => theme.fontSizes.l};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: 170%;
`;

export const AnimationContainer = styled.div`
  position: relative;
  display: none;
  width: 0;
  height: 0;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    display: block;
    ${(p) =>
      p.light
        ? `min-width: 419px;
        width: 419px;
        height: 351px;`
        : `
    min-width: 573px;
    width: 573px;
    height: 302px;`}
  }
`;
export const Picture = styled.picture`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    display: none;
  }
`;

export const Image = styled.img`
  width: 100%;
  max-width: ${(p) => (p.light ? 500 : 538)}px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    display: none;
  }
`;
