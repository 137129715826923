import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { Flag } from "../../../ui/Flag/Flag";
import { Modal } from "../../../ui/Modal/Modal";

// export const All
export const RestyledModal = styled(Modal)`
  padding: 16px;

  .md_title {
    margin-top: 4px;
    margin-bottom: 27px;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    .md_title {
      margin-top: 0;
      margin-bottom: 32px;
    }
  }
`;

export const StyledContainer = styled.div`
  margin-top: 24px;
  height: 60vh;
  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    gap: 36px;
  }
`;

export const StyledContinent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledContinentName = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.title};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;

  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    font-size: ${({ theme }) => theme.fontSizes.xl};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    line-height: 120%;
  }
`;

export const ListCountries = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 12px;
    column-gap: 36px;
  }
`;

export const StyledCountry = styled.li`
  min-width: 130px;
`;

export const CountryLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors["neutral-800"]};
    transition: all 0.3s ease;

    .country-name {
      &::after {
        width: 100%;
        transition: width 0.3s ease;
      }
    }
  }
`;

export const LabelCountryFlag = styled(Flag)`
  border-radius: 2px;
`;

export const CountryName = styled.span`
  margin-left: 8px;
  color: ${({ theme }) => theme.colors["neutral-500"]};
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-size: ${({ theme }) => theme.fontSizes.m};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  line-height: 150%;

  overflow: hidden;
  text-overflow: ellipsis;

  &::after {
    content: "";
    position: relative;
    top: -1px;
    width: 0px;
    height: 1px;
    display: block;
    background: ${({ theme }) => theme.colors["neutral-800"]};
    transition: all 0.3s ease;
  }
`;
