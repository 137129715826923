import { startTransition, useCallback, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { getAllCabinet } from "./store/reducers/CabinetReducer/Cabinet.selectors";

import { useDispatchedActions, useLangUrlDefault } from "./hooks";
import "./i18n";
import { ApiService } from "./services";
import { getAllComparison } from "./store/reducers/ComparisonReducer/Comparison.selectors";
import { getAllContent } from "./store/reducers/ContentReducer/Content.selectors";
import { getAllPromocodes } from "./store/reducers/PromocodesReducer/Promocodes.selectors";
import { getAllTranslation } from "./store/reducers/TranslationReducer/Translation.selectors";
import { collectDefaultContent } from "./utils/helpers/serverSideState.helpers";

export const App = ({ provider: RouterProvider, routerProps }) => {
  // **Props
  const [queryLang] = useLangUrlDefault();

  const {
    statistic,
    proxyTypes,
    countries,
    currencies,
    rentPeriods,
    reviews,
    sellers,
    socials,
    captchaConfig,
    mainBlockList,
    seoDataDefault,
    proxyTypesMenu,
    seoDataHtml
  } = useSelector(getAllContent);
  const { alternatePromocodes } = useSelector(getAllPromocodes);
  const { isDataLoaded: comparisonLoaded } = useSelector(getAllComparison);
  const { isDataLoaded: translationLoaded } = useSelector(getAllTranslation);
  const { siteStatuses } = useSelector(getAllCabinet);

  // **Local state
  const [mounted, setMounted] = useState(false);
  const {
    setAuthentication,
    setUser,
    setLanguages,
    getAllCountries,
    getAllCurrencies,
    getAllRentPeriods,
    getComparisonWidgetInfo,
    getAlternatePromocodes,
    getLastSiteReviews,
    getAllTopSellers,
    getStatistic,
    getAllProxies,
    getAllMenuProxies,
    getCaptchaConfig,
    getMainBlockList,
    getAllSocials,
    getSeoDataDefault,
    getAllCabinetSiteStatuses,
    getSeoDataHtml
  } = useDispatchedActions();

  const getLanguages = useCallback(async () => {
    try {
      const response = await ApiService.getAllTranslations();
      if (response && response.status !== 200) {
        throw response;
      }

      setLanguages(
        response.data.map((lang) => ({
          label: lang.languageNameOriginal,
          value: lang.languageCode,
          id: lang.id
        }))
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!mounted) {
      startTransition(() => setMounted(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check for authentication
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const authResponse = await ApiService.isUserAuthenticated();

        if (authResponse && authResponse.status !== 200) {
          throw authResponse;
        }

        if (authResponse.data) {
          const userResponse = await ApiService.getUserAuth();

          if (userResponse && userResponse.status !== 200) {
            throw userResponse;
          }
          startTransition(() => {
            setUser(userResponse.data);
            setAuthentication(authResponse.data);
          });
        } else {
          startTransition(() => setAuthentication(false));
        }
      } catch (err) {
        startTransition(() => setAuthentication(false));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    // if (!isUserAuthenticated) {
    if (mounted) {
      checkAuthentication();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted]);

  //Get initial content for site
  useEffect(() => {
    const fetchData = async () => {
      const requests = [
        !statistic?.isDataLoaded && getStatistic(),
        !proxyTypes?.isDataLoaded && getAllProxies(),
        !proxyTypesMenu?.isDataLoaded && getAllMenuProxies(),
        !countries?.isDataLoaded && getAllCountries(),
        !currencies?.isDataLoaded && getAllCurrencies(),
        !rentPeriods?.isDataLoaded && getAllRentPeriods(),
        !comparisonLoaded && getComparisonWidgetInfo(),
        !alternatePromocodes?.isDataLoaded && getAlternatePromocodes(),
        !reviews?.isDataLoaded && getLastSiteReviews(),
        !sellers?.isDataLoaded && getAllTopSellers(),
        !translationLoaded && getLanguages(),
        !socials?.isDataLoaded && getAllSocials(),
        !captchaConfig?.isDataLoaded && getCaptchaConfig(),
        !mainBlockList?.isDataLoaded && getMainBlockList(),
        !seoDataDefault?.isDataLoaded && getSeoDataDefault(),
        !siteStatuses?.isDataLoaded && getAllCabinetSiteStatuses(),
        !seoDataHtml?.isDataLoaded && getSeoDataHtml()
      ].filter((item) => item);
      // eslint-disable-next-line no-undef
      await Promise.allSettled(requests);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang]);

  return <RouterProvider {...routerProps} />;
};

App.getServerSideState = async (store, params) => {
  await collectDefaultContent(store, params);
};
