import styled from "@emotion/styled";

import { Select } from "../../../../../components/ui/Select/Select";

export const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: ${(p) => (p.noMargin ? "0" : "16px")};
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 200px;
    ${(p) => (p.advantages ? "margin-left: auto;" : "margin-right: auto;")}
  }
`;

export const StyledBoxHidden = styled.div`
  display: ${(p) => (p.hidden ? "none" : "block")};

  textarea {
    min-height: 100px;
  }
`;
