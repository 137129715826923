import { ExportPage } from "../pages/ExportPage/ExportPage.jsx";

export const ExportRoutes = (ssr) => [
  {
    path: "/:lang?/export/",
    tag: "export",
    type: "export",
    component: ssr
      ? ExportPage
      : () => import("../pages/ExportPage/ExportPage.jsx"),
    isAuthRequired: false
  }
];
