import styled from "@emotion/styled";

import { TextButton } from "../../ui/TextButton/TextButton";

export const StyledExpandebleComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding: 24px;
  border-bottom: ${(p) => p.theme.borders.border};
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: calc(100% - 48px);
    top: 0;
    left: 24px;
    height: 1px;
    border-top: 1px dashed #d8dae099;
  }
`;

export const StyledExpandebleComponentContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  gap: 32px;
  align-items: start;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 130%;
  color: ${(p) => p.theme.colors["neutral-800"]};
`;

export const StyledContentTitle = styled.p`
  font-size: ${(p) => (p.sub ? p.theme.fontSizes.s : p.theme.fontSizes.m)};
  font-weight: 600;
  margin-bottom: ${(p) => (p.sub ? "4px" : "16px")};
  ${(p) => (p.sub ? "line-height: 142%;" : "")}
`;

export const StyledContentText = styled.p`
  font-size: ${(p) => p.theme.fontSizes.s};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 130%;
  color: ${(p) => p.theme.colors["neutral-500"]};
`;

export const StyledContentList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${(p) => (p.type === "characteristics" ? "16px" : "0px")};
  list-style: none;
  padding: 0;
`;

export const StyledContentListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 5px 0;

  & svg {
    color: #c5cdd9;
    margin-right: 8px;
  }
`;

export const StyledCirkle = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #c5cdd9;
  margin-right: 8px;
`;

export const StyledOKIcon = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M10 3L4.5 8.5L2 6" stroke="%23C5CDD9" stroke-width="1.5" stroke-linecap="square"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 8px;
`;

export const StyledTextButton = styled(TextButton)`
  & > span {
    &::after {
      width: 100% !important;
    }
  }
`;
