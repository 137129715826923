import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";
import { Title } from "../../../../components/common/Languages/Languages.styled";
import { Button } from "../../../../components/ui/Button/Button";
import { StyledLoading } from "../../../../components/ui/Button/Button.styled";
import { Flag } from "../../../../components/ui/Flag/Flag";

export const StyledContainer = styled(Container)`
  position: relative;
  z-index: 2;
  margin-top: -42px !important;
  padding-bottom: 64px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-top: -175px !important;
    padding-bottom: 0 !important;
  }
`;

export const StyledInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors["background-color"]};
  border-radius: 12px;
  box-shadow: 0px 5px 28px 0px rgba(135, 140, 189, 0.08);
  padding: 0;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 36px;
    background: white;
    margin-top: -140px;
    flex-direction: row;
    min-height: 690px;
  }
`;

export const StyledForm = styled.div`
  background: ${({ theme }) => theme.colors["background-color"]};
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 47%;
    max-width: 360px;
    min-width: 360px;
    margin-right: 36px;
    margin-left: 0;
    margin-bottom: 0;
    padding: 24px;
  }
`;

export const StyledFormWrapper = styled.div`
  padding: 16px;
  background-color: white;
  margin-bottom: 24px;
  border-radius: 12px;
  box-shadow: 0px 5px 28px 0px rgba(135, 140, 189, 0.08);

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 0;
    margin-bottom: 0;
    border-radius: 0;
    box-shadow: none;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  margin-bottom: 24px;
`;

export const StyledImageWrapper = styled.div`
  min-height: 220px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    min-height: 237px;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 1.21 !important;
  margin-bottom: 16px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    font-size: 32px !important;
    line-height: 1.18 !important;
    margin-bottom: 24px !important;
  }
`;

export const StyledInput = styled.div`
  margin-bottom: 16px;
`;

export const StyledButton = styled(Button)`
  margin-bottom: 12px;
`;

export const StyledInfoMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors["neutral-500"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 139%;

  img {
    margin-right: 12px;
  }
`;

export const RestyledLoading = styled(StyledLoading)`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  color: ${(p) => p.theme.colors["neutral-400"]};
`;

export const StyledAdditionalText = styled.button`
  display: flex;
  align-items: center;

  & span {
    font-family: ${(p) => p.theme.fontFamily.lato};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 139%;
  }
`;

export const StyledProgressWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-top: 0;
  }
`;

export const StyledResults = styled.div`
  width: 100%;
  border-radius: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    height: ${(p) => (p.isLoading ? "520px" : "490px")};
    width: 100%;
    border-radius: 12px;
    display: ${(p) => (p.isLoading ? "block" : "flex")};
    justify-content: center;
    align-items: center;
  }
`;

export const StyledListWrapper = styled.div`
  width: 100%;
`;

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
`;

export const StyledAnalyzingWrapper = styled.div`
  display: ${(p) => (p.variant === "mobile" ? "block" : "none")};
  margin-bottom: 16px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: ${(p) => (p.variant === "mobile" ? "none" : "block")};
    height: 100%;
    width: 100%;
    margin-bottom: 0;
  }

  & h2 {
    margin-bottom: 12px;
  }
`;

export const StyledGradient = styled.div`
  position: absolute;
  height: 66px;
  display: none;
  background: linear-gradient(0deg, #fff 23.68%, rgba(255, 255, 255, 0) 63.16%);
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: block;
    bottom: 0;
    width: 100%;
  }
`;

export const StyledFlag = styled(Flag)`
  margin-right: 8px;
`;
