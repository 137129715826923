import * as yup from "yup";

import { EMAIL_REGEX, EMAIL_STAGE_REGEX } from "../constants";

export const CommentSchema = (trns, isAuth) =>
  yup.object().shape({
    ...(!isAuth
      ? {
          name: yup.string().required(trns.field.rules.required),
          email: yup
            .string()
            .trim()
            .required(trns.email.rules.required)
            .matches(
              process.env?.REACT_APP_MODE === "prod"
                ? EMAIL_REGEX
                : EMAIL_STAGE_REGEX,
              trns.email.rules.email
            )
        }
      : {}),
    body: yup.string().required(trns.field.rules.required)
  });
