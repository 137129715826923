import { useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormSwitch } from "../../../../../components/forms/FormSwitch/FormSwitch";
import { FormBlock } from "../../../components/FormBlock/FormBlock";

import { StyledDesctiption } from "./BlockActive.styled";

export const BlockActive = () => {
  const { t } = useTranslation();
  const { watch, setValue, getValues } = useFormContext();
  const [disabled, setDisabled] = useState(false);
  const date = watch("dateRange");
  const eternal = watch("eternal");
  useEffect(() => {
    if (eternal === "eternal") {
      setDisabled(false);
      return;
    }
    if (date && eternal === "date") {
      const [, end] = date;
      if (end && end < new Date()) {
        if (getValues("isActive")) {
          setValue("isActive", false);
        }
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, eternal]);

  return (
    <FormBlock>
      <FormSwitch
        name={"isActive"}
        label={t("promocodes.isActive.label")}
        showError
        disabled={disabled}
      />
      <StyledDesctiption>
        {t("dashboard.promocodes.addEdit.blocks.active.description")}
      </StyledDesctiption>
    </FormBlock>
  );
};
