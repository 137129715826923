import styled from "@emotion/styled";

export const ListMobile = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 12px;
  margin-bottom: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none;
  }
`;

export const ListMobileItem = styled.li`
position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  &:not(:last-child) {
  
  &::after {
  position: absolute;
  bottom: -6px;
  left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: ${(p) => p.theme.colors["neutral-50"]};
  }
  `;

export const ListMobileBlock = styled.div`
  display: flex;
  flex-direction: column;

  font-family: ${(p) => p.theme.fontFamily.lato};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
`;

export const ItemLabel = styled.span`
  display: inline-block;
  color: ${(p) => p.theme.colors["neutral-400"]};
  font-size: ${(p) => p.theme.fontSizes.s};
  line-height: 143%;
  text-transform: uppercase;
  margin-bottom: 4px;
`;

export const ItemValue = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-size: ${(p) => p.theme.fontSizes.m};
  line-height: 125%;
`;

export const Status = styled.span`
  display: flex;
  align-items: center;

  color: ${(p) =>
    p.webRtcStatus ? p.theme.colors["red-500"] : p.theme.colors["main-700"]};
`;
