import { useTranslation } from "react-i18next";

import { FormStars } from "../../../../forms/FormStars/FormStars";
import { LabelStyled, RatingBox } from "../LeaveReviewModal.styled";

export const Ratings = () => {
  const { t } = useTranslation();
  return (
    <RatingBox>
      <LabelStyled noMargin>
        {t("modals.review.form.ratings.title")}
      </LabelStyled>
      <FormStars
        name="priceRating"
        label={t("modals.review.form.ratings.price")}
        defaultValue={0}
        tooltipPrefixText={t("modals.review.form.ratings.rating")}
      />
      <FormStars
        name="speedRating"
        label={t("modals.review.form.ratings.speed")}
        defaultValue={0}
        tooltipPrefixText={t("modals.review.form.ratings.rating")}
      />
      <FormStars
        name="reliabilityRating"
        label={t("modals.review.form.ratings.reliability")}
        defaultValue={0}
        tooltipPrefixText={t("modals.review.form.ratings.rating")}
      />
      <FormStars
        name="supportRating"
        label={t("modals.review.form.ratings.support")}
        defaultValue={0}
        tooltipPrefixText={t("modals.review.form.ratings.rating")}
      />
    </RatingBox>
  );
};
