import PT from "prop-types";

import { Icon } from "../../ui/Icon/Icon";

import { ErrorCounter } from "./FormError.styled";

export const FormError = ({ message, className = "" }) => (
  <ErrorCounter className={className}>
    <Icon name="inputError" />
    {message}
  </ErrorCounter>
);

FormError.propTypes = {
  message: PT.string.isRequired
};
