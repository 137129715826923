import * as yup from "yup";

import { URL_WITHOUT_HTTPS } from "../constants";

export const HttpHeadersCheckSchema = (trns) =>
  yup.object().shape({
    url: yup
      .string()
      .required(trns.form.rules.required)
      .matches(URL_WITHOUT_HTTPS, trns.form.rules.url)
  });
