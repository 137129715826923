import { createSlice } from "@reduxjs/toolkit";

import { ToolsInitialState } from "./Tools.initialState";
import { setAnonymityReducer } from "./Tools.reducer";
import { fetchAnonymity, setAnonymity } from "./Tools.thunks";

const ToolsSlice = createSlice({
  name: "tools",
  initialState: ToolsInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(setAnonymity, setAnonymityReducer)

      // fetchSeoBlocks
      .addCase(fetchAnonymity.pending, (state) => {
        state.anonymity.loading = true;
        state.anonymity.errors = null;
      })
      .addCase(fetchAnonymity.fulfilled, (state, action) => {
        state.anonymity.data = action.payload;
        state.anonymity.loading = false;
        state.anonymity.isLoaded = true;
        state.anonymity.errors = null;
      })
      .addCase(fetchAnonymity.rejected, (state, action) => {
        state.anonymity.loading = false;
        state.anonymity.errors = action.payload;
      })
});

export const ToolsReducerActions = {
  setAnonymity,
  fetchAnonymity
};

export default ToolsSlice.reducer;
