import styled from "@emotion/styled";

import { Icon } from "../Icon/Icon";

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLabel = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    & *[class*="checkbox"] {
      color: ${(props) => props.theme.colors["main-600"]};
    }

    & input:checked + *[class*="checkbox"] {
      color: #79b22f;
    }
  }
`;

export const StyledCheckboxInput = styled.input`
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
`;

export const StyledCheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors["neutral-500"]};
  font-family: var(--font-family-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
`;

export const StyledCheckboxIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: ${(props) =>
    props.checked ? props.theme.colors["main-600"] : "#d8dae0"};
`;
