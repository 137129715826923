import { useTranslation } from "react-i18next";

import animation from "../../../assets/animations/port_scanner.riv";
import {
  fingerprintFaqImage,
  fingerprintFaqImage2x,
  portScannerHeroImageMobile,
  portScannerHeroImageMobile2x
} from "../../../assets/img";
import { SeoSection } from "../../../components/common/SeoSection/SeoSection";
import { Rive } from "../../../components/ui/Rive/Rive";
import { $api } from "../../../services/AxiosInstance";
import { AllActions } from "../../../store/reducers/AllActions";

import { PortScanner } from "./PortScanner/PortScanner";
import {
  RestyledMainSection,
  StyledFAQSection
} from "./PortScannerPage.styled";

const PortScannerPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <RestyledMainSection
        button={false}
        images={{
          mobile1x: portScannerHeroImageMobile,
          mobile2x: portScannerHeroImageMobile2x
        }}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-123}
            right={-56}
            bottom={-173}
            left={-56}
          />
        }
      />
      <PortScanner />
      <StyledFAQSection
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        tools
      />
      <SeoSection />
    </>
  );
};

export default PortScannerPage;

PortScannerPage.getServerSideState = async (store, params) => {
  const { data } = await $api.post("/api/front/faqs", {
    pageTag: params.tag,
    languageCode: params.locale,
    params: params.params
  });

  const fetchSeoBlockParams = {
    languageCode: params.locale,
    keys: [
      "toolsPortScanerWhatIsIt",
      "toolsPortScanerWhichPorts",
      "toolsPortScanerHowToUse"
    ]
  };
  const { data: seoBlock } = await $api.post(
    "/api/front/seoBlock",
    fetchSeoBlockParams
  );
  if (seoBlock) {
    store.dispatch(
      AllActions.setSeoBlock({ data: seoBlock, params: fetchSeoBlockParams })
    );
  }

  if (data) {
    store.dispatch(
      AllActions.setFaqContent({
        data,
        pageTag: params.tag,
        languageCode: params.locale
      })
    );
  }
};
