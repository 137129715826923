import { makeWordHighlight } from "../../../../utils/helpers/text.helper";

import { StarTitle, Title } from "./HighlightedTitle.styled";

export const HighlightedTitle = ({ heading, highlightWord, star }) => {
  const Component = star ? StarTitle : Title;

  return (
    <Component
      dangerouslySetInnerHTML={{
        __html: makeWordHighlight(heading, highlightWord)
      }}
    />
  );
};
