import { useEffect } from "react";

import { ExportPage } from "../pages/ExportPage/ExportPage";

export const ExportLayout = () => {
  useEffect(() => {
    const html = document.querySelector("html");
    html.setAttribute("new-layout", "true");
    return () => {
      html.removeAttribute("new-layout");
    };
  }, []);
  return <ExportPage />;
};
