// import { startTransition, useEffect, useState } from "react";

/* eslint-disable import/namespace */
import { Helmet } from "react-helmet-async";
// import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useLangUrlDefault, useMatchedRoute } from "../../hooks";
import { getAllBlogInner } from "../../store/reducers/BlogInnerReducer/BlogInner.selectors";
import { getAllContent } from "../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getSiteDescription } from "../../utils/helpers";
import { generateMetaTags } from "../../utils/helpers/generateMetaTags.helper";

const BLOG_INNER_TAG = "bloginner";
const PROXY_SITE_TAG = "proxy-site";

export const MetaPage = () => {
  // **Props
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  // const metaData = useMetaData();
  const route = useMatchedRoute();

  // **Redux state
  const {
    pageContent: { current },
    seoDataHtml
  } = useSelector(getAllContent);
  const { routeContent } = useSelector(getAllContent);
  const { site } = useSelector(getAllProxySite);
  const { content } = useSelector(getAllBlogInner);

  const imageUrl = current?.data?.imageUrl;
  const homeURL = process.env.REACT_APP_URL;

  // const [title, setTitle] = useState(current?.data?.title || "");
  // const [description, setDescription] = useState(
  //   current?.data?.description || ""
  // );

  // useEffect(() => {
  //   if (current?.data?.title) {
  //     startTransition(() => {
  //       setTitle(current?.data?.title);
  //     });
  //   }

  //   if (
  //     route?.tag === PROXY_SITE_TAG &&
  //     site.data?.description &&
  //     getSiteDescription(site.data?.description, queryLang, false)
  //   ) {
  //     startTransition(() => {
  //       setDescription(
  //         getSiteDescription(site.data?.description, queryLang, false)
  //       );
  //     });
  //   } else if (current?.data?.description) {
  //     startTransition(() => {
  //       setDescription(current?.data?.description);
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [current?.data?.title, site.data?.description, queryLang]);

  const getCurrentTitle = () => {
    if (current?.data?.title?.length > 0) {
      return current?.data?.title;
    }
    if (route?.tag === BLOG_INNER_TAG && content?.data?.title?.length > 0) {
      return content?.data?.title;
    }
    return t("metaPage.title");
  };

  const getCurrentDescription = () => {
    if (
      route?.tag === PROXY_SITE_TAG &&
      site.data?.description &&
      getSiteDescription(site.data?.description, queryLang, false)
    ) {
      return getSiteDescription(site.data?.description, queryLang, false);
    }
    if (current?.data?.description?.length > 0) {
      return current?.data?.description;
    }
    if (
      route?.tag === BLOG_INNER_TAG &&
      content?.data?.description?.length > 0
    ) {
      return content?.data?.description;
    }
    return t("metaPage.description");
  };
  // const isTitle = Boolean(title);
  // const isDescription = Boolean(description);
  const currentTitle = getCurrentTitle();
  const currentDescription = getCurrentDescription();

  const isImageUrl = Boolean(imageUrl);
  // let currentTitle = title || t("metaPage.title");
  // let currentDescription = description || t("metaPage.description");
  // if (route?.tag === BLOG_INNER_TAG) {
  //   currentTitle = content?.data?.title;
  // } else if (isTitle) {
  //   currentTitle = title;
  // } else {
  //   currentTitle = t("metaPage.title");
  // }

  // if (tag === BLOG_INNER_TAG) {
  //   currentDescription = content?.data?.description;
  // } else if (isDescription) {
  //   currentDescription = description;
  // } else {
  //   currentDescription = t("metaPage.description");
  // }

  const nextYearValue = () => {
    const today = new Date();

    const nextYear = new Date(today);
    nextYear.setFullYear(today.getFullYear() + 1);

    return nextYear.toISOString().split("T")[0];
  };

  const randomMinPrice = Math.random().toFixed(1);

  const randomRating = (Math.random() + 4).toFixed(1);

  return (
    <Helmet>
      {generateMetaTags(
        currentTitle,
        routeContent?.tag === PROXY_SITE_TAG && currentDescription.length > 160
          ? `${currentDescription.slice(0, 160)}...`
          : currentDescription,
        queryLang,
        false,
        isImageUrl ? imageUrl : false
      )}
      {routeContent?.tag === "proxy" && (
        <script type="application/ld+json">
          {`
                {
                "@context":"http://schema.org",
                "@type":"Product",
                "image": [
                  "${homeURL}/img/meta_logo.jpg"
                ],
                "name": "Proxy servers",
                "description": "More than 100 proxies of services that will provide stable work on social networks, SEO and marketing, rates and online games",
                "brand": {
                  "@type": "Brand",
                  "name": "Trusty Tech"
                },
                "sku": "0000000008",
                "mpn": "000008",
                "aggregateRating":{
                  "@type":"AggregateRating",
                  "ratingValue": ${randomRating},
                  "reviewCount": 35,
                  "bestRating": 5
                },
                "review": {
                  "@type": "Review",
                  "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": ${randomRating},
                    "bestRating": "5"
                  },
                  "author": {
                    "@type": "Person",
                    "name": "Alice"
                  }
                },
                "offers": {
                  "@type": "Offer",
                  "url": "${homeURL}${routeContent?.location}",
                  "priceCurrency": "USD",
                  "price": "${randomMinPrice}",
                  "priceValidUntil": "${nextYearValue()}",
                  "availability": "https://schema.org/InStock",
                  "itemCondition": "https://schema.org/NewCondition"
                }
              }
            `}
        </script>
      )}
      {routeContent?.tag === PROXY_SITE_TAG && (
        <script type="application/ld+json">
          {`
                {
                "@context":"http://schema.org",
                "@type":"Product",
                "image": [
                  "${homeURL}${site?.data?.imageFile}"
                ],
                "name": "${site.data?.name}",
                "description": "${site.data?.description?.[queryLang]}",
                "aggregateRating":{
                  "@type":"AggregateRating",
                  "ratingValue": ${site.data?.rating},
                  "reviewCount": ${
                    site?.data?.reviewRatingNegative +
                    site?.data?.reviewRatingPositive
                  },
                  "bestRating": 5
                },
                "offers": {
                  "@type": "Offer",
                  "url": "${homeURL}${routeContent?.location}",
                  "priceCurrency": "USD",
                  "price": "${site.data?.price}",
                  "priceValidUntil": "${nextYearValue()}",
                  "availability": "https://schema.org/InStock",
                  "itemCondition": "https://schema.org/NewCondition"
                }
              }
            `}
        </script>
      )}
      {seoDataHtml.data && (
        <script type="application/ld+json">{`${seoDataHtml.data}`}</script>
      )}
    </Helmet>
  );
};
