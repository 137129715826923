import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ReadMore } from "../../../../components/ui/ReadMore/ReadMore";
import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import { getAllBlogInner } from "../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";

import {
  StyledContentWrapper,
  StyledDescription,
  StyledIcon,
  StyledImage,
  StyledList,
  StyledListItem,
  StyledListLink,
  StyledNavigation,
  StyledPreview,
  StyledPreviewTitle,
  StyledPreviewTitleWrapper,
  StyledTitle
} from "./BlogNavItem.styled";

export const BlogNavItem = () => {
  const [, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();
  // **Dispatch
  const { setBurgerMenu, setServiceMenu } = useDispatchedActions();

  const { sidebar } = useSelector(getAllBlogInner);

  const closeMenu = () => {
    setBurgerMenu(false);
    setServiceMenu(false);
  };

  const listMemo = useMemo(
    () => (
      <StyledList>
        {sidebar.data?.latest?.slice(0, 2)?.map((el) => (
          <StyledListItem key={el.title}>
            <StyledListLink
              to={`${hrefLang}/blog/${el.alias}`}
              onClick={closeMenu}
            >
              {el?.title}
              <StyledIcon name="arrowAltRight" />
            </StyledListLink>
          </StyledListItem>
        ))}
      </StyledList>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sidebar?.data?.latest]
  );

  return (
    <StyledNavigation>
      <StyledTitle>{t("navigation.interestingRead")}</StyledTitle>
      <StyledPreview>
        <StyledImage>
          <source
            srcSet={
              sidebar.data?.popular[0]?.coverImage ||
              "/img/ui/blog-placeholder.svg"
            }
          />
          <img
            src={sidebar.data?.popular[0]?.coverImage}
            alt={sidebar.data?.popular[0]?.title}
            loading="lazy"
          />
        </StyledImage>
        <StyledListLink
          to={`${hrefLang}/blog/${sidebar.data?.popular[0]?.alias}`}
          onClick={closeMenu}
        >
          <StyledContentWrapper>
            <StyledPreviewTitleWrapper>
              <StyledPreviewTitle>
                {sidebar.data?.popular[0]?.title}
              </StyledPreviewTitle>
              <StyledDescription>
                {sidebar.data?.popular[0]?.description}
              </StyledDescription>
            </StyledPreviewTitleWrapper>
            <ReadMore variant="light" />
          </StyledContentWrapper>
        </StyledListLink>
      </StyledPreview>
      {listMemo}
    </StyledNavigation>
  );
};
