import styled from "@emotion/styled";

import { FormSelect } from "../../../../../components/forms/FormSelect/FormSelect";

export const FormFilter = styled.form`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 24px;
  gap: 24px;
  background: #2e3644;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: flex-start;
    border-radius: 12px;
    padding: 36px;
    gap: 36px;
  }
`;

export const RestyledFormSelect = styled(FormSelect)`
  gap: 12px;
  margin: 0 !important;
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 144px;
  min-width: 144px;
  gap: 12px;
`;

export const RadioGroupBox = styled.div`
  display: flex;
  align-items: center;
  min-height: 20px;
  gap: 24px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    min-height: 48px;
  }

  & label[data-checked="true"],
  & label[data-checked="false"] {
    font-family: ${({ theme }) => theme.fontFamily.lato};
    font-size: ${({ theme }) => theme.fontSizes.m};
    font-style: ${({ theme }) => theme.fontStyle.normal};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    line-height: 125%;
    transition: all 0.1s ease-out;
    cursor: pointer;
  }

  & label[data-checked="false"] {
    color: #ffffffb3;
    transition: all 0.1s ease-out;
  }

  & label[data-checked="true"] {
    color: ${({ theme }) => theme.colors.white};
    transition: all 0.1s ease-out;
  }
`;

export const FormLabel = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 140%;
  text-transform: uppercase;
`;
