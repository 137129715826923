import { Route } from "react-router-dom";

export const renderRoutes = (routes) =>
  routes.map((route) => (
    <Route
      key={route.path}
      path={route.path}
      hydrateFallbackElement={null}
      lazy={async () => {
        if (route.isNavigate) {
          return {
            element: route.element
          };
        }
        const { default: Component } = await route.component();

        return {
          element: <Component tag={route.tag} />
        };
      }}
    />
  ));
