import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getAllContent } from "../store/reducers/ContentReducer/Content.selectors";
import { getAllSeoBlock } from "../store/reducers/SeoBlockReducer/SeoBlock.selectors";
import { currentSeoBlockTitle } from "../utils/helpers";

import { useDispatchedActions } from "./useDispatchedActions";
import { useLangUrlDefault } from "./useLangUrlDefault";

export const useSeoBlock = (keys) => {
  const [searchParams] = useSearchParams();
  const [queryLang] = useLangUrlDefault();
  const { fetchSeoBlocks } = useDispatchedActions();
  const { loading, errors, data, loadingKeys } = useSelector(getAllSeoBlock);

  // temp logick for replase data
  const { proxyTypes, countries } = useSelector(getAllContent);
  const activeType = searchParams.get("fpt") || "IPv4";
  const activeCountry =
    searchParams.get("fc") && activeType === "IPv4"
      ? searchParams.get("fc")
      : null;
  const country = activeCountry
    ? countries?.data?.find((country) => country.urlParam === activeCountry)
    : null;
  const countryNameGen = country
    ? country?.caseLocalization?.[queryLang]?.gen
    : null;
  const proxyType = proxyTypes.data?.[queryLang]?.find(
    (item) => item.type === activeType
  );
  // end temp logick

  useEffect(() => {
    const alreadyInProcess = keys.some((key) => loadingKeys.includes(key));

    if (!alreadyInProcess) {
      const notExistKeys = keys.filter(
        (key) => !data[queryLang]?.hasOwnProperty(key)
      );

      if (notExistKeys?.length) {
        fetchSeoBlocks({ keys: notExistKeys, languageCode: queryLang });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keys, queryLang, data, loadingKeys]);

  const searchedKeys = keys.reduce((acc, key) => {
    if (!data[queryLang] || !data[queryLang].hasOwnProperty(key)) {
      return acc;
    }
    // temp logick for replase data
    const keyData = data[queryLang][key];
    const changedData = Object.keys(keyData).reduce((acc, key) => {
      if (key === "showOnFront") {
        return { ...acc, [key]: keyData[key] };
      }
      const { content } = keyData[key];
      return {
        ...acc,
        [key]: {
          ...keyData[key],
          content: currentSeoBlockTitle(
            content,
            proxyType?.name?.replace(/proxy|прокси|проксі/gi, "").trim(),
            countryNameGen
          )
        }
      };
    }, {});

    return {
      ...acc,
      [key]: changedData
    };
    // end temp logick
    // return {
    //   ...acc,
    //   [key]: data[queryLang][key]
    // };
  }, {});

  return {
    loading,
    data: searchedKeys,
    errors
  };
};
