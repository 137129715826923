/* eslint-disable import/order */
import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Button } from "../../../../components/ui/Button/Button";
import { Separator } from "../../../../components/ui/Separator/Separator";
import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import { ApiService } from "../../../../services";
import { getAllCabinet } from "../../../../store/reducers/CabinetReducer/Cabinet.selectors";
import { getAllTranslation } from "../../../../store/reducers/TranslationReducer/Translation.selectors";
import { PromocodeSchema } from "../../../../utils/validation";
import { FormBlock } from "../../components/FormBlock/FormBlock";
import { StyledForm } from "../AddEditPromocode.styled";

import { BlockActive } from "./BlockActive/BlockActive";
import { BlockDescriptions } from "./BlockDescriptions/BlockDescriptions";
import { BlockPeriod } from "./BlockPeriod/BlockPeriod";
import { BlockPromocode } from "./BlockPromocode";
import { BlockSites } from "./BlockSites";

export const Form = ({ defaultValues, isEdit }) => {
  const [queryLang, hrefLang] = useLangUrlDefault();
  const navigate = useNavigate();

  // **Redux state
  const { languages } = useSelector(getAllTranslation);
  const { promocodes: cabinetPromocodes } = useSelector(getAllCabinet);

  const { t } = useTranslation();

  // **Local state
  const [isLoading, setIsLoading] = useState(false);

  // **Dispatch
  const { getCabinetPromocodes } = useDispatchedActions();

  // Form
  const methods = useForm({
    resolver: yupResolver(
      PromocodeSchema(
        t("forms", {
          returnObjects: true
        }),
        languages
      )
    ),
    defaultValues
  });

  const { eternal } = methods.watch();
  const onSubmit = async (data) => {
    const params = {
      id: isEdit ? defaultValues.id : null,
      siteId: data.siteId,
      siteName: isEdit ? data.siteName : null,
      siteAlias: isEdit ? data.siteAlias : null,
      promocode: data.promocode,
      startDate: data.dateRange?.[0],
      endDate: data.dateRange?.[1],
      eternal: data.eternal === "eternal",
      isActive: data.isActive,
      imageUrl: isEdit ? data.imageUrl : null,
      description: data.description,
      showIn: isEdit ? data.showIn : null,
      recommendedPromo: isEdit ? data.recommendedPromo : null
    };
    try {
      setIsLoading(true);

      const promocodeResponse = !isEdit
        ? await ApiService.createPromocode(params)
        : await ApiService.updatePromocode(params);

      // Handling error for "promocodeResponse" query
      if (promocodeResponse && promocodeResponse.status !== 200) {
        throw promocodeResponse;
      }

      if (!promocodeResponse.data) {
        toast.error(t("notifications.promocode.duplicate"));
        return;
      }

      // Show success message that site has been created / updated
      toast.success(
        isEdit
          ? t("notifications.promocode.edited")
          : t("notifications.promocode.created")
      );

      // Getting all promocodes
      getCabinetPromocodes(cabinetPromocodes.prevParams);

      // Check if file is added, if no go the main cabinet page
      navigate(`${hrefLang}/dashboard/promocodes/`);
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    methods.reset({
      ...defaultValues,
      dateRange: [defaultValues.startDate, defaultValues.endDate]
    });
    navigate(`${hrefLang}/dashboard/promocodes/`);
  };

  useEffect(() => {
    if (defaultValues) {
      methods.reset({
        ...defaultValues,
        dateRange: [defaultValues.startDate, defaultValues.endDate]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  useEffect(() => {
    if (methods && Object.keys(methods.formState.errors)?.length > 0) {
      methods.trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang]);

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={methods.handleSubmit(onSubmit)}>
        <BlockSites />
        <Separator />

        <BlockPromocode />
        <Separator />

        <BlockDescriptions />
        <Separator />

        <BlockPeriod eternal={eternal} />
        <Separator />

        <BlockActive />
        <Separator />

        <FormBlock bodyType={"buttons"}>
          <Button loading={isLoading} type="submit">
            {t(`dashboard.promocodes.addEdit.${isEdit ? "edit" : "add"}`)}
          </Button>
          <Button
            disabled={isLoading}
            variant={"secondary"}
            onClick={resetForm}
          >
            {t("forms.buttons.cancel")}
          </Button>
        </FormBlock>
      </StyledForm>
    </FormProvider>
  );
};
