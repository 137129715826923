import { useState } from "react";

import { matchPath, useLocation } from "react-router-dom";

// import {
//   AppRoutes,
//   AuthRoutes,
//   DashboardRoutes,
//   ExportRoutes,
//   ToolsRoutes
// } from "../routes";
import { AppRoutes } from "../routes/app.routes";
import { AuthRoutes } from "../routes/auth.routes";
import { DashboardRoutes } from "../routes/dashboard.routes";
import { ExportRoutes } from "../routes/export.routes";
import { ToolsRoutes } from "../routes/tools.routes";
import { getCurrentRoutes } from "../utils/helpers";

export const useMatchedRoute = () => {
  const { pathname } = useLocation();
  const allRoutes = [
    ...AppRoutes(),
    ...AuthRoutes(),
    ...DashboardRoutes(),
    ...ToolsRoutes(),
    ...ExportRoutes()
  ];
  const [routes] = useState(
    getCurrentRoutes(allRoutes, process.env.REACT_APP_MODE === "dev").map(
      (route) => ({ ...route, component: null, element: null })
    )
  );

  const splittedPathname = pathname.split("/").filter(Boolean);
  if (
    splittedPathname?.length > 0 &&
    !["ru", "ua"].includes(splittedPathname[0])
  ) {
    return routes.find((route) =>
      matchPath({ path: route?.path.replace("/:lang?", "") }, pathname)
    );
  }

  return routes.find((route) => matchPath({ path: route?.path }, pathname));
};
