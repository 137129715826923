/* eslint-disable no-undef */
import { useEffect, useRef, useState } from "react";

import PT from "prop-types";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

import { calcRotate } from "../../../utils/helpers/calcRotate.helpers";

import {
  StyledProgressBar,
  StyledProgressCircleImg,
  StyledProgressText
} from "./Progress.styled";

export const Progress = ({
  isLoading = false,
  fraction = 2,
  interval = 5,
  infinity = false
}) => {
  const timeoutID = useRef(null);

  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (isLoading) {
      timeoutID.current = setTimeout(() => {
        if (percent >= 100 && infinity) {
          setPercent(0);
          return;
        } else if (percent >= 100) {
          clearTimeout(timeoutID.current);
          timeoutID.current = null;
          return;
        }
        setPercent((value) => value + fraction);
      }, interval);
    } else {
      setPercent(100);
      clearTimeout(timeoutID.current);
      timeoutID.current = null;
    }

    return () => clearTimeout(timeoutID.current);
  }, [percent, isLoading, fraction, interval, infinity]);

  return (
    <StyledProgressBar>
      <StyledProgressCircleImg
        src="img/icons/circleIcon.svg"
        style={{
          transform: `rotate(${calcRotate(percent)}deg) translateX(78.5px)`,
          transition: percent === 0 ? "none" : "all 0.5s ease 0s"
        }}
      />
      <CircularProgressbarWithChildren
        value={percent}
        counterClockwise={true}
        styles={{
          path: {
            // Path color
            stroke: "#8FC846",
            strokeWidth: "1px",
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "butt",
            // Customize transition animation
            transition:
              percent === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s",
            transformOrigin: "center center"
          },
          trail: {
            // Trail color
            stroke: "transparent"
          }
        }}
      >
        <StyledProgressText>
          {percent}
          {/* <span className={styles.progress_bar__percent}>%</span> */}
        </StyledProgressText>
      </CircularProgressbarWithChildren>
    </StyledProgressBar>
  );
};

Progress.propTypes = {
  isLoading: PT.bool,
  fraction: PT.number,
  interval: PT.number,
  infinity: PT.bool
};
