// Actions
import { BlogInnerReducerActions } from "./BlogInnerReducer/BlogInner.slice";
import { BlogReducerActions } from "./BlogReducer/Blog.slice";
import { CabinetReducerActions } from "./CabinetReducer/Cabinet.slice";
import { ComparisonReducerActions } from "./ComparisonReducer/Comparison.slice";
import { ContentReducerActions } from "./ContentReducer/Content.slice";
import { FAQReducerActions } from "./FAQReducer/FAQ.slice";
import { FreeProxyReducerActions } from "./FreeProxyReducer/FreeProxy.slice";
import { PromocodesReducerActions } from "./PromocodesReducer/Promocodes.slice";
import { ProxySiteReducerActions } from "./ProxySiteReducer/ProxySite.slice";
import { SeoBlockReducerActions } from "./SeoBlockReducer/SeoBlock.slice";
import { SiteReducerActions } from "./SiteReducer/Site.slice";
import { ToolsReducerActions } from "./ToolsReducer/Tools.slice";
import { TranslationReducerActions } from "./TranslationReducer/Translation.slice";
import { UserReducerActions } from "./UserReducer/User.slice";

export const AllActions = {
  ...ContentReducerActions,
  ...TranslationReducerActions,
  ...SiteReducerActions,
  ...BlogReducerActions,
  ...BlogInnerReducerActions,
  ...ProxySiteReducerActions,
  ...UserReducerActions,
  ...CabinetReducerActions,
  ...ComparisonReducerActions,
  ...PromocodesReducerActions,
  ...FreeProxyReducerActions,
  ...SeoBlockReducerActions,
  ...FAQReducerActions,
  ...ToolsReducerActions
};
