import PT from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useDispatchedActions, useLangUrlDefault } from "../../../hooks";
import { getAllUser } from "../../../store/reducers/UserReducer/User.selectors";

import { StyledButton } from "./AuthButton.styled";

export const AuthButton = ({ variant = "dark" }) => {
  const [, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();

  // **Redux state
  const { isUserAuthenticated, user } = useSelector(getAllUser);

  // **Dispatch
  const { setMobileMenu, setServiceMenu, setBurgerMenu } =
    useDispatchedActions();

  const closeMenu = () => {
    setMobileMenu(false);
    setServiceMenu(false);
    setBurgerMenu(false);
  };

  if (isUserAuthenticated) {
    return (
      <StyledButton
        variant="outlined"
        color={variant}
        linkTo={`${hrefLang}/dashboard/profile/`}
        onClick={closeMenu}
      >
        {user.email}
      </StyledButton>
    );
  }

  return (
    <StyledButton
      variant="outlined"
      color={variant}
      linkTo={`${hrefLang}/login/`}
      onClick={closeMenu}
      iconRight="logIn"
    >
      {t("navigation.logIn")}
    </StyledButton>
  );
};

AuthButton.propTypes = {
  variant: PT.oneOf(["light", "dark"])
};
