import { createAction } from "@reduxjs/toolkit";

export const setMobileMenu = createAction("site/setMobileMenu");
export const setBurgerMenu = createAction("site/setBurgerMenu");
export const setServiceMenu = createAction("site/setServiceMenu");
export const setPreviousLang = createAction("site/setPreviousLang");
export const setServicesOpened = createAction("site/setServicesOpened");
export const setLocationOpened = createAction("site/setLocationOpened");
export const setServicesMenuStep = createAction("content/setServicesMenuStep");
export const setSelectedLocation = createAction("content/setSelectedLocation");
export const setSearchCountry = createAction("content/setSearchCountry");
