import { Select } from "../../../../components/ui/Select/Select";

import { StyledSortBox, StyledSortLabel } from "./SortBlock.styled";

export const SortBlock = ({
  defaultValue,
  value,
  label,
  options,
  changeSortType
}) => (
  <StyledSortBox>
    {label ? <StyledSortLabel>{label}</StyledSortLabel> : null}
    <Select
      defaultValue={defaultValue}
      value={value}
      onChange={changeSortType}
      options={options}
      size="md"
    />
  </StyledSortBox>
);
