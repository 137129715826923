import styled from "@emotion/styled";

export const StyledAnnotationContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.xxs};
  font-weight: 600;
  line-height: 167%;
  text-align: left;
  & > img {
    margin-right: 4px;
  }
`;

export const StyledAnnotationPositive = styled.span`
  color: ${(p) =>
    p.default ? p.theme.colors["neutral-300"] : p.theme.colors["main-600"]};
`;

export const StyledAnnotationNegative = styled.span`
  color: ${(p) =>
    p.default ? p.theme.colors["neutral-300"] : p.theme.colors["red-500"]};
`;
